import React, { useEffect, useState } from 'react';
import { GridColDef, GridCellParams, GridRowParams, GridEvents, MuiEvent, GridCallbackDetails, GridRenderCellParams } from '@mui/x-data-grid';
import { Match } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { TeamLogos } from '../../Globals';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlayerDataGrid from '../PlayerDataGrid';
import _ from 'underscore';
import { RenderTeamCellToolTip } from '../../Globals/TableFunctions';
import { TeamsList } from '../../Globals';

export interface Props {
    matches: Match[];
    teamname: string;
}

const columns:GridColDef[]  = [
    { field: "season", headerName: "Season" },
    { field: "round", headerName: "Round" },
    { field: 'opposition', headerName: 'Opposition', width:150,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderTeamCellToolTip(params.row.opposition, params.row.oppositionlogo, params.row.oppositioncolors,params.row.match))
    },
    { field: "date", headerName: "Date" },
    { field: "location", headerName: "Location" },
    { field: 'navigatebutton', headerName: '' ,renderCell: (params: GridRenderCellParams<Date>) => (
        <KeyboardArrowRightIcon />)
    },
]

function SingleTeamsGames({matches, teamname}:Props) {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [ rows, setRows ] = useState<object[]>([]);

    useEffect(() => {
        PopulateRows();
    }, [matches, teamname])

    function PopulateRows() {
        
        let newrows:object[] = [];
        let newmatches:Match[] = matches;
        newmatches = _.chain(newmatches).sortBy('-round').sortBy("-season").value()
        newmatches.map(function(match) {
            let opposition:string = '';
            match.teams.map(function(team) {
                if (team.name !== teamname) {
                    opposition = team.name ? team.name : '';
                }
            });
            let oppositioncolors:string[] = TeamsList[opposition];
            newrows.push({
                id: match.id,
                season: match.season,
                round: match.round,
                opposition: opposition,
                oppositionlogo: TeamLogos[opposition],
                date: match.date,
                location: match.location,
                match: match,
                oppositioncolors: oppositioncolors,

            });

        });
        
        setRows(newrows);
    }

    
    const onCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        if (params.field === 'navigatebutton') {
            navigate(`/match/${params.row.season}/${params.row.round}/${params.row.opposition}`);
        }
    }
    return <PlayerDataGrid columns={columns} height="500px" rows={rows} onCellClick={onCellClick} />
    
}

export default SingleTeamsGames;