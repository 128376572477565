import React, { useEffect, useState, useRef } from 'react';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { CHARTCOLORS } from '../Globals'

interface Props {
    series: {name: string, data: number[];}[];
    title: string;
    categories: string[];
    showtitle?:string;
    type?:string;
}

interface ChartOptions {
    chart: { type:string };
    title: { text?: string};
    series: {data: ChartSeries[]}[] | {data:number[], name: string}[] | ChartSeries[] | { data: ChartSeries };
    xAxis: { categories: string[], title: { text:string}};
    yAxis: { title: { text: string}};
}

interface ChartSeries {
    data: number[] | [name:string, value:number];
    name: string;
    y?: number;
}


const MultiSeriesGraph = ({series, title, categories, showtitle, type}: Props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [ seriesData, setSeriesData ] = useState<{name:string, data:number[]}[]>(series);
    if (!type) {
        type = 'line';
    }
    let options:ChartOptions = {
        chart: { 
            type:type,
        },
        title: {
            text: showtitle
        },
        series: series,
        xAxis: {
            categories: categories,
            title: {
                text: 'Rounds'
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        }
    }
    
    

    useEffect(() => {
        setSeriesData(series);
    },[])

    useEffect(() => {
        setSeriesData(series);
    },[series])
    
    Highcharts.setOptions({
        colors: CHARTCOLORS
    })
    return (<div>
        <HighchartsReact
            ref={chartComponentRef}
            highcharts={Highcharts}
            options={options}
            updateArgs={[true, true, true]}
            oneToOne={true} 
        />
    </div>)
}

export default MultiSeriesGraph;