import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { selectMagicNumber, ChangeMagicNumber, selectPlayerDisplay, TogglePlayerDisplay } from '../reducers/UserSettingsReducer';

import { Drawer, TextField, Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';

export interface Props {
    open: boolean;
    setOpen: (setopen: boolean) => void;
}

const UserDrawer = ({open, setOpen}:Props) => {
    const dispatch = useAppDispatch();
    let statemagicnumber = useAppSelector(selectMagicNumber);
    let statePlayerDisplay = useAppSelector(selectPlayerDisplay);
    const [ magicNumber, setMagicNumber ] = useState(statemagicnumber || 0);
    const [ playerDisplay, setPlayerDisplay ] = useState<string>( statePlayerDisplay || 'table');

    function onChange(change:string) {
        if (!isNaN(parseInt(change))) {
            setMagicNumber(parseInt(change));
            dispatch(ChangeMagicNumber(parseInt(change)));
        }
    }

    function handleDisplayChange(change:React.ChangeEvent<HTMLInputElement>) {
        setPlayerDisplay(change.target.value);
        dispatch(TogglePlayerDisplay(change.target.value));
    }
    return <Drawer
        open={open}
        onClose={e => setOpen(false)}
        anchor="bottom"
    >
         <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                padding: "1rem",
                height: 300,
              }}
        >
            
            <Typography variant="body1" style={{marginBottom: "2rem"}}>Settings</Typography>
            <TextField label="Magic Number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={magicNumber} onChange={e => onChange(e.target.value)} />
        </Box>
    </Drawer>
}

export default UserDrawer;