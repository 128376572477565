import React, { useEffect, useState } from 'react';
import { Match } from '../../types/types';
import { Card, Typography, CardContent, Paper } from '@mui/material';
import { TeamLogos, TeamsList } from '../../Globals';
import { useNavigate } from 'react-router-dom';

export interface Props {
    match: Match;
}

function SingleMatchCard({match}:Props) {
    console.log("SINGLE MATCH CARD: ", match)
    let navigate = useNavigate();
    let datestring:string = '';
    let matchdate:Date = new Date(match.date);
    datestring = matchdate.toLocaleDateString('en-US', { day: "numeric", month: 'long'});

    let teamPrimaryColors:string[] = [];
    match.teams.map(function(team) {
        let teamname = team.name? team.name : '';
        teamPrimaryColors.push(TeamsList[teamname][0]);
    });
    
    

    let hometeamname:string = match.hometeam? match.hometeam.name : '';
    let awayteamname:string = match.awayteam? match.awayteam.name : '';
    
    const hometeamgradientcolor:string = TeamsList[hometeamname][0];
    const awayteamgradientcolor:string = TeamsList[awayteamname][0];
    
    let homescorecolor:string = '';
    let awayscorecolor:string = '';

    if (match.homescore > match.awayscore) {
        homescorecolor = 'green';
        awayscorecolor = 'red'
    } else if (match.homescore === match.awayscore) {
        homescorecolor = 'red';
        awayscorecolor = 'green'
    } else {
        homescorecolor = 'red';
        awayscorecolor = 'green'
    }
    
    return (<Card sx={{cursor: "pointer", marginRight: "1rem", maxWidth: 250, overflow: 'visible', display: "flex", background: `linear-gradient(120deg, ${hometeamgradientcolor} 0%, ${hometeamgradientcolor} 50%, ${awayteamgradientcolor} 50%)`}} onClick={e => navigate('/match/'+match.id)}>
        <CardContent sx={{justifyContent: "flex-start", flexDirection: "column", display: "flex", paddingBottom: 12, alignItems: "center", paddingLeft: 0, paddingRight: 0}} style={{paddingBottom: 12}}>
            <Typography sx={{ fontSize: 12,whiteSpace:"nowrap" }} color="white" gutterBottom>{datestring}</Typography>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <img src={TeamLogos[hometeamname]} style={{marginLeft: 0, marginRight: 0,width: "70px", height: "70px", margin: "auto", marginBottom: "0.5rem"}} alt={hometeamname} />
                    <Paper style={{width: "50%", backgroundColor: homescorecolor, color: "white"}}>
                        {match.homescore}
                    </Paper>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <img src={TeamLogos[awayteamname]} style={{marginLeft: 0, marginRight: 0,width: "70px", height: "70px", margin: "auto", marginBottom: "0.5rem"}} alt={awayteamname} />
                    <Paper style={{width: "50%", backgroundColor: awayscorecolor, color: "white"}}>
                        {match.awayscore}
                    </Paper>
                </div>
                
                
            </div>
            <Typography sx={{ fontSize: 14, marginBottom: 0,whiteSpace:"nowrap" }} color="white" gutterBottom>Round: {match.round}</Typography>
        </CardContent>
    </Card>)
}

export default SingleMatchCard;