import React, { useEffect, useState } from 'react';
import NavContainer from '../components/Navigation/NavContainer';
import Header from '../components/Header/Header';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getOwnerships, selectLoading, selectOwnerships } from '../reducers/SCTeams';
import OwnershipsTable from '../components/Ownerships/OwnershipsTable';
import { Container } from '@mui/material';
import OwnershipsHeader from '../components/Ownerships/OwnershipsHeader';
import { LASTROUND } from '../Globals/Globals';


const OwnershipsScreen = () => {
    const dispatch = useAppDispatch();
    const [ position, setPosition ] = useState('CTW');
    const [ round, setRound ] = useState(LASTROUND);
    let ownerships = useAppSelector(selectOwnerships);
    const isLoading = useAppSelector(selectLoading);
    useEffect(() => {
        dispatch(getOwnerships({position,round}));
    },[position,round])

    return <div>
        <NavContainer />
        <Header />
        <Container maxWidth="xl" style={{paddingBottom: "5rem"}}>
            <div style={{display: "flex", flexDirection: "column", rowGap: "1rem"}}>
                <OwnershipsHeader round={round} setRound={setRound} position={position} setPosition={setPosition}/>
                <OwnershipsTable ownerships={ownerships} />
            </div>
        </Container>
    </div>
}

export default OwnershipsScreen;