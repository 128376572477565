import axios from "axios";

// export default  axios.create({
//    //baseURL: 'http://192.168.1.13:8000/'
//    baseURL: 'http://localhost:8000/',
//    //baseURL: 'https://limitless-woodland-18796.herokuapp.com/',
// });

let isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

isLocalhost = false;

const REMOTE_API_URL = "https://limitless-woodland-18796.herokuapp.com/";
const LOCAL_API_URL = "http://localhost:8000/";

export default axios.create({
  baseURL: isLocalhost ? LOCAL_API_URL : REMOTE_API_URL,
});
