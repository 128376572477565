import React from "react";
import { Match, Player, PlayerStats, Game } from "../../types/types";
import Paper from "@mui/material/Paper";
import { numberFormat } from "highcharts";
import { CalculateBE } from "../../functions/CalculateBE";
import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectMagicNumber } from "../../reducers/UserSettingsReducer";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  AddPlayerByID,
  selectChosenPlayerIds,
  RemovePlayer,
} from "../../reducers/ChosenPlayers";
import { TOOLTIPS } from "../../Globals";
import _ from "underscore";
import {
  CURRENT_SEASON_STRING,
  LAST_SEASON_STRING,
} from "../../Globals/Globals";
const MyStatHeader = styled(Paper)`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  padding: 12px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
  @media (max-width: 800px) {
    margin-top: 1rem;
  }
`;

const Mystatcontainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  @media (min-width: 800px) {
    width: 8%;
    margin-bottom: 0;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const HideForSmall = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const StatsHeaderText = styled.div`
  text-align: center;
  text-decoration: underline;
  font-size: 0.75rem;
  @media (min-width: 800px) {
    font-size: 0.75rem;
  }
`;
const StatValueText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  @media (min-width: 800px) {
    font-size: 0.8rem;
  }
`;

export interface Props {
  stats: {
    be: number;
    nv: number;
    last5: number[];
    three: number | string;
    five: number | string;
    avgbase: number;
    avgpts: number;
  };
  player: Player;
  games: Game[];
}

function StatsHeadline({ stats, player, games }: Props) {
  console.log("Stats Headline: ", { stats, player });
  const dispatch = useAppDispatch();
  let selectedplayerids = useAppSelector(selectChosenPlayerIds);

  let magicnumber = useAppSelector(selectMagicNumber);
  if (games.length > 0) {
    let price: number = 0;
    let be: number = 0;
    let points: number = 0;
    let ppm: number = 0;
    let threerd: number = 0;
    let fiverd: number = 0;
    let aveminutes: number = 0;
    let avePPM: number = 0;
    let price_change: number = 0;
    let formattedPrice: string = "";
    let playerstats: PlayerStats = {
      price: "",
      be: 0,
      ppm: 0,
      threeFive: "",
      five: "",
      three: "",
      base: 0,
      aveminutes: 0,
      scbe: 0,
      posrank: 0,
    };
    let avgbase: number = 0;
    let threefive: string = "";
    if (games.length > 0) {
      var pricetotal = 0;
      var pointstotal = 0;
      var minutestotal = 0;
      games.map(function (game) {
        pricetotal = pricetotal + game.price;
        pointstotal = pointstotal + game.points;
        minutestotal = minutestotal + game.minutes_played;
        avePPM = avePPM + game.points / game.minutes_played;
      });
      points = pointstotal / games.length;
      aveminutes = minutestotal / games.length;
      ppm = pointstotal / minutestotal;
      avePPM = avePPM / games.length;

      points = Math.round(points);
      ppm = Math.round(ppm * 100 + Number.EPSILON) / 100;
      aveminutes = Math.round(aveminutes * 100 + Number.EPSILON) / 100;
      avePPM = Math.round(avePPM * 100 + Number.EPSILON) / 100;
      price = games[games.length - 1].price;
      price_change = games[games.length - 1].price_change;

      formattedPrice = price?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      formattedPrice = formattedPrice?.substr(0, formattedPrice.length - 3);

      // update to new avgs
      points = stats.avgpts;
      avgbase = stats.avgbase;
      points = Math.round(points * 100 + Number.EPSILON) / 100;
      avgbase = Math.round(avgbase * 100 + Number.EPSILON) / 100;
    }
    if (stats) {
      playerstats = CalculatePlayerStats({
        games: player.stats[0].last5Games,
        magicnumber: magicnumber,
      });
    }
    let ownershipPercentage: number = 0;
    if (games.length > 0) {
      be = games[games.length - 1].scbe;
      ownershipPercentage = games[games.length - 1].ownerShipPercentage;
    } else {
      be = playerstats.be;
    }
    const renderStat = (label: string, value: number | string) => {
      return (
        <Tooltip title={TOOLTIPS[label]} placement="top">
          <Mystatcontainer>
            <StatsHeaderText>{label}</StatsHeaderText>
            <StatValueText>{value}</StatValueText>
          </Mystatcontainer>
        </Tooltip>
      );
    };

    const isselected = selectedplayerids.includes(player.id);

    let thisSeasonStat;
    let lastSeasonStat;
    let thisSeasonAVG;
    let lastSeasonAVG = 0;
    let currentSeasonStat;
    let basePlusPower;
    player.stats.map(function (stat) {
      if (stat.season === LAST_SEASON_STRING) {
        lastSeasonStat = stat;
      } else if (stat.season === CURRENT_SEASON_STRING) {
        thisSeasonStat = { ...stat };
      } else if (stat.season === CURRENT_SEASON_STRING) {
        currentSeasonStat = stat;
      }
    });

    if (thisSeasonStat) {
      avgbase = thisSeasonStat.avgbase;
      be = thisSeasonStat?.lastgame?.scbe;
      basePlusPower =
        thisSeasonStat?.lastgame?.total_tackle_busts +
        thisSeasonStat?.lastgame?.total_effective_offloads;
      formattedPrice = thisSeasonStat?.lastgame?.price?.toLocaleString(
        "en-US",
        { style: "currency", currency: "USD" }
      );
      formattedPrice = formattedPrice?.substr(0, formattedPrice.length - 3);

      if (thisSeasonStat?.lastgame?.ownerShipPercentage) {
        ownershipPercentage = thisSeasonStat?.lastgame?.ownerShipPercentage;
      }
      if (lastSeasonStat) {
        points = lastSeasonStat.avgpts;
        //ppm = lastSeasonStat?.lastgame?.total_minutes_played / lastSeasonStat?.lastgame?.total_points

        if (lastSeasonStat.lastgame) {
          lastSeasonAVG =
            lastSeasonStat?.lastgame?.total_points /
            lastSeasonStat?.lastgame?.total_games;
        }
        let avgMinutes =
          lastSeasonStat.lastgame?.total_minutes_played /
          lastSeasonStat.lastgame?.total_games;
        aveminutes = Math.round(avgMinutes * 100 + Number.EPSILON) / 100;
      }
      if (thisSeasonStat.lastgame) {
        ppm =
          thisSeasonStat?.lastgame?.total_points /
          thisSeasonStat?.lastgame?.total_minutes_played;
        let avgMinutes =
          thisSeasonStat.lastgame.total_minutes_played /
          thisSeasonStat.lastgame.total_games;
        aveminutes = Math.round(avgMinutes * 100 + Number.EPSILON) / 100;
        thisSeasonAVG =
          thisSeasonStat.lastgame.total_points /
          thisSeasonStat.lastgame.total_games;
      }

      if (thisSeasonStat.last5Games) {
        let threetotal = 0;
        let fivetotal = 0;
        if (thisSeasonStat.last5Games.length > 1) {
          let sortedlast5 = _.sortBy(
            thisSeasonStat.last5Games,
            function (game) {
              return -game.round;
            }
          );
          sortedlast5.map(function (game, index) {
            if (index <= 2) {
              threetotal = threetotal + game.points;
            }
            if (index <= 5) {
              fivetotal = fivetotal + game.points;
            }
          });
          if (sortedlast5.length < 3) {
            threetotal = threetotal / sortedlast5.length;
          } else {
            threetotal = threetotal / 3;
          }
          if (sortedlast5.length < 5) {
            fivetotal = fivetotal / sortedlast5.length;
          } else {
            fivetotal = fivetotal / 5;
          }
          threefive = `${threetotal.toFixed(1)}/${fivetotal.toFixed(2)}`;
        }
      }
    }

    if (currentSeasonStat) {
      formattedPrice = currentSeasonStat?.lastgame?.price?.toLocaleString(
        "en-US",
        { style: "currency", currency: "USD" }
      );
      formattedPrice = formattedPrice?.substr(0, formattedPrice.length - 3);
      be = currentSeasonStat.lastgame?.scbe;
    }
    return (
      <MyStatHeader>
        <Tooltip title={TOOLTIPS["Price"]} placement="top">
          <Mystatcontainer>
            <StatsHeaderText>Price</StatsHeaderText>
            <PriceContainer
              style={{ color: price_change > -1 ? "green" : "red" }}
            >
              <span>{formattedPrice}</span>&nbsp;
              <span>
                ({price_change > -1 ? "+" : ""}
                {price_change?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                )
              </span>
            </PriceContainer>
          </Mystatcontainer>
        </Tooltip>
        {renderStat("Ownership", `${ownershipPercentage}%`)}
        {renderStat("AVG", thisSeasonAVG?.toFixed(2))}
        {renderStat("BE", be)}
        {renderStat("Base", avgbase)}
        {renderStat("Base + Power", basePlusPower)}
        {renderStat("2023 AVG", lastSeasonAVG?.toFixed(2))}
        {renderStat("PPM", ppm?.toFixed(1))}
        {renderStat("Minutes", aveminutes)}
        {renderStat("3/5rd Avg", threefive)}
        {isselected ? (
          <RemoveIcon onClick={(e) => dispatch(RemovePlayer(player.id))} />
        ) : (
          <AddIcon onClick={(e) => dispatch(AddPlayerByID(player.id))} />
        )}
      </MyStatHeader>
    );
  } else {
    return <div></div>;
  }
}

export default StatsHeadline;
