import React, { useEffect, useState } from 'react';
import { Card, CardMedia, Divider, Paper, Tooltip } from '@mui/material';
import { Game, LightPlayer } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import {TeamsList, TeamLogos, TOOLTIPS} from '../../Globals';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import styled from 'styled-components';
import { isArray } from 'underscore';
import PlayerCardPopover from './PlayerCardPopover';
export interface Props {
    Game: Game;
    player: LightPlayer;
    large?: boolean;
    filteritem?: string;
}

const CardNumber = styled.b`
    font-size: 1rem;
    @media (min-width: 1024px) {
        font-size: 1.1rem ;
    }
`

const CardLabel = styled.span`
    font-size: 0.75rem;
    line-height: 1;
    text-transform: capitalize;
    @media (min-width: 1024px) {
        font-size: 0.9rem;
    }
`


function PlayerGameCard2({Game, player,large=false, filteritem='false'}:Props) {
    const DEV = false;
    let navigate = useNavigate();
    let teamname = Game?.team?.name ? Game?.team?.name : '';

    let colors = TeamsList[teamname];
    let increase:boolean = Game?.price_change > -1;
    
    if (!colors) {
        colors = ['#262626', '#161616']
    }

    let showfilteritem:boolean = filteritem !== 'false' ? true : false;
    
    let playerimg ='./mysteryplayer.png';
    if (DEV) {
        playerimg ='https://www.nrlsupercoachstats.com/highcharts/PlayerPictures/2021/Trbojevic,%20Tom.png';
    } else {
        playerimg = player.pic ? player.pic : playerimg
    }
    return <div style={{width: large ? '250px' : '', position: "relative", textAlign: "center", display: 'flex', flexDirection: 'column', height: "100%"}} className="playergamecard">
            <div style={{
                    background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
                cursor: 'pointer',
                height: "100%"
            }}
                onClick={e => navigate(`/player/${player.id}`)}
            >
                <div style={{
                    backgroundImage: `url("${playerimg}")`,
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    paddingBottom: "60%",
                    backgroundRepeat:"no-repeat",
                    //backgroundColor: "blue",
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                    height: "100%"
                }}>
                <div style={{position: "absolute", top: 6, left: 6}}>
                    <div style={{display:"flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start"}}>        
                        { /* <span style={{color: "white"}}>{player.stats[0]?.last5Games[0]?.team?.name}</span> */ }
                        <img style={{width: "30px", height: "30px"}} src={TeamLogos[teamname]} alt={teamname}/>
                    </div>
                </div>
                
            </div>
        </div>
        <Paper style={{paddingBottom: "0.5rem"}}>
            {/* <PlayerCardPopover player={player} /> */}
            <Tooltip title={TOOLTIPS['Price']} placement="top">
            <a href={`/player/${player.id}`}>
                <div style={{display: "flex", flexDirection: "column", marginTop: "0.5rem",marginBottom: "0.5rem"}}>
                    <span><b style={{color: "black"}}>{player.firstname[0]}. {player.lastname}</b></span>
                    <span style={{display: "flex",width: "100%",justifyContent: "center",marginTop: '0.25rem'}}>
                        <b style={{color: increase ? "green" : 'red'}}>{Game?.price.toLocaleString('en-US', { style: "currency", currency: "USD", maximumSignificantDigits: 6, minimumFractionDigits: 0, maximumFractionDigits: 0})}</b> 
                        {increase ? <TrendingUpIcon style={{color: "green", marginLeft: "0.25rem"}} /> : <TrendingDownIcon style={{color: "red", marginLeft: "0.5rem"}}/> }
                    </span>
                </div>
            </a>
            </Tooltip>
            <div style={{display: "grid", gridTemplateRows: "1fr", gridTemplateColumns: "1fr 1fr 1fr", gridRowGap: "0.5rem", marginBottom: "1rem"}}>
                <Tooltip title={TOOLTIPS['PTS']} placement="top">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div><CardNumber>{Game?.points}</CardNumber></div>
                        <CardLabel>PTS</CardLabel>                    
                    </div>
                </Tooltip>
                <Tooltip title={TOOLTIPS['Mins']} placement="top">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div><CardNumber>{Game?.minutes_played}</CardNumber></div>
                        <CardLabel>Mins</CardLabel>                    
                    </div>
                </Tooltip>
                <Tooltip title={TOOLTIPS['Base']} placement="top">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div><CardNumber>{Game?.base_points}</CardNumber></div>
                        <CardLabel>Base</CardLabel>                    
                    </div>
                </Tooltip>
            </div>
            {showfilteritem ? <div>
                <div><CardNumber>{Game[filteritem]}</CardNumber></div>
                <CardLabel>{filteritem?.split("_").join(" ")}</CardLabel>                    
            </div> : null}
        </Paper>
    </div>
}

export default PlayerGameCard2;