import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  selectPlayer,
  GetSinglePlayer,
  selectSinglePlayer,
  selectLoadingStatus,
} from "../../reducers/FrontReducer";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import MaterialTable from "../Table";
import Filter from "../Filter";
import {
  Game,
  Match,
  GraphType,
  Team,
  Rank,
  Player,
  MatchWithRanks,
} from "../../types/types";
import StatsHeadline from "../SinglePlayer/StatsHeadline";
import Graph from "../Graph";
import GraphContainer from "../GraphContainer";
import { SelectChangeEvent } from "@mui/material/Select";
import { FilterType } from "../../types/components";
import SinglePlayerHeader from "../SinglePlayer/SinglePlayerHeader";
import {
  TableCell,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tabs,
  Tab,
  Box,
  Paper,
  Tooltip,
  Container,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import _ from "underscore";
import PlayerDataGrid from "../PlayerDataGrid";
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
  GridEvents,
  MuiEvent,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import PCCContainer from "../PriceChangeCalculator/PCCContainer";
import { TeamLogos, TeamsList } from "../../Globals";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SingleTeamHeader from "../SingleTeam/SingleTeamHeader";
import GamesHeader from "../GamesHeader";
import NavContainer from "../Navigation/NavContainer";
import ChangeAbleGraph from "../ChangeableGraph";
import styled from "@emotion/styled";
import {
  RenderPriceCell,
  RenderTeamCell,
  RenderTeamCellToolTip,
  RenderRankCell,
  RenderPositionRanks,
} from "../../Globals/TableFunctions";
import Loading from "../Loading";
import { RegisterEvent, RegisterPageView } from "../../functions/Analytics";
import PosRank from "../SinglePlayer/PosRank";
import Trades from "../SinglePlayer/Trades";
import PlayerOwnership from "../SinglePlayer/PlayerOwnership";
import PredictionsTable from "../SinglePlayer/PredictionsTable";
import AdsenseAdd from "../AdsenseAdd";
import OwnershipGraph from "../SinglePlayer/OwnershipGraph";
import Header from "../Header/Header";
import {
  CURRENT_SEASON_STRING,
  LAST_SEASON_STRING,
  LASTROUND,
} from "../../Globals/Globals";

export interface PlayerScreenContentProps {
  singlePlayerData: {
    player: Player;
    games: Game[];
    matches: Match[];
    upcoming: MatchWithRanks[];
    stats: {
      be: number;
      nv: number;
      last5: number[];
      three: number | string;
      five: number | string;
      avgpts: number;
      avgbase: number;
      aveppm: number;
    };
  };
  playerid: string;
}

const MyAccordionDetails = styled(AccordionDetails)`
  @media (max-width: 600px) {
    padding: 0;
    background-color: green;
  }
`;

let graphs: GraphType[];
graphs = [
  {
    type: "line",
    name: "Points",
    term: ["points"],
    data: [],
    categories: [],
  },
  {
    type: "pie",
    name: "Score Breakdown",
    term: [
      "base_points",
      "create_points",
      "negative_play_points",
      "scoring_points",
      "evade_points",
    ],
    data: [],
    categories: [],
  },
];
let basefilters: FilterType[] = [
  {
    options: ["2024", "2023", "2022", "2021", "2020", "Any"],
    name: "Season",
    selected: "2024",
    term: "season",
  },
];

const columns: GridColDef[] = [
  { field: "season", headerName: "Season", width: 75 },
  { field: "round", headerName: "Rd", type: "number", width: 50 },

  {
    field: "opposition",
    headerName: "Opponent",
    width: 140,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderTeamCellToolTip(
        params.row.opposition,
        params.row.oppositionlogo,
        params.row.oppositioncolors,
        params.row.game
      ),
  },
  {
    field: "price",
    headerName: "Price",
    type: "number",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderPriceCell(params.row.price, params.row.price_change),
    sortComparator: (v1, v2) => {
      return 1;
    },
  },
  { field: "points", headerName: "Points", type: "number" },
  { field: "scbe", headerName: "BE" },
  { field: "position", headerName: "Position" },
  {
    field: "posranks",
    headerName: "PositionRanks",
    width: 200,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderPositionRanks(params.row.posranks),
  },
  { field: "minutes_played", headerName: "Minutes", type: "number" },
  { field: "ppm", headerName: "PPM", type: "number" },
  { field: "base_points", headerName: "Base", type: "number" },
  { field: "power_points", headerName: "Power Pts", type: "number" },
  { field: "create_points", headerName: "Assist", type: "number" },
  {
    field: "negative_play_points",
    headerName: "Negative Play",
    type: "number",
  },
  { field: "scoring_points", headerName: "Scoring", type: "number" },
  { field: "evade_points", headerName: "Attack", type: "number" },
  { field: "tackles", headerName: "Tackles", type: "number" },
  { field: "missed_tackles", headerName: "Missed Tackles", type: "number" },
  { field: "tries", headerName: "Tries", type: "number" },
  { field: "hit_upso8", headerName: "H8", type: "number" },
  { field: "hit_ups", headerName: "HU", type: "number" },
  { field: "errors", headerName: "Errors", type: "number" },
  {
    field: "nextroundpoints",
    headerName: "Next Round Predicted Points",
    type: "number",
  },
  {
    field: "nextroundprice",
    headerName: "Predicted Price",
    type: "number",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderPriceCell(
        params.row.nextroundprice,
        params.row.predictedPriceChange
      ),
    sortComparator: (v1, v2) => {
      return 1;
    },
  },
  {
    field: "nextroundprice",
    headerName: "Next Round Predicted Price",
    type: "number",
  },
  {
    field: "navigatebutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => (
      <KeyboardArrowRightIcon />
    ),
  },
];

const upcomingcolumns: GridColDef[] = [
  { field: "round", headerName: "Round" },
  {
    field: "opponent",
    headerName: "Opponent",
    width: 140,
    flex: 1,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "38px",
          alignItems: "center",
          background: `linear-gradient(120deg, ${params.row.oppositioncolors[0]} 0%, ${params.row.oppositioncolors[0]} 50%, ${params.row.oppositioncolors[1]} 50%)`,
          color: "white",
        }}
      >
        <img
          style={{ height: "1.5rem", width: "2rem" }}
          src={params.row.opponentlogo}
          alt="Team logo"
        />
        <p>{params.row.opponent}</p>
      </div>
    ),
  },
  { field: "date", headerName: "Date" },
  { field: "location", headerName: "Location", width: 300 },
  {
    field: "ranks",
    headerName: "PTS Conceeded Rank",
    width: 700,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderRankCell(params.row.ranks),
  },
];

const TABITEMS: String[] = [
  "Stats",
  "Fixture",
  "Price Change Calculator",
  "Trades",
  "Ownership",
];

const SinglePlayerScreenContent = ({
  singlePlayerData,
  playerid,
}: PlayerScreenContentProps) => {
  const DEV = false;
  let { id } = useParams<{ id: string }>();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  //let singlePlayerData = useAppSelector(selectSinglePlayer);
  let loadingstatus = useAppSelector(selectLoadingStatus);

  const [graphData, setGraphData] = useState<GraphType[]>(graphs);
  const [filters, setFilters] = useState<FilterType[]>(basefilters);
  const [games, setGames] = useState<Game[]>(singlePlayerData.games);
  const [graphGames, setGraphGames] = useState<Game[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  let player = singlePlayerData.player;
  let basegames = singlePlayerData.games;
  let trades = singlePlayerData.player.trades;
  let stats = singlePlayerData.stats;

  let upcoming = singlePlayerData.upcoming;
  let datagridrows: object[];
  let upcomingrows: object[];

  let thisteam: string = player?.stats[0]?.last5Games[0]?.team?.name
    ? player?.stats[0]?.last5Games[0]?.team?.name
    : "";
  let thisteamobject: Team = player.stats[0].last5Games[0]?.team
    ? player.stats[0].last5Games[0]?.team
    : null;
  datagridrows = games.map(function (game, index) {
    let base: number =
      game.tackles +
      game.hitups_over_8m +
      game.hitups_under_8m +
      game.missed_tackles;
    let oppositionname = game.opposition?.name ? game?.opposition?.name : "";
    let oppositioncolors: string[] = TeamsList[oppositionname];
    let predictedPriceChange: number =
      game.nextRoundPredictedPrice - game.price;
    let posranks = _.uniq(game.positionrank, function (posrank) {
      return posrank.position.name;
    });
    return {
      id: `${index}-${game.linkedmatchid}`,
      season: game.season,
      round: game.round,
      //home: game.hometeam[0]?.name,
      opposition: oppositionname,
      oppositionlogo: TeamLogos[oppositionname],
      minutes_played: game.minutes_played,
      points: game.points,
      ppm: game.points / game.minutes_played,
      price: game.price?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      price_change: game.price_change,
      position: game.position?.name,
      base_points: game.base_points,
      create_points: game.create_points,
      negative_play_points: game.negative_play_points,
      scoring_points: game.scoring_points,
      evade_points: game.evade_points,
      base: base,
      tackles: game.tackles,
      missed_tackles: game.missed_tackles,
      tries: game.tries,
      hit_upso8: game.hitups_over_8m,
      hit_ups: +game.hitups_under_8m,
      errors: game.errors,
      oppositioncolors: oppositioncolors,
      game: game,
      posrdrank: game.posrdrank,
      postotalrank: game.posrank,
      scbe: game.scbe,
      nextroundpoints: game.nextRoundPredictedPoints,
      nextroundprice: game.nextRoundPredictedPrice,
      predictedPriceChange: predictedPriceChange,
      power_points: game.tackle_busts + game.effective_offloads,
      posranks: posranks,
    };
  });
  console.log("dfr: ", datagridrows);

  upcomingrows = upcoming.map(function (match, index) {
    var showteam = "";
    var ranks: Rank[] = [];

    match.teams.map(function (team) {
      if (team.name !== thisteam) {
        showteam = team.name ? team.name : "";
        ranks = team.ranks;
      }
    });

    let oppositioncolors: string[] = TeamsList[showteam]
      ? TeamsList[showteam]
      : [0, 0];
    return {
      id: `${index}-${match.round}-${showteam}`,
      round: match.round,
      opponent: showteam,
      opponentlogo: TeamLogos[showteam],
      date: match.date,
      location: match.location,
      oppositioncolors: oppositioncolors,
      ranks: ranks,
    };
  });
  console.log("ip: ", upcomingrows);

  useEffect(() => {
    //dispatch(GetSinglePlayer(id));
    DoSort("2022", "season");
    DoGames(games);
    RegisterPageView({ path: `/player/${id}` });
  }, [playerid]);

  useEffect(() => {
    DoSort("2022", "season");
    //DoGames(games);
  }, [singlePlayerData]);

  useEffect(() => {}, [games]);

  const DoSort = (selecteditem: string, term: string): any => {
    let newbase = basegames.slice();
    let newgames: Game[] = [];
    var newfilters = filters;
    newbase?.map(function (game) {
      var include = false;
      newfilters.map(function (filter) {
        if (filter.term === term) {
          filter.selected = selecteditem;
        }
        //Check if this filter is filtering
        if (filter.selected !== "Any") {
          include =
            game[filter.term as keyof typeof Object].toString() ===
            filter.selected.toString();
        } else {
          include = true;
        }
      });
      if (include) {
        newgames.push(game);
      }
    });

    newgames = _.chain(newgames).sortBy("round").sortBy("season").value();
    setFilters(newfilters);
    setGames(newgames);
    DoGraphGames();
  };

  const DoGraphGames = () => {
    let newgames: Game[] = singlePlayerData.games.slice();
    newgames = _.chain(newgames).sortBy("round").sortBy("season").value();
    let changeablegraphsgames: Game[] = [];
    changeablegraphsgames = _.chain(newgames)
      .filter(function (game) {
        return game.season === parseInt(filters[0].selected);
      })
      .sortBy("round")
      .value();
    setGraphGames(changeablegraphsgames);
  };

  const DoGames = (games: Game[]) => {
    let newgames: Game[] = singlePlayerData.games.slice();
    newgames = _.chain(newgames).sortBy("round").sortBy("season").value();
    let changeablegraphsgames: Game[] = [];
    changeablegraphsgames = _.chain(newgames)
      .filter(function (game) {
        return game.season === parseInt(filters[0].selected);
      })
      .sortBy("round")
      .value();
    setGames(newgames);
    setGraphGames(changeablegraphsgames);
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (params.field === "navigatebutton") {
      navigate(
        `/match/${params.row.season}/${params.row.round}/${params.row.opposition}`
      );
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    RegisterEvent({
      category: "Single Player",
      action: "changetab",
      label: `${player.id} ${player.firstname} ${player.lastname} ${TABITEMS[newValue]}`,
    });
    RegisterPageView({ path: `player/${player.id}/${TABITEMS[newValue]}` });
    setSelectedTab(newValue);
  };

  let playerimg = "/mysteryplayer.png";
  if (!DEV) {
    playerimg = player.pic !== "" ? player.pic : "/mysteryplayer.png";
  }

  let lastseasonstat;
  let thisseasonstat;
  player.stats.forEach(function (stat) {
    if (stat.season === LAST_SEASON_STRING) {
      lastseasonstat = stat;
      if (stat.lastgame) {
        thisteamobject = stat.lastgame?.team;
      }
    } else if (stat.season === CURRENT_SEASON_STRING) {
      thisseasonstat = stat;
    }
  });

  if (player) {
    return (
      <div id="singleplayerscreen">
        <GamesHeader
          lastmatch={
            lastseasonstat ? lastseasonstat.lastgame : games[games.length - 1]
          }
          upcoming={upcoming}
          image={playerimg}
          header={player.firstname + " " + player.lastname}
          thisTeam={thisteamobject}
        />
        <Container maxWidth="xl" style={{ paddingBottom: "5rem" }}>
          <StatsHeadline stats={stats} player={player} games={games} />
          <Box>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem  ",
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="scrollable"
                style={{ marginBottom: "1rem" }}
              >
                <Tab label="Stats" />
                <Tab label="Fixture" />
                <Tab label="Price Change Calculator" />
                <Tab label="Trades" />
                <Tab label="Ownership" />
              </Tabs>
            </Box>

            {selectedTab === 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: "1rem",
                  }}
                >
                  {filters.map(function (filter) {
                    return (
                      <Filter
                        options={filter.options}
                        onChange={DoSort}
                        selected={filter.selected}
                        name={filter.name}
                        term={filter.term}
                      />
                    );
                  })}
                </div>

                <Paper
                  style={{ marginBottom: "1rem", padding: "0.5rem" }}
                  elevation={5}
                >
                  <ChangeAbleGraph
                    defaultGraphItems={["points"]}
                    games={graphGames}
                    title=""
                  />
                </Paper>
                <PlayerDataGrid
                  height={600}
                  columns={columns}
                  rows={datagridrows}
                  onCellClick={onCellClick}
                />
                <div>
                  <h4>Predictions</h4>
                  <PredictionsTable games={games} />
                </div>
              </div>
            ) : null}
            {selectedTab === 1 ? (
              <PlayerDataGrid
                height={450}
                columns={upcomingcolumns}
                rows={upcomingrows}
              />
            ) : null}
            {selectedTab === 2 ? (
              <PCCContainer games={games} player={player} />
            ) : null}
            {selectedTab === 3 ? (
              <div>
                <Trades trades={trades ? trades : []} />
              </div>
            ) : null}
            {selectedTab === 4 ? (
              <div>
                {/* <PlayerOwnership ownerships={player.ownerships? player.ownerships : []} lastround={1} /> */}
                <OwnershipGraph
                  ownerships={player.ownerships ? player.ownerships : []}
                  lastround={LASTROUND}
                />
              </div>
            ) : null}
          </Box>
          <div style={{ marginTop: "1rem" }}>
            <AdsenseAdd />
          </div>
        </Container>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default SinglePlayerScreenContent;
