import React, { KeyboardEvent, useState } from 'react';
import { Autocomplete, Box, TextField, Paper } from '@mui/material';
import { SimplePlayer } from '../../types/types';
import { useNavigate } from 'react-router-dom';

import { AddPlayer, AddPlayerByID } from '../../reducers/ChosenPlayers';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {RegisterEvent} from '../../functions/Analytics';
import { createFilterOptions } from '@mui/material/Autocomplete';


export interface Props {
    players: SimplePlayer[];
    setSelectedPlayer: (string) => void;
}

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option:SimplePlayer) => `${option.firstname} ${option.lastname}`,
    trim: true,
  });

function AutoComplete({players, setSelectedPlayer}: Props) {
    
    const navigate = useNavigate();
    const [ value, setValue] = useState("-1");
    
    return (<Autocomplete
        id="searchauto"
        options={players}

        sx={{marginBottom: "1rem"}}
        onChange={(event, player: SimplePlayer | null, reason) => {
            setValue(player? player.id : "-1");
        }}
        onKeyDown={(event) => {
            if (event.key === 'Enter') {
                RegisterEvent({
                    category: 'search',
                    action: 'Enter',
                    label: `${value}`,
                });
              
              if (value !== "1") {
                setSelectedPlayer(value)
              }
            }
        }}
        filterOptions={filterOptions}
        onHighlightChange={(event, value: SimplePlayer | null) => {
            setValue(value? value.id : "1");
        }}
        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
        renderOption={(props, option) => {
            return (<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} onClick={(e:React.ChangeEvent<any>) => {RegisterEvent({category: 'search',action: 'click',label: `${option.id}: ${option.firstname} ${option.lastname}`});setSelectedPlayer(option.id); }} onKeyPress={(e:KeyboardEvent) => {console.log("LEY:", e); }}>
                <img loading='lazy'
                    src={option.pic? option.pic : '/mysteryplayer.png'}
                    style={{width: '60px', height: '60px'}}
                />
                {option.firstname} {option.lastname}
            </Box>)
        }}
        renderInput={(params) => (
            <TextField 
                {...params}
                
                label="Switch Player"
            /> 
        )}
    />)

}

export default AutoComplete;