import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { RootState, AppThunk } from '../../app/store';
import api from '../app/api';
import { RootState } from '../app/store';
//import { fetchCount } from './counterAPI';
import { Player, Team, Position, Match, Game } from '../types/types';
import {SinglePlayerAction } from './FrontReducer';

export interface ChosenPlayersState {
    chosenPlayers: Player[];
    games: Game[],
}

export interface AddPlayerAction {
    player: Player
}

export interface MultiplePlayersAction {
    games: Game[]
}

const initialState: ChosenPlayersState = {
    chosenPlayers: [],
    games: [],
}


export const selectChosenPlayers = (state: RootState) => state.chosenplayers.chosenPlayers;

export const selectSelectedGames = (state:RootState ) => state.chosenplayers.games;

export const selectChosenPlayerIds = (state: RootState) => {
    let ids:string[] = state.chosenplayers.chosenPlayers.map(function(player) {
        return player.id;
    })
    return ids;
}

const FetchMultiplePlayerGames = (ids?:string) => {
    return new Promise<{data: MultiplePlayersAction}>((resolve) => {
        api.post('/players/', {ids: ids}).then(function(payload) {
          resolve(payload);
        });
      });
}

export const GetMultiplePlayerGames = createAsyncThunk(
    'chosenplayers/GetMultiplePlayerGames',
    async(ids:string) => {
        const response = await FetchMultiplePlayerGames(ids);
        return response;
    }
)

const FetchPlayerById = (id:string) => {
    return new Promise<{data: SinglePlayerAction}>((resolve) => {
        api.get("/player/"+id).then(function(payload) {
            resolve(payload);
        })
    })
}

export const AddPlayerByID = createAsyncThunk(
    'chosenplayers/GetPlayerByID',
    async(id:string) => {
        const response = await FetchPlayerById(id);
        return response;
    }
)

export const ChosenPlayersSlice = createSlice({
    name: "chosenplayers",
    initialState,
    reducers: {
        AddPlayer: (state, action: PayloadAction<Player>) => {
            let id:string = action.payload.id;
            let include:boolean = true;
            state.chosenPlayers.map(function(player) {
                if (player.id === id) {
                    include = false;
                }
            });
            if (include) {
                state.chosenPlayers.push(action.payload);
            }
            
        },
        RemovePlayer: (state, action: PayloadAction<string>) => {
            state.chosenPlayers = state.chosenPlayers.filter(function(player) {
                if (player.id !== action.payload) {
                    return player;
                }
            })
        },
        GetMultiplePlayerGames: (state, action) => {
            
        },
        AddPlayerByID: (state, action) => {
            
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(GetMultiplePlayerGames.fulfilled, (state, action) => {
            state.games = action.payload.data.games;
        })
        .addCase(AddPlayerByID.fulfilled,(state, action) => {
            state.chosenPlayers.push(action.payload.data.player);
        });
    }
})

export const { AddPlayer, RemovePlayer } = ChosenPlayersSlice.actions;

export default ChosenPlayersSlice.reducer;