import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//import { RootState, AppThunk } from '../../app/store';
import api from "../app/api";
import { RootState } from "../app/store";
//import { fetchCount } from './counterAPI';
import {
  Player,
  Team,
  Position,
  Match,
  Game,
  SimplePlayer,
  MatchWithRanks,
  Placing,
  SCTeamWithPlacings,
  PlayerOwnership,
  Ownership,
} from "../types/types";

export interface FrontState {
  frontData: {
    players: Player[];
    teams: Team[];
    positions: Position[];
    topPlayers: Player[];
    lastMatches: Match[];
    toplastround: Player[];
    avgBase: Player[];
    mostOwned: {
      player: SimplePlayer;
      ownership: Ownership;
    }[];
    PositionPlayers: {
      players: Player[];
    };
    gainers: {
      player: Player;
      twordAgo: number;
      rdAgo: number;
      diff: number;
    }[];
    lastroundPlacing: Placing[];
    featuredTeam: {
      team: SCTeamWithPlacings;
      placing: Placing;
      ownerships: PlayerOwnership;
    };
    lowbeplayers: Player[];
  };
  PositionPlayers: {
    players: Player[];
  };
  allplayers: SimplePlayer[];
  lastroundPlacing: Placing[];
  featuredTeam: {
    team: SCTeamWithPlacings;
    placing: Placing;
    ownerships: PlayerOwnership;
  };
  singlePlayer: {
    player: Player;
    games: Game[];
    matches: Match[];
    upcoming: MatchWithRanks[];
    stats: {
      be: number;
      nv: number;
      last5: number[];
      three: number | string;
      five: number | string;
      avgpts: number;
      avgbase: number;
      aveppm: number;
    };
  };
  mostViewed: Player[];
  status: "idle" | "loading" | "failed";
}

export interface action {
  type: String;
  payload: {
    teams: [];
    players: [];
    positions: [];
    topPlayers: [];
    lastMatches: [];
    toplastround: [];
    allplayers: [];
    avgBase: [];
    PositionPlayers: { players: [] };
    gainers: [];
    lastroundPlacing: [];
    featuredTeam: {
      team: SCTeamWithPlacings;
      placing: Placing;
      ownerships: PlayerOwnership;
    };
    mostOwned: { player: SimplePlayer; ownership: Ownership }[];
    lowbeplayers: Player[];
  };
}

export interface SinglePlayerAction {
  player: Player;
  games: Game[];
  matches: Match[];
  upcoming: MatchWithRanks[];
  stats: {
    be: number;
    nv: number;
    last5: number[];
    five: string | number;
    three: string | number;
    avgpts: number;
    avgbase: number;
    aveppm: number;
  };
}

export interface SinglePlayerPayload {
  type: String;
  payload: { player: Player; games: Game[]; stats: object };
}

export interface payloadAction {
  teams: Team[];
  players: Player[];
  positions: Position[];
  topPlayers: Player[];
  lastMatches: Match[];
  toplastround: Player[];
  avgBase: Player[];
  allplayers: SimplePlayer[];
  PositionPlayers: { players: Player[] };
  lastroundPlacing: Placing[];
  mostOwned: { player: SimplePlayer; ownership: Ownership }[];
  gainers: {
    player: Player;
    twordAgo: number;
    rdAgo: number;
    diff: number;
  }[];
  featuredTeam: {
    team: SCTeamWithPlacings;
    placing: Placing;
    ownerships: PlayerOwnership;
  };
  lowbeplayers: Player[];
}

export interface mostPopularAction {
  mostViewed: Player[];
}

const initialState: FrontState = {
  frontData: {
    players: [],
    teams: [],
    positions: [],
    topPlayers: [],
    lastMatches: [],
    toplastround: [],
    allplayers: [],
    PositionPlayers: { players: [] },
    gainers: [],
    lastroundPlacing: [],
    mostOwned: [],
    avgBase: [],
    //@ts-ignore
    featuredTeam: { placing: {}, team: {}, ownerships: {} },
    lowbeplayers: [],
  },
  allplayers: [],

  PositionPlayers: { players: [] },
  singlePlayer: {
    player: {
      id: "-1",
      firstname: "",
      lastname: "",
      pic: "",
      position: { name: "" },
      stats: [
        {
          last5Games: [],
          timestamp: "",
          avgpts: 0,
          avgbase: 0,
          ppm: 0,
          season: "",
          lastgame: null,
        },
      ],
      positionrank: [],
      ownerships: [],
    },
    games: [],
    matches: [],
    upcoming: [],
    stats: {
      be: -1,
      nv: -1,
      last5: [],
      three: -1,
      five: -1,
      avgpts: 0,
      avgbase: 0,
      aveppm: 0,
    },
  },
  mostViewed: [],
  status: "idle",
};

const fetchFront = () => {
  return new Promise<{ data: payloadAction }>((resolve) => {
    api.get("/front").then(function (payload) {
      console.log("Fetch front payload: ", payload);
      resolve(payload);
    });
  });
};

const fetchAllPlayers = () => {
  return new Promise<{ data: payloadAction }>((resolve) => {
    api.get("/allplayers").then(function (payload) {
      console.log("Fetch allplayers: ", payload);
      resolve(payload);
    });
  });
};

const fetchFrontSC = () => {
  return new Promise<{ data: payloadAction }>((resolve) => {
    api.get("/frontsc").then(function (payload) {
      console.log("Fetch frontsc: ", payload);
      resolve(payload);
    });
  });
};

const fetchSinglePlayer = (id?: string) => {
  return new Promise<{ data: SinglePlayerAction }>((resolve) => {
    api.get("/player/" + id).then(function (payload) {
      resolve(payload);
    });
  });
};

const fetchMostPopular = () => {
  return new Promise<{ data: mostPopularAction }>((resolve) => {
    api.get("/mostpopular/").then(function (payload) {
      resolve(payload);
    });
  });
};

const fetchPositionPlayers = () => {
  return new Promise<{ data: mostPopularAction }>((resolve) => {
    api.get("/frontpp").then(function (payload) {
      resolve(payload);
    });
  });
};

export const GetMostPopular = createAsyncThunk(
  "front/getmostpopular",
  async () => {
    const response = await fetchMostPopular();
    return response;
  }
);

export const GetSinglePlayer = createAsyncThunk(
  "front/getsingleplayer",
  async (id?: string) => {
    const response = await fetchSinglePlayer(id);
    return response;
  }
);

export const getFront = createAsyncThunk("front/getfront", async () => {
  const response = await fetchFront();
  return response;
});

export const getFrontSC = createAsyncThunk("front/getfrontsc", async () => {
  const response = await fetchFrontSC();
  return response;
});

export const getAllPlayers = createAsyncThunk(
  "front/getallplayers",
  async () => {
    const response = await fetchAllPlayers();
    return response;
  }
);

export const getPositionPlayers = createAsyncThunk(
  "front/getpositionplayers",
  async () => {
    const response = await fetchPositionPlayers();
    return response;
  }
);

export const frontSlice = createSlice({
  name: "front",
  initialState,
  reducers: {
    getfront: (state, action: action) => {
      state.frontData = action.payload;
    },
    getsingleplayer: (state, action: action) => {},
    getallplayers: (state, action: action) => {
      //@ts-ignore
      state.frontData.allplayers = action.payload;
    },
    getfrontsc: (state, action: action) => {},
    getpositionplayers: (state, action: action) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFront.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFront.fulfilled, (state, action) => {
        state.status = "idle";
        state.frontData = action.payload.data;
      })
      .addCase(GetSinglePlayer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetSinglePlayer.fulfilled, (state, action) => {
        state.status = "idle";
        state.singlePlayer = action.payload.data;
        console.log("action.payload.data: ", action.payload.data);
      })
      .addCase(GetMostPopular.pending, (state) => {})
      .addCase(GetMostPopular.fulfilled, (state, action) => {
        state.mostViewed = action.payload.data.mostViewed;
        console.log("GetMostPopular: ", action.payload.data);
      })
      .addCase(getAllPlayers.fulfilled, (state, action) => {
        state.allplayers = action.payload.data.players;
      })
      .addCase(getFrontSC.fulfilled, (state, action) => {
        state.lastroundPlacing = action.payload.data.lastroundPlacing;
        state.featuredTeam = action.payload.data.featuredTeam;
      })
      .addCase(getPositionPlayers.fulfilled, (state, action) => {
        console.log("getPositionPlayers: ", action.payload.data);
        //@ts-ignore
        state.PositionPlayers = action.payload.data.PositionPlayers;
      });
  },
});

export const selectPlayers = (state: RootState) =>
  state.front.frontData?.players;

export const selectPositions = (state: RootState) =>
  state.front.frontData?.positions;

export const selectTeams = (state: RootState) => state.front.frontData?.teams;

export const selectFront = (state: RootState) => state.front;

export const selectAVGBase = (state: RootState) =>
  state.front.frontData.avgBase;

export const selectTopPlayers = (state: RootState) =>
  state.front.frontData.topPlayers;

export const selectLastMatches = (state: RootState) =>
  state.front.frontData.lastMatches;

export const selectTopLastRound = (state: RootState) =>
  state.front.frontData.toplastround;

export const selectGainers = (state: RootState) =>
  state.front.frontData.gainers;

export const selectLoadingStatus = (state: RootState) => state.front.status;

export const selectPlayer = (id?: string) => (state: RootState) =>
  state.front.frontData.players.filter(function (player: Player) {
    return player.id.toString() === id;
  });

export const selectAllPlayersList = (state: RootState) =>
  state.front.allplayers;

export const selectSinglePlayer = (state: RootState) =>
  state.front.singlePlayer;

export const selectUpcoming = (state: RootState) =>
  state.front.singlePlayer.upcoming;

export const selectTopPlayerPositions = (state: RootState) =>
  state.front.PositionPlayers;

export const selectFrontPlacings = (state: RootState) =>
  state.front.lastroundPlacing;

export const selectFeaturedTeam = (state: RootState) =>
  state.front.featuredTeam;

export const selectLowBEPlayers = (state: RootState) =>
  state.front.frontData.lowbeplayers;

export const selectMostViewed = (state: RootState) => state.front.mostViewed;

export const selectMostOwned = (state: RootState) =>
  state.front.frontData.mostOwned;

export const { getfront } = frontSlice.actions;

export default frontSlice.reducer;
