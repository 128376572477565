import React, { useEffect, useState } from "react";
import { LightPlayer } from "../../types/types";
import {
  CURRENT_SEASON_STRING,
  TRIMMEDPOSITIONLIST,
} from "../../Globals/Globals";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "underscore";

export interface Props {
  players: LightPlayer[];
  setPlayers: (players: LightPlayer[]) => void;
  resetPlayers: () => void;
}

const FindPlayersInput = ({ players, setPlayers, resetPlayers }: Props) => {
  const [selectedPositions, setPositions] = useState("*");
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedPriceDirection, setSelectedPriceDirection] = useState(">");
  const [priceInputValue, setPriceInputValue] = useState("");

  const [selectedBEDirection, setSelectedBEDirection] = useState(">");
  const [BEInputValue, setBEInputValue] = useState("");
  const [selectedBE, setSelectedBE] = useState(0);

  const [selectedPointsDirection, setSelectedPointsDirection] = useState(">");
  const [PointsInputValue, setPointsInputValue] = useState("");
  const [selectedPoints, setSelectedPoints] = useState(0);

  function FilterChange() {
    let newplayers = players.slice();
    let keepplayers: LightPlayer[] = [];
    newplayers.map(function (player) {
      let include = true;
      const theStat = player.stats
        ?.filter((stat) => stat.season === CURRENT_SEASON_STRING)
        .pop();
      const lastgame = theStat?.lastgame;
      console.log("PLAYER: ", { lastgame, theStat, player });
      if (!theStat) {
        return;
      }
      const playerstats = theStat;

      if (selectedPositions !== "*") {
        let playerpositions: string[] = [];

        player.position.map(function (position) {
          playerpositions.push(position.name);
        });
        if (!playerpositions.includes(selectedPositions)) {
          include = false;
        }
      }
      if (selectedPrice !== 0) {
        if (selectedPriceDirection === ">") {
          if (lastgame.price < selectedPrice) {
            include = false;
          }
        } else {
          if (lastgame.price > selectedPrice) {
            include = false;
          }
        }
      }
      if (selectedBE !== 0) {
        if (selectedBEDirection === ">") {
          if (lastgame.scbe < selectedBE) {
            include = false;
          }
        } else {
          if (lastgame.scbe > selectedBE) {
            include = false;
          }
        }
      }
      if (selectedPoints !== 0) {
        if (selectedPointsDirection === ">") {
          if (playerstats.avgpts < selectedPoints) {
            include = false;
          }
        } else {
          if (playerstats.avgpts > selectedPoints) {
            include = false;
          }
        }
      }
      if (include) {
        keepplayers.push(player);
      }
    });

    setPlayers(keepplayers);
  }

  function onPositionChange(changeevent: SelectChangeEvent<string>) {
    let newvalues = changeevent.target.value;
    setPositions(newvalues);
  }

  function onPriceChange(changeevent: React.ChangeEvent<HTMLInputElement>) {
    let newprice = changeevent.target.value;
    if (!isNaN(parseInt(newprice))) {
      setSelectedPrice(parseInt(newprice));
    }
    setPriceInputValue(newprice);
  }

  function onBEChange(changeevent: React.ChangeEvent<HTMLInputElement>) {
    let newBE = changeevent.target.value;
    if (!isNaN(parseInt(newBE))) {
      setSelectedBE(parseInt(newBE));
    }
    setBEInputValue(newBE);
  }

  function onPTSChange(changeevent: React.ChangeEvent<HTMLInputElement>) {
    let newPTS = changeevent.target.value;
    if (!isNaN(parseInt(newPTS))) {
      setSelectedPoints(parseInt(newPTS));
    }
    setPointsInputValue(newPTS);
  }

  useEffect(() => {
    FilterChange();
  }, [
    selectedPositions,
    selectedPrice,
    selectedPriceDirection,
    selectedBE,
    selectedPoints,
    selectedPriceDirection,
    selectedBEDirection,
    selectedPointsDirection,
  ]);

  return (
    <div
      style={{
        display: "flex",
        marginTop: "1rem",
        columnGap: "1rem",
        marginBottom: "1rem",
        overflowY: "scroll",
      }}
    >
      <div>
        <InputLabel
          id={"positioninputlabel"}
          style={{ marginBottom: "0.5rem" }}
        >
          Position
        </InputLabel>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Select
            value={selectedPositions}
            label="Position"
            onChange={(e) => onPositionChange(e)}
            labelId={"positioninputlabel"}
          >
            <MenuItem value={"*"}>Any</MenuItem>
            {TRIMMEDPOSITIONLIST.map(function (position) {
              return <MenuItem value={position}>{position}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <div>
        <InputLabel
          id={"positioninputlabel1"}
          style={{ marginBottom: "0.5rem" }}
        >
          Price
        </InputLabel>
        <FormControl
          size="small"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <InputLabel
            id={"positiondirectionlabel1"}
            style={{ marginBottom: "0.5rem" }}
          >
            Direction
          </InputLabel>
          <Select
            value={selectedPriceDirection}
            label="Direction"
            onChange={(e) => {
              setSelectedPriceDirection(e.target.value);
            }}
            sx={{ width: "80px" }}
          >
            <MenuItem value="<">{"<"}</MenuItem>
            <MenuItem value=">">{">"}</MenuItem>
          </Select>
          <TextField
            label="Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            value={priceInputValue}
            onChange={onPriceChange}
          />
        </FormControl>
      </div>
      <div>
        <InputLabel id={"beinputlabel"} style={{ marginBottom: "0.5rem" }}>
          Break Even
        </InputLabel>
        <FormControl
          size="small"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <InputLabel
            id={"bedirectionlabel"}
            style={{ marginBottom: "0.5rem" }}
          >
            Direction
          </InputLabel>
          <Select
            value={selectedPriceDirection}
            label="Direction"
            onChange={(e) => {
              setSelectedPriceDirection(e.target.value);
            }}
            sx={{ width: "80px" }}
          >
            <MenuItem value="<">{"<"}</MenuItem>
            <MenuItem value=">">{">"}</MenuItem>
          </Select>
          <TextField
            label="Breakeven"
            value={BEInputValue}
            onChange={onBEChange}
          />
        </FormControl>
      </div>

      <div>
        <InputLabel id={"ptsinputlabel"} style={{ marginBottom: "0.5rem" }}>
          AVG Pts
        </InputLabel>
        <FormControl
          size="small"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <InputLabel
            id={"ptsdirectionlabel"}
            style={{ marginBottom: "0.5rem" }}
          >
            Direction
          </InputLabel>
          <Select
            value={selectedPointsDirection}
            label="Direction"
            onChange={(e) => {
              setSelectedPointsDirection(e.target.value);
            }}
            sx={{ width: "80px" }}
          >
            <MenuItem value="<">{"<"}</MenuItem>
            <MenuItem value=">">{">"}</MenuItem>
          </Select>
          <TextField
            label="Pts"
            value={PointsInputValue}
            onChange={onPTSChange}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default FindPlayersInput;
