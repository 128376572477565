import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import internal from 'stream';
import { InputLabel, TextField } from '@mui/material';
import { RegisterEvent } from '../../functions/Analytics';

const VCLoopHole = () => {
    const [ vcScore, setvcScore ] = useState(0);
    const [ cScore, setcScore ] = useState(0);
    const [ dpScore, setdpScore ] = useState(0);
    const [ aeScore, setaeScore ] = useState(0);
    const [ loopholeScore, setLoopholeScore] = useState(0);
    const [ noLoopholeScore, setNoLoopholeScore] = useState(0);
    const [scores, setScores ] = useState({'vcScore': 0,'cScore':0,'dpScore':0,'aeScore':0});
    function CalculateLoopHole() {
        let nlh = scores['vcScore'] + (scores['cScore']*2) + scores['dpScore'];
        let lh = (scores['vcScore'] * 2) + scores['cScore'] + scores['aeScore'];

        setLoopholeScore(lh);
        setNoLoopholeScore(nlh);
        RegisterEvent({category: "tools", action: "Calculateloophole", label:"Calculated Loophole"})
    }

    function onChange(value:number,score:string) {
        if (!isNaN(value)) {
            let newscores = scores;
            newscores[score]=value;
            setScores(newscores);
            CalculateLoopHole();
        }
    }
    return (<Paper style={{display: "flex",padding: "1rem",columnGap: "1rem",flexWrap: "wrap", justifyContent: 'center'}}>
        <h1 style={{width: "100%", textAlign: "center"}}>Vice Captain Loophole</h1>
        <Paper style={{display: "flex", flexDirection: "column", rowGap: "1rem",padding: "1rem"}}>
            <TextField
                label="Captain Score"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                key="CaptainsScore"
                value={scores['cScore']}
                onChange={ e=> { onChange(parseInt(e.target.value),'cScore')}}
            />
            <TextField
                label="Vice Captain Score"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                key="ViceCaptainsScore"
                value={scores['vcScore']}
                onChange={ e=> { onChange(parseInt(e.target.value),'vcScore')}}
            />
            <TextField
                label="Dropped Player's Score"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                key="DroppedPlayerScore"
                value={scores['dpScore']}
                onChange={ e=> { onChange(parseInt(e.target.value),'dpScore')}}
            />
            <TextField
                label="Auto Emergency Score"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                key="AutoEmergencyScore"
                value={scores['aeScore']}
                onChange={ e=> { onChange(parseInt(e.target.value),'aeScore')}}
            />
        </Paper>
        <div style={{display: "flex", flexDirection: "row", alignSelf: "center",flexWrap: "wrap", padding: "1rem", columnGap: "1rem"}}>
            <div style={{width: "100%", textAlign: "left"}}>
                <h4>Scores</h4>
            </div>
            <div style={{width: "calc(50% - 1rem)"}}>
                <Paper style={{padding: "1.5rem"}} elevation={5}>
                    <h2>{noLoopholeScore}</h2>
                    <b>No Loop Hole</b>
                </Paper>
            </div>
            <div style={{width: "calc(50% - 1rem)"}}>
                <Paper style={{padding: "1.5rem"}} elevation={5}>
                    <h2>{loopholeScore}</h2>
                    <b>Loop Hole</b>
                </Paper>
            </div>
        </div>
    </Paper>)
}

export default VCLoopHole;