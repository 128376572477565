import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import FindPlayersTable from "../components/FindPlayers/FindPlayersTable";
import FindPlayersInput from "../components/FindPlayers/FindPlayersInput";
import { Container } from "@mui/material";
import NavContainer from "../components/Navigation/NavContainer";
import {
  getAll,
  selectStatus,
  selectAllPlayers,
} from "../reducers/FindPlayers";
import { LightPlayer } from "../types/types";
import _ from "underscore";
import Loading from "../components/Loading";
import Header from "../components/FrontPage/Header";
import AdsenseAdd from "../components/AdsenseAdd";

const FilterPlayers = (
  fields: string[],
  values: (string | number)[],
  players: LightPlayer
) => {
  const newplayers: LightPlayer[] = _.filter(players, function (player) {
    let include = true;
    fields.map(function (field, index) {
      if (values[index] !== "*") {
        if (player[field] !== values[index]) {
          include = false;
        }
      }
    });
    return include;
  });
};

const FindPlayer = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectStatus);
  const allplayers = useAppSelector(selectAllPlayers);
  const [players, setPlayers] = useState(allplayers.slice());
  const [position, SetPosition] = useState("*");

  useEffect(() => {
    ResetPlayers();
  }, [allplayers, loading]);

  useEffect(() => {
    dispatch(getAll());
  }, []);

  function ResetPlayers() {
    setPlayers(allplayers.slice());
  }

  return (
    <Container
      maxWidth="xl"
      style={{ marginBottom: "2rem", marginLeft: "auto", marginRight: "auto" }}
    >
      <NavContainer />
      <Header />
      <FindPlayersInput
        players={allplayers}
        setPlayers={setPlayers}
        resetPlayers={ResetPlayers}
      />
      {loading === "loading" ? (
        <Loading />
      ) : (
        <>
          <FindPlayersTable players={players} />
        </>
      )}
      <AdsenseAdd />
    </Container>
  );
};

export default FindPlayer;
