import styled from "@emotion/styled";
import { Container } from "@mui/material";

export var TeamsList = {
  Broncos: ["#6c1d45", "#521633"],
  Raiders: ["#94c949", "#679c40"],

  Bulldogs: ["#00468b", "#00306f"],
  Sharks: ["#1f7eb2", "#15679f"],
  Titans: ["#047db0", "#084970"],
  Eagles: ["#660238", "#500128"],
  "Sea Eagles": ["#660238", "#500128"],
  Storm: ["#592b8c", "#4f1782"],
  Knights: ["#003b73", "#002b5d"],
  Cowboys: ["#0c2340", "#0c192f"],
  Eels: ["#002d72", "#001b46"],
  Panthers: ["#2c2f30", "#1f2122"],
  Rabbitohs: ["#003b19", "#002b11"],
  Dragons: ["#cd232c", "#a6121c"],
  Roosters: ["#0b2d5b", "#072046"],
  Warriors: ["#151f6d", "#0e1557"],
  Tigers: ["#262626", "#161616"],
  "Wests Tigers": ["#262626", "#161616"],
  Dolphins: ["#e0121a", "#bd0f16"],
  "": ["", ""],
};

export var TeamsBGColors = {
  Broncos: "transparent",
  Raiders: "white",
  Bulldogs: "transparent",
  Sharks: "transparent",
  Titans: "transparent",
  Eagles: "white",
  "Sea Eagles": "transparent",
  Storm: "transparent",
  Knights: "transparent",
  Cowboys: "transparent",
  Eels: "white",
  Panthers: "white",
  Rabbitohs: "white",
  Dragons: "white",
  Roosters: "white",
  Warriors: "transparent",
  Tigers: "white",
  "Wests Tigers": "transparent",
  Dolphins: "transparent",
};

export const TeamLogos = {
  Dragons:
    "https://www.nrl.com/Client/dist/logos/dragons-badge.svg?bust=202110292",
  Titans:
    "https://www.nrl.com/Client/dist/logos/titans-badge.svg?bust=202110292",
  Roosters:
    "https://www.nrl.com/Client/dist/logos/roosters-badge.svg?bust=202110292",
  Broncos:
    "https://www.nrl.com/Client/dist/logos/broncos-badge-light.svg?bust=202110292",
  Knights:
    "https://www.nrl.com/Client/dist/logos/knights-badge-light.svg?bust=202110292",
  Eels: "https://www.nrl.com/Client/dist/logos/eels-badge.svg?bust=202110292",
  Panthers:
    "https://www.nrl.com/Client/dist/logos/panthers-badge-light.svg?bust=202110292",
  "Sea Eagles":
    "https://www.nrl.com/Client/dist/logos/sea-eagles-badge.svg?bust=202110292",
  Raiders:
    "https://www.nrl.com/Client/dist/logos/raiders-badge-light.svg?bust=202110292",
  Sharks:
    "https://www.nrl.com/Client/dist/logos/sharks-badge-light.svg?bust=202110292",
  Rabbitohs:
    "https://www.nrl.com/Client/dist/logos/rabbitohs-badge.svg?bust=202110292",
  Warriors:
    "https://www.nrl.com/Client/dist/logos/warriors-badge-light.svg?bust=202110292",
  Tigers:
    "https://www.nrl.com/.theme/wests-tigers/badge-basic24.svg?bust=202403120144",
  "Wests Tigers":
    "https://www.nrl.com/.theme/wests-tigers/badge-basic24.svg?bust=202403120144",
  Storm:
    "https://www.melbournestorm.com.au/client/dist/logos/storm-badge-light.svg?bust=202202171",
  Cowboys:
    "https://www.nrl.com/Client/dist/logos/cowboys-badge-light.svg?bust=202110292",
  Bulldogs:
    "https://www.nrl.com/Client/dist/logos/bulldogs-badge.svg?bust=202110292",
  Dolphins:
    "https://www.dolphinsnrl.com.au/.theme/dolphins/badge-light.svg?bust=202302142313",
  undefined: "",
};

export const FILTERITEMS = [
  "points",
  "tackles",
  "missed_tackles",
  "base_points",
  "create_points",
  "negative_play_points",
  "scoring_points",
  "evade_points",
  "minutes_played",
  "missed_tackles",
  "tries",
  "hitups_over_8m",
  "hitups_under_8m",
  "errors",
];

export const CHARTCOLORS = [
  "#2EB67D",
  "#36C5F0",
  "#E01E5A",
  "#910000",
  "#ECB22E",
  "#492970",
  "#f28f43",
  "#77a1e5",
  "#c42525",
  "#a6c96a",
];

export const RankColors = {
  1: "#339900",
  2: "#339900",
  3: "#339900",
  4: "#99cc33",
  5: "#99cc33",
  6: "#99cc33",
  7: "#ffcc00",
  8: "#ffcc00",
  9: "#ffcc00",
  10: "#ff9966",
  11: "#ff9966",
  12: "#ff9966",
  13: "#ff4e08",
  14: "#ff4e08",
  15: "#ff4e08",
  16: "#ff4e08",
};
export const ReverseRankColors = {
  16: "#339900",
  15: "#339900",
  14: "#339900",
  13: "#99cc33",
  12: "#99cc33",
  11: "#99cc33",
  10: "#ffcc00",
  9: "#ffcc00",
  8: "#ffcc00",
  7: "#ff9966",
  6: "#ff9966",
  5: "#ff9966",
  4: "#ff4e08",
  3: "#ff4e08",
  2: "#ff4e08",
  1: "#ff4e08",
};

export const RankText = {
  0: "0th",
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
  7: "7th",
  8: "8th",
  9: "9th",
  10: "10th",
  11: "11th",
  12: "12th",
  13: "13th",
  14: "14th",
  15: "15th",
  16: "16th",
};

export const MyContainer = styled(Container)`
  @media (min-width: 600px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
`;

export const TOOLTIPS = {
  PTS: "Aerage Supercoach points scored",
  PPM: "Points per minute",
  BE: "Breakeven",
  "3RA": "Average points scored for last 3 rounds",
  Mins: "Average minutes played",
  Base: "(Runs + Tackles) - Missed Tackles",
  Ownership: "Total Ownership",
  Minutes: "Average minutes played",
  "3/5rd Avg": "3 round average, 5 round average points scored",
  Price: "Price of player now (change in price)",
  AVG: "Average points this season",
  "2022 AVG": "Average points 2022 season",
  "Base + Power": "Total Base + Power Points",
};
