import React from 'react';
import { SimplePlayer } from '../../types/types';
import { MenuItem, Paper, Select } from '@mui/material';
import AutoComplete from './AutoComplete';
import styled from '@emotion/styled';
export interface FloatingMenuProps {
    selectedPlayer: string;
    setSelectedPlayer: (string) => void;
    players: SimplePlayer[];
}

const MenuContainer = styled.div`
    top: 1rem;
    position: absolute;
    @media (min-width: 600px) {
        top: 1rem;
        position: fixed;
    }
`

const FloatingMenu = ({selectedPlayer, setSelectedPlayer, players}) => {
    return <MenuContainer style={{padding: "1rem",right: '1rem', width: 300, zIndex: 1000}} className='floatingmenu'>
        <AutoComplete players={players} setSelectedPlayer={setSelectedPlayer} />
    </MenuContainer>
}

export default FloatingMenu;