import React, { useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import './App.css';
import FrontPage  from './screens/Frontpage';
import SinglePlayer  from './screens/SinglePlayer2';
import SingleTeam from './screens/SingleTeam';
import SinglePosition from './screens/SinglePosition';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import SettingsIcon from '@mui/icons-material/Settings';
import UserDrawer from './components/UserDrawer';
import SelectedPlayersContainer from './components/SelectedPlayers/SelectedPlayersContainer';
import SingleMatch from './screens/SingleMatch';
import NavContainer from './components/Navigation/NavContainer';
import SearchScreen from './screens/SearchScreen';
import ComparePlayers from './screens/ComparePlayers';
import styled from '@emotion/styled';
import ReactGA from 'react-ga';
import Footer from './components/Footer';
import AppSnackbar from './components/AppSnackbar';
import FindPlayer from './screens/FindPlayer';
import Tools from './screens/Tools';
import SCTeams from './screens/SCTeams';
import SingleSCTeam from './screens/SingleSCTeam';
import SinglePlayerScreen from './screens/PlayerScreen';
import OwnershipsScreen from './screens/OwnershipsScreen';
//G-YHVPGC0KN7


const MyContainer = styled(Container)`
  
  @media (min-width: 600px) {
    
  }
  padding-bottom: 5rem;
`

function App() {
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  ReactGA.initialize('UA-187848395-2', {
    debug: false,
    titleCase: false,  
  });
  return (
    <div className="App">

      <CssBaseline />
      <div style={{minHeight: "80vh"}}>
        { /*<SettingsIcon style={{position: "fixed", top: "0.5rem", right: "0.5rem", zIndex: 2000 }} onClick={e => setDrawerOpen(!drawerOpen)}/> */ }
         <UserDrawer open={drawerOpen} setOpen={setDrawerOpen} /> 
        
        <Router>
        <SelectedPlayersContainer />
          <AppSnackbar />
          <Routes>
              <Route path='/' element={<FrontPage />} />
              <Route path='/player/:id' element={<SinglePlayer />} />
              <Route path='/team/:name' element={<SingleTeam />}/>
              <Route path='/position/:name' element={<SinglePosition />}/>
              <Route path='/match/:matchId' element={<SingleMatch type="matchid" />}/>
              <Route path='/match/:season/:round/:team' element={<SingleMatch type="seasonround" />} />
              <Route path='/search' element={<SearchScreen />} />
              <Route path='/compareplayers' element={<ComparePlayers />} />
              <Route path='/findplayers' element={<FindPlayer />} />
              <Route path='/tools/:tool' element={<Tools />} />
              <Route path='/scteams' element={<SCTeams />} />
              <Route path='/scteams/:teamid' element={<SingleSCTeam />} />
              <Route path="/players" element={<SinglePlayerScreen />} />
              <Route path="/ownerships" element={<OwnershipsScreen />} />
            </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
