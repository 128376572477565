import { MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import React from 'react';
import { RoundedCTAPaper, SMALLPOSITIONLIST } from '../Positions/PositionHeader';
import { LASTROUND } from '../../Globals/Globals';

export interface OwnershipsHeaderProps {
    round: number;
    setRound: (round: number) => void;
    position: string;
    setPosition: (pos: string) => void;
}

const OwnershipsHeader =({round,setRound, position, setPosition}:OwnershipsHeaderProps) => {
    function handleRoundChange(event: SelectChangeEvent<string>, child: React.ReactNode) {
        
        setRound(parseInt(event.target.value));
    }
    function handlePositionChange(event: SelectChangeEvent<string>, child: React.ReactNode) {
        
        setPosition(event.target.value);
    }

    
    return <div style={{display: "flex", padding: "1rem", alignItems: "center"}}>
        <Paper elevation={5} style={{display: "flex", height: 112}}>
            <div style={{height: '100%', display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "0.5rem"}}>
                <Typography variant='h6'>
                    Positional Ownership
                </Typography>
                <Typography variant='body1' textAlign="left">
                    View positonal ownership by round & position
                </Typography>
            </div>
        </Paper>
        <Paper elevation={5} style={{display: "flex", columnGap: "1rem", alignItems: "center", marginLeft:"auto"}}>
            
            <div style={{display: "flex", flexDirection: "column"}}>
                
                <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
                
                <Typography variant="h6" style={{width: 100}}>
                    Position
                </Typography>
                <Select
                    value={position}
                    onChange={handlePositionChange}
                    style={{width: 100}}
                >
                    {SMALLPOSITIONLIST.map(function(theposition) {
                        return <MenuItem value={theposition}>{theposition}</MenuItem>
                    })}
                </Select>
                </div>
                <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
                    <Typography variant="h6" style={{width: 100}}>
                        Round
                        </Typography>
                    <Select 
                        value={round.toString()}
                        onChange={handleRoundChange}
                        label="Round"
                        style={{width: 100}}
                    >
                        {Array.from({length: LASTROUND}, (_, i) => i + 1).map(function(index) {
                            return <MenuItem value={index}>{index}</MenuItem>
                        })}

                    </Select>
                </div>
            </div>
        
        </Paper>
    </div>
}

export default OwnershipsHeader;