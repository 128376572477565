import React, { useEffect, useState } from 'react';
import { OwnershipWithPlayer } from '../../types/types';
import PlayerDataGrid from '../PlayerDataGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

export interface OwnershipsTableProps {
    ownerships: OwnershipWithPlayer[];
}



const renderOwnershipCell = (params: GridRenderCellParams<Date>) => {
    // @ts-ignore
    let value = parseInt(params.value) ? parseInt(params.value) : 1;
    return <div style={{backgroundColor: `#FF0000${value}`, width: "100%", textAlign: "center"}}>{value}%</div>
}

const columns:GridColDef[] = [
    {field: 'player', headerName: 'Player', width: 200},
    { field: 'top100', headerName: 'Top 100',width: 125,flex: 1, renderCell: (params: GridRenderCellParams<Date>) => {
    return renderOwnershipCell(params);
    },cellClassName: "no-padding-cell", headerClassName:"no-padding-header"
    },
    { field: 'top1000', headerName: 'Top 1000', width: 125,flex: 1, renderCell: (params: GridRenderCellParams<Date>) => {
        return renderOwnershipCell(params);
        },cellClassName: "no-padding-cell", headerClassName:"no-padding-header"
    },
    { field: 'top5000', headerName: 'Top 5000', width: 125,flex: 1, renderCell: (params: GridRenderCellParams<Date>) => {
        return renderOwnershipCell(params);
        },cellClassName: "no-padding-cell", headerClassName:"no-padding-header"
    },
    { field: 'top10000', headerName: 'Top 10,000', width: 125,flex: 1, renderCell: (params: GridRenderCellParams<Date>) => {
        return renderOwnershipCell(params);
        },cellClassName: "no-padding-cell", headerClassName:"no-padding-header"
    },
    { field: 'top20000', headerName: 'Top 20,000', width: 125,flex: 1, renderCell: (params: GridRenderCellParams<Date>) => {
        return renderOwnershipCell(params);
        },cellClassName: "no-padding-cell", headerClassName:"no-padding-header"
    },
    { field: 'top100000', headerName: 'Top 100,000', width: 125,flex: 1, renderCell: (params: GridRenderCellParams<Date>) => {
        return renderOwnershipCell(params);
        },cellClassName: "no-padding-cell", headerClassName:"no-padding-header"
    },
]

const OwnershipsTable = ({ownerships}:OwnershipsTableProps) => {
    const [rows, setRows ] = useState<object[]>([]);

    useEffect(() => {
        PopulateRows();
    },[ownerships])
    const PopulateRows = () => {
        let newRows = [];
        ownerships.map(function(ownership) {
            newRows.push({
                id: `ownerships-${ownership.player.id}-${ownership.round}`,
                top100: ownership.top100,
                top1000: ownership.top1000,
                top5000: ownership.top5000,
                top10000: ownership.top10000,
                top20000: ownership.top20000,
                top100000: ownership.top100000,
                player: `${ownership.player.firstname} ${ownership.player.lastname}`
            });
        })
        setRows(newRows);
    }
    return <>
        <PlayerDataGrid 
            rows={rows}
            columns={columns}
            height={600}
            initialSort={{
                sorting: {
                  sortModel: [{ field: 'top100000', sort: 'desc' }],
                },
              }}
        />
    </>
}

export default OwnershipsTable; 