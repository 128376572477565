import React, { ReactElement, useEffect, useState } from 'react';
import { Select, InputLabel, MenuItem, FormControl, ListSubheader } from '@mui/material';
import { Game, GraphType, Ownership } from '../../types/types';
import MultiSeriesGraph from '../MultiSeriesGraph';
import { SelectChangeEvent } from '@mui/material/Select';

export interface Props {
    defaultGraphItems: string[];
    games: Game[] | Ownership[];
    title: string;
    filteritems?: {
            name: string;
            options: [string,string][];
    }[];
}

interface ChartSeries {
    name: string;
    data: number[];
}


const NEGATIVEITEMS = [
    'missed_tackles',
    'negative_play_points',
]

function GenericChangeAbleGraph({defaultGraphItems, games, filteritems,title=""}:Props) {
    const [ selectedGraphItems, setSelectedGraphItems ] = useState<string[]>(defaultGraphItems);
    let [ graphdata, setGraphData ] = useState<GraphType[]>([]);
    let [ graphSeries, setGraphSeries ] = useState<ChartSeries[]>([]);
    let [ categories, setCategories ] = useState<string[]>([]);
    //@ts-ignore
    let filterItemArray = [];
    filteritems?.map(function(filteritem) {
        //@ts-ignore
        filterItemArray.push(<ListSubheader>{filteritem.name}</ListSubheader>);
        filteritem.options.map(function(option) {
            //@ts-ignore
            filterItemArray.push(<MenuItem value={option[0]}>{option[1]}</MenuItem>)
        })
    });

    useEffect(() => {
        PopulateGraph();
    }, [defaultGraphItems, games, selectedGraphItems])
    

    function onChange(changeevent:SelectChangeEvent<string[]>) {
        let newvalues = changeevent.target.value;
        if (Array.isArray(newvalues)) {
            setSelectedGraphItems(newvalues);
        }
        
    }

    function PopulateGraph() {
        let seriesArr:ChartSeries[] = [];
        let newCategories:string[] = [];
        //set up series
        selectedGraphItems.map(function(graphitem) {
            seriesArr.push({
                name: graphitem, 
                data: [],
            });
        })
        
        games.forEach(function(game:Game|Ownership) {
            selectedGraphItems.map(function(graphitem, graphItemIndex) {
                let item = game[graphitem];
                if (NEGATIVEITEMS.includes(graphitem)) {
                    item = -item;
                }
                seriesArr[graphItemIndex].data.push(item);
            });
            newCategories.push(`Round: ${game.round}`);

        });
        setGraphSeries(seriesArr);
        setCategories(newCategories)
    }
    
    return <div style={{display: "flex", flexWrap: "wrap"}}>
        
        <div style={{width: "100%"}}>
            <MultiSeriesGraph series={graphSeries} title="title" categories={categories} showtitle={selectedGraphItems.join(", ")} type="line" /> 
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start", marginLeft: "auto"}}> 
        <FormControl>
            <InputLabel id={`multiinputlabel`} htmlFor={"nativeselectoption"}>Graph Items</InputLabel>
            <Select 
                value={selectedGraphItems}
                label={'Graph Items'}
                
                onChange={e => onChange(e)}
                labelId={'multiinputlabel'}
                multiple
                id="nativeselectoption"
                //style={{width: 250, textAlign: "left", textTransform: 'capitalize'}}
            >
                {/*@ts-ignore */}
                {filterItemArray}   
            </Select>
        </FormControl>
        </div>
    </div>

}

export default GenericChangeAbleGraph;