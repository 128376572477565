import React, { useEffect } from 'react';
import {Adsense} from '@ctrl/react-adsense';
import { Paper } from '@mui/material';


const AdsenseAdd = () => {
    useEffect(() => {
      
      try {
        // @ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch(e) {
        console.log("e:", e);
      }
      
    },[]);
    return <></>
    // return (<div style={{width: "100%", display: "flex", justifyContent: 'center'}}>
    //   <Adsense
    //     client="ca-pub-7897141354951736"
    //     slot="6696503110"
    //     //adTest="on"
    //     style={{ display: 'block', width: "100%", height: "250px", marginBottom: "1rem" }}
    //     layout="in-article"
    //     format="auto"
    //     data-full-width-responsive="true"
    //   />
    //   </div>)
}

export default AdsenseAdd;