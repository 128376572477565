import React, { ReactNode} from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import MenuItem from '@mui/material/MenuItem';

interface Props {
    name: string;
    options: string[];
    onChange(selectedItem: string, term: string): any;
    selected: string;
    term: string;
}

function Filter ({options, onChange, selected, name, term}: Props) {
    return (<div style={{display: "flex", flexDirection: "column"}}>
    <InputLabel id={`${name}-inputlabel`}>{name}</InputLabel>
    <Select
        value={selected}
        label={name}
        onChange={e => onChange(e.target.value, term)}
        labelId={`${name}-inputlabel`}
    >
        {options.map(function(option) {
            return (<MenuItem value={option}>{option}</MenuItem>);
        })}
    </Select></div>)
}
export default Filter;