import React, { useEffect, useRef, useState } from 'react';
import { Placing, LightPlayer, Game, PlayerOwnership, SCTeam, SCTeamWithPlacings, PlayerOwnershipPlayer} from '../../../types/types';
import { Paper, Tooltip, Typography, useTheme, Tabs, Tab } from '@mui/material';
import styled from '@emotion/styled';
import SCTeamHeader from './SCTeamHeader';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FeaturedTeamPlayers from './FeaturedTeamPlayers';
import RankGraph from './RankGraph';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export interface SinglePlayerOwnership {
    id: string;
    player: LightPlayer;
    played: string;
    game: Game;
}

export interface Props {
    players: PlayerOwnershipPlayer[];
    placing: Placing;
    featuredTeam: SCTeamWithPlacings;
}

const HeaderContainer = styled.div`
    display: flex; 
    cursor: pointer;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
    color: black;
    text-decoration: none !important;
    .icon-hide-for-large {
        display: none;
    }
    a {
        text-decoration: none !important;
    }
    @media(max-width:800px) {
        flex-direction: column;
        align-items: flex-start;
        .icon-show-for-large {
            display: none;
        }
        .icon-hide-for-large {
            display: block;
            margin-left: auto;
        }
        .text-container {
            width: 100%;
        }
        
    }
`;


const FeaturedTeamContainer = ({featuredTeam, players, placing}:Props) => {
    console.log("FTC: ", featuredTeam, placing, players);
    const theme = useTheme();
    const [ selectedTab, setSelectedTab ] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
      };
    
    //@ts-ignore
    let theCaptain:SinglePlayerOwnership = {};
    //@ts-ignore
    let theVice:SinglePlayerOwnership = {};

    players.map(function(player:PlayerOwnershipPlayer) {
        if (player.played === 'capt') {
            theCaptain = player;
        } 
        if (player.played === 'vice') {
            theVice = player;
        }
    });

    return <Paper elevation={5} style={{width: "100%", overflowY: "scroll", padding: "1rem"}}>
            <a href={`/scteams/${featuredTeam?.teamid}`}>
                <HeaderContainer className="sc-teams-header-container">
                    <div style={{display: "flex", alignItems: "center"}} className="text-container">
                        <AttachMoneyIcon sx={{fontSize: "4rem", color: theme.palette.mode === 'dark' ? '#21ace1' : '#21ace1'}}/>
                        <div>
                            <Typography variant="h6" style={{textAlign: "left"}}>Top Team </Typography>
                            <Typography gutterBottom variant="body2" style={{textAlign: "left"}}>This weeks top team</Typography>
                            
                        </div>
                        <KeyboardArrowRightIcon sx={{fontSize: '3rem'}} className="icon-hide-for-large"/>
                    </div>
                    <SCTeamHeader placing={placing} />
                    <KeyboardArrowRightIcon sx={{fontSize: '3rem'}} className="icon-show-for-large"/>
                </HeaderContainer>
            </a>
            <Tabs value={selectedTab} onChange={handleChange} style={{marginBottom: "0.5rem"}}>
                <Tab label={"Players"} />
                <Tab label={"Season Graph"} />
            </Tabs>

            {selectedTab === 0 ? <FeaturedTeamPlayers captain={theCaptain} vice={theVice} players={players} /> : null }
            {selectedTab === 1 ? <RankGraph placings={featuredTeam.placings} /> : null }
            
    </Paper>
}

export default FeaturedTeamContainer;