import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import NavDrawer from './NavDrawer';
import { RegisterModalView } from '../../functions/Analytics';

function NavContainer() {
    const [ drawerOpen, setDrawerOpen ] = useState(false);

    return (<div>
        <MenuIcon style={{position: 'fixed', top: "0.5rem", left: "0.5rem",zIndex: 1000}} onClick={e => {setDrawerOpen(true); RegisterModalView({name: "NavOpen"})}}/>
        <NavDrawer open={drawerOpen} setOpen={setDrawerOpen} />
    </div>

    )
}

export default NavContainer;
