import React, { useEffect, useState } from 'react';
import { SelectSinglePositionLoadingStatus } from '../../reducers/PositionReducer';
import { Player } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Link, Navigate } from 'react-router-dom';
import PlayerCard from '../PlayerCard/PlayerCard';
import { AddPlayer } from '../../reducers/ChosenPlayers';
import { Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import _, { sortBy } from 'underscore';
import {get_suffix} from '../../functions/Ordinal'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';  

export interface Props {
    positions: {
        players: Player[];
    } 
}
const NavLink = styled(Link)`
    align-items: center;
    margin-bottom: 1rem; 
    display: flex;
    text-decoration: none !important;
    h6, svg {
        color: black;
    }
`; 

const RankSpan = styled.span`
    position: absolute;
    top: 2.5rem;
    left: 0.5rem;
    z-index: 100;
    font-size: 1rem;
    color: white;
    font-weight: bold;
`
const BANNED_POSITIONS = ['Reserve', 'Replacement', 'Interchange'];

const PositionContainer = ({positions}:Props) => {
    const dispatch = useAppDispatch();
    
    return <div style={{width: "100%"}}>
        {Object.entries(positions).map(function(position) {
            console.log("POSITIONS: ", position);
            let positionName = position[0];
            if (BANNED_POSITIONS.includes(positionName) || position[1].length === 0) {
                return null;
            }
            let players = position[1];
            players = _.sortBy(players, function(player) {
                let rank = player.stats[0]?.last5Games[player.stats[0]?.last5Games.length-1]?.posrank ? player.stats[0]?.last5Games[player.stats[0]?.last5Games.length-1]?.posrank : '';
                let posranks = player.positionrank.filter(function(posrank) {
                    if (posrank.position.name === position[0]) {
                        return posrank;
                    }
                })
                if (posranks.length > 0) {
                    rank = posranks[posranks.length-1].totalrank
                }
                return rank
            })
            return <div style={{width: "100%", marginBottom: "1rem"}}>
                <Paper style={{padding: "1rem"}} elevation={5}>
                    <NavLink to={`/position/${positionName}`}>
                        <Typography variant="h6" style={{textAlign: "left"}}>{positionName}</Typography>
                        <ArrowForwardIosIcon sx={{fontSize: 20,marginLeft: "0.25rem"}} />
                    </NavLink>
                    <div style={{display: "flex",overflowX: "scroll", width: "100%"}}>
                        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr", gridTemplateRows: "1fr", columnGap: "0.5rem", rowGap: "1rem", marginLeft: "0.25rem", marginBottom: "0.5rem"}}>
                            {players.map(function(player, index) { 
                                
                                let rank = player.stats[0]?.last5Games[player.stats[0]?.last5Games.length-1]?.posrank ? player.stats[0]?.last5Games[player.stats[0]?.last5Games.length-1]?.posrank : '';
                                let posranks = player.positionrank.filter(function(posrank) {
                                    if (posrank.position.name === position[0]) {
                                        return posrank;
                                    }
                                })
                                if (posranks.length > 0) {
                                    console.log("POS RANKS: ", posranks)
                                    rank = posranks[posranks.length-1].totalrank
                                }
                                return <div style={{position: "relative"}}>
                                    {/* @ts-ignore */}
                                    <RankSpan>{get_suffix(rank)}</RankSpan>
                                    <PlayerCard player={player} onPlusClick={player => dispatch(AddPlayer(player))} onNavClick={player => <Navigate to={`/player/${player.id}`}/>} width={'200px'} />
                                </div>
                            })}
                        </div>
                    </div>
                </Paper>
            </div>
        })}
    </div>
}

export default PositionContainer;