import React, { useEffect } from 'react';
import { Team, Match, Game } from '../types/types';
import { TeamsList, TeamLogos } from '../Globals';
import { Card, Paper, CardContent, Typography, Container, Chip } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import UpcomingGameCard from './UpcomingGameCard';
import { MyContainer } from '../Globals';
import styled from 'styled-components';
import { get_suffix } from '../functions/Ordinal';
import PosRank from './SinglePlayer/PosRank';
export interface Props {
    lastmatch: Game;
    upcoming: Match[];
    image: string;
    header: string;
    thisTeam: Team;
}



const HeaderContainer = styled(MyContainer)`
    @media (max-width: 1024px) {
        flex-wrap: wrap
    }
`;

const PlayerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) { 
        align-items: center;
        margin-bottom: 1rem;
        width: 100%;
        .playerimg {
            height: 10rem !important;
            width: 10rem !important;
        }
        b {
            font-size: 1.6rem;
        }
    }

`;

const MatchesContainer = styled.div`
    display: flex; 
    margin-left: 2rem; 
    overflow: hidden;
    @media (max-width: 1024px) { 
        margin-left: 0;
    }
`;

function GamesHeader({lastmatch, upcoming, image, header, thisTeam}:Props) {
    
    const navigate = useNavigate();
    let teamname = thisTeam?.name ? thisTeam.name : '';
    
    var colors = TeamsList[teamname];
    if (!colors) {
        colors = ['#262626', '#161616']
    }

    let lastopponentname:string = lastmatch?.opposition?.name? lastmatch.opposition.name : "";
    
    let showlastmatch:boolean = false;
    if (lastmatch?.date) {
        if (new Date(lastmatch.date) < new Date()) {
            showlastmatch = true;
        }
    }

    let colors2 = TeamsList[lastopponentname];
    if (!colors2) {
        colors2 = ['#262626', '#161616']
    }
    let positionname:string = '';
    let positionrank:string = '';
    if (lastmatch) {
        positionname = lastmatch.position ? lastmatch.position.name : '';
        positionrank = get_suffix(lastmatch.posrank);
    }
    
    return (<Paper style={{ padding: "1rem", background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`}} className='gamesheader'>
        <HeaderContainer maxWidth="xl" style={{display: "flex", alignItems: "center"}}>
            <PlayerContainer>
                <div className="playerimg" style={{height: "6rem", width: "6rem", backgroundColor: "transparent", borderRadius: "50%", backgroundImage: `url("${image}")`, backgroundSize: "cover", backgroundPosition: "center center"}} />
                <b style={{color: "white"}}>{header}</b>
                <PosRank game={lastmatch} />
            </PlayerContainer>
            <MatchesContainer >
            {showlastmatch ? 
                <Card style={{overflow: 'visible', background: `linear-gradient(120deg, ${colors2[0]} 0%, ${colors2[0]} 50%, ${colors2[1]} 50%)`}} className="lastgamecard">
                    
                    <Link to={`/match/${lastmatch?.linkedmatchid}`}>
                        <CardContent sx={{justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", display: "flex", paddingBottom: 12, height: "100%"}} style={{paddingBottom: 12}}>
                            <Typography sx={{ fontSize: 12 }} color="white" gutterBottom>Last Game</Typography>
                            <img src={TeamLogos[lastopponentname]} style={{width: "60px", height: "60px", margin: "auto"}} />
                            <Typography sx={{ fontSize: 14, marginBottom: 0, whiteSpace: "nowrap" }} color="white" gutterBottom>Round: {lastmatch?.round}</Typography>
                        </CardContent>
                    </Link>
                </Card>
                : null }
                <div style={{overflow: "hidden"}}>
                    <div style={{display: "flex", overflow: "scroll", width: "100%", height: "100%"}}>
                        {upcoming.map(function(upcomingMatch) {
                            return <UpcomingGameCard match={upcomingMatch} thisteamname={teamname} />
                        })}
                    </div>
                </div>
            </MatchesContainer>
        </HeaderContainer>
    </Paper>)
}


export default GamesHeader;