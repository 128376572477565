import { Paper, Typography } from '@mui/material';
import React from 'react';
import { POSITIONLIST } from '../../Globals/Globals';
import styled from 'styled-components';
export interface Props{
    thisPosition: string;
}

export const SMALLPOSITIONLIST = [
    'Hooker',
    'Front-Row-Forward',
    '2RF',
    'Halfback',
    'Five-Eighth',
    'CTW',
    'Fullback',
]

export const RoundedCTAPaper = styled(Paper)`
    cursor: pointer;
    border-radius: 5%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    text-align: center;
    @media(min-width: 800px) {
        padding: 0.75rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    @media(max-width: 800px) {
        padding: 0.5rem;
        h4 {
            font-size: 1rem !important;
        }
    }   
`

const PositionHeader = ({thisPosition}:Props) => {


    return <Paper elevation={5} style={{display: "flex", padding: "1rem", alignItems: "center"}}>
        <Typography style={{marginRight: "auto", textAlign: "center", width: '40%', textDecoration: "underline"}} variant="h4">
            {thisPosition}
        </Typography>
        <div style={{display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gridTemplateRows: "1fr 1fr", columnGap: "1rem", rowGap: "1rem"}}>
            {SMALLPOSITIONLIST.map(function(position) {
                if (position !== thisPosition) {
                    return <a href={`/position/${position}`} className='hoverme'><RoundedCTAPaper>{position}</RoundedCTAPaper></a>
                }  
            })}
        </div>
    </Paper>
}

export default PositionHeader