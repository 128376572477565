import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

interface RankingCellProps {
    current: number;
    change: number;
    isIncrease: boolean;
}

export function RenderRankingCell({current,change, isIncrease}:RankingCellProps) {
    
    return <div style={{display: "flex", alignItems: "center"}}>
        {current}
        {change &&
            <div style={{display: "flex", alignItems: "center",fontSize: "0.9rem", color: isIncrease ? "red" : "green"}}>
                {isIncrease ?   <ArrowDropDown /> : <ArrowDropUp />}
                {change > 0 ? '-' : '+'}
                {Math.abs(change)}
            </div>
        }
    </div>
}