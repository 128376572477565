import { Chip, Tooltip } from "@mui/material";
import GameToolTip from "../components/GameToolTip";
import MatchToolTip from "../components/MatchToolTip";
import { RankColors, RankText, ReverseRankColors } from "../Globals";
import { Match, Game, Rank, PositionRank } from "../types/types";
import PosRankChip from "../components/Chips/PosRankChip";

export function RenderPositionRanks(positionRanks: PositionRank[]) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {positionRanks.map(function (posrank) {
        return <PosRankChip posrank={posrank} />;
      })}
    </div>
  );
}

export function RenderPriceCell(price: number, price_change: number) {
  return (
    <div
      style={{ width: "100%", justifyContent: "flex-start", display: "flex" }}
    >
      <span>
        {price?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </span>
      &nbsp;
      <span style={{ color: price_change > -1 ? "green" : "red" }}>
        ({price_change > -1 ? "+" : ""}
        {price_change?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
        )
      </span>
    </div>
  );
}

export function RenderTeamCell(name: string, logo: string, colors: string[]) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "38px",
        alignItems: "center",
        background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
        color: "white",
      }}
    >
      <img
        style={{ height: "1.5rem", width: "2rem" }}
        src={logo}
        alt="Team logo"
      />
      <p>{name}</p>
    </div>
  );
}

export function RenderTeamCellToolTip(
  name: string,
  logo: string,
  colors: string[],
  match: Match
) {
  return (
    <Tooltip arrow title={<MatchToolTip match={match} />}>
      {RenderTeamCell(name, logo, colors)}
    </Tooltip>
  );
}

export function RenderTeamCellToolTipGame(
  name: string,
  logo: string,
  colors: string[],
  game: Game
) {
  return (
    <Tooltip arrow title={<GameToolTip game={game} />}>
      {RenderTeamCell(name, logo, colors)}
    </Tooltip>
  );
}

export function RenderRankCell(ranks: Rank[]) {
  return (
    <div
      style={{
        display: "flex",
        columnGap: "0.75rem",
        overflowY: "hidden",
        overflowX: "scroll",
      }}
    >
      {ranks.map(function (rank) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Chip
              onDelete={(e) => console.log("")}
              label={`${rank.position.name}:`}
              style={{ backgroundColor: ReverseRankColors[rank.rank] }}
              deleteIcon={
                <span style={{ lineHeight: "1" }}>{RankText[rank.rank]}</span>
              }
            />
          </div>
        );
      })}
    </div>
  );
}
