import React, { useEffect, useState } from 'react';
import Graph from './Graph';
import { Select, InputLabel, MenuItem } from '@mui/material';
import { Game, GraphType } from '../types/types';
import MultiSeriesGraph from './MultiSeriesGraph';
import { SelectChangeEvent } from '@mui/material/Select';

export interface Props {
    defaultGraphItems: string[];
    games: Game[];
    title: string;
}

interface ChartSeries {
    name: string;
    data: number[];
}

const FILTERITEMS = [
    ['points','Points'],
    ['price','Price'],
    ['tackles','Tackles'],
    ['base_points','Base Points'], 
    ['create_points', 'Create Points'],
    ['negative_play_points', "Negative Play Points"],
    ['scoring_points', 'Scoring Points'],
    ['evade_points','Evade Points'],
    ['minutes_played', 'Minutes Played'],
    ['missed_tackles', 'Missed Tackles'],
    ['tries','Tries'],
    ['hitups_over_8m','Hitups Over 8m'],
    ['hitups_under_8m','Hitups Under 8m'],
    ['errors','Errors'],
    ['scbe','SuperCoach BE'],
    ['ownerShipPercentage','Ownership Percentage'],
    ['nextRoundPredictedPrice','Next Round Predicted Price'],
    ['nextRoundPredictedPoints','Next Round Predicted Points'],
    ['effective_offloads', 'Effective Offloads'],
    ['tackle_busts', 'Tackle Busts'],
]

const NEGATIVEITEMS = [
    'missed_tackles',
    'negative_play_points',
]

function ChangeAbleGraph({defaultGraphItems, games, title=""}:Props) {
    const [ selectedGraphItems, setSelectedGraphItems ] = useState<string[]>(defaultGraphItems);
    let [ graphdata, setGraphData ] = useState<GraphType[]>([]);
    let [ graphSeries, setGraphSeries ] = useState<ChartSeries[]>([]);
    let [ categories, setCategories ] = useState<string[]>([]);

    useEffect(() => {
        PopulateGraph();
    }, [defaultGraphItems, games, selectedGraphItems])
    

    function onChange(changeevent:SelectChangeEvent<string[]>) {
        let newvalues = changeevent.target.value;
        if (Array.isArray(newvalues)) {
            setSelectedGraphItems(newvalues);
        }
        
    }

    function PopulateGraph() {
        let seriesArr:ChartSeries[] = [];
        let newCategories:string[] = [];
        //set up series
        selectedGraphItems.map(function(graphitem) {
            seriesArr.push({
                name: graphitem, 
                data: [],
            });
        })
        
        games.map(function(game) {
            selectedGraphItems.map(function(graphitem, graphItemIndex) {
                let item = game[graphitem];
                if (NEGATIVEITEMS.includes(graphitem)) {
                    item = -item;
                }
                seriesArr[graphItemIndex].data.push(item);
            });
            newCategories.push(`Round: ${game.round}`);

        });
        setGraphSeries(seriesArr);
        setCategories(newCategories)
    }
    
    return <div style={{display: "flex", flexWrap: "wrap"}}>
        
        <div style={{width: "100%"}}>
            <MultiSeriesGraph series={graphSeries} title="title" categories={categories} showtitle={selectedGraphItems.join(", ")} type="line" /> 
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start", marginLeft: "auto"}}> 
            <InputLabel id={`multiinputlabel`}>Graph Items</InputLabel>
            <Select 
                value={selectedGraphItems}
                label={'Graph Items'}
                onChange={e => onChange(e)}
                labelId={'multiinputlabel'}
                multiple
                style={{width: 250, textAlign: "left", textTransform: 'capitalize'}}
            >
                {FILTERITEMS.map(function(option) {
                    return <MenuItem value={option[0]}>{option[1]}</MenuItem>
                })}
            </Select>
        </div>
    </div>

}

export default ChangeAbleGraph;