import React, { useState } from 'react';
import { Box, TextField, InputAdornment, Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SelectResults, Search, SelectLoadingStatus } from '../../reducers/SearchReducer';
import SearchResultsTable from './SearchResultsTable';
import Loading from '../Loading';


function SearchContainer() {
    const dispatch = useAppDispatch();
    const searchresults = useAppSelector(SelectResults);
    const loadingstatus  = useAppSelector(SelectLoadingStatus);
    const [ searchTerm, setSearchTerm ] = useState("");

    return <div>
        <Box>
            <Paper
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                elevation={5}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Players"
                    inputProps={{ 'aria-label': 'search players' }}
                    value={searchTerm}
                    onChange={ e => setSearchTerm(e.target.value)}
                    onKeyPress= { ev => {
                        if (ev.key ==="Enter") { 
                            ev.preventDefault(); 
                            dispatch(Search(searchTerm));
                        }
                    }}  
                />
                <IconButton sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>
            {loadingstatus === 'loading' ? <Loading /> : <SearchResultsTable results={searchresults} />}
            
        </Box>
    </div>
}

export default SearchContainer;