import React, { useEffect, useState } from "react";
import { ReactComponent as Smalllogo } from "../logo.svg";
import { Container } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
const Footer = () => {
  return (
    <div style={{ background: "#0d1525", padding: "1rem", marginTop: "1rem" }}>
      <Container style={{ display: "flex" }} maxWidth="xl">
        <a
          href="https://scplaybook.com.au/"
          target="_blank"
          style={{
            backgroundImage: `url('/images/icons/Logo-Blue-White.svg')`,
            backgroundSize: "contain",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            height: "2rem",
            width: "12rem",
          }}
          rel="noreferrer"
        ></a>
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            columnGap: "1rem",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.instagram.com/scplaybook1/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon sx={{ color: "white" }} />
          </a>
          <a
            href="https://www.facebook.com/scplaybook1/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon sx={{ color: "white" }} />
          </a>
          <a
            href="https://twitter.com/scplaybook1?lang=en"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon sx={{ color: "white" }} />
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
