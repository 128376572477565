import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface UserSettingsState {
  magicNumber: number;
  playerdisplay: "card" | "table";
  snackbaropen: boolean;
  snackbarmessage: string;
}

export interface payload {
  magicnumber: number;
}

const initialState: UserSettingsState = {
  magicNumber: 10130,
  playerdisplay: "table",
  snackbaropen: false,
  snackbarmessage: "",
};

export const selectMagicNumber = (state: RootState) => state.user.magicNumber;
export const selectPlayerDisplay = (state: RootState) =>
  state.user.playerdisplay;

export const selectSnackbarOpen = (state: RootState) => state.user.snackbaropen;
export const selectSnackbarMessage = (state: RootState) =>
  state.user.snackbarmessage;

export const UserSettingsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    ChangeMagicNumber: (state, action: PayloadAction<number>) => {
      state.magicNumber = action.payload;
    },
    TogglePlayerDisplay: (state, action: PayloadAction<string>) => {
      if (action.payload === "card") {
        state.playerdisplay = "card";
      } else {
        state.playerdisplay = "table";
      }
    },
    SetSnackBarOpen: (state, action: PayloadAction<boolean>) => {
      state.snackbaropen = action.payload;
    },
    SetSnackBarText: (state, action: PayloadAction<string>) => {
      state.snackbarmessage = action.payload;
    },
  },
});

export const {
  ChangeMagicNumber,
  TogglePlayerDisplay,
  SetSnackBarOpen,
  SetSnackBarText,
} = UserSettingsSlice.actions;

export default UserSettingsSlice.reducer;
