import { Player } from "../types/types";
export const CURRENT_SEASON_STRING = "2024";
export const CURRENT_SEASON = 2024;
export const LAST_SEASON = CURRENT_SEASON - 1;
export const LAST_SEASON_STRING = `${LAST_SEASON}`;

export const POSITIONLIST = [
  "CTW",
  "Interchange",
  "Prop",
  "2RF",
  "Five-Eighth",
  "Halfback",
  "Fullback",
  "Hooker",
  "Reserve",
  "Replacement",
  "FLB",
  "FRF",
  "5/8",
  "HFB",
  "HOK",
  "Front-Row Forward",
];

export const TRIMMEDPOSITIONLIST = [
  "2RF",
  "Prop",
  "CTW",
  "Fullback",
  "Front-Row Forward",
  "Five-Eighth",
  "Halfback",
  "Hooker",
];

export const TOOLTIPS = {
  PTS: "Supercoach points scored",
  PPM: "Points per minute",
  BE: "Breakeven",
  "3RA": "Average points scored for last 3 rounds",
  Mins: "Average minutes played",
  Base: "Base",
};

export const IMAGES_BUCKET =
  "https://scpbimages.s3.ap-southeast-2.amazonaws.com/";

export const GetPlayerImage = (player: Player) => {
  return `${IMAGES_BUCKET}${player.firstname.replace(
    " ",
    ""
  )}_${player.lastname.replace(" ", "")}.png`;
};

export const GetPlayerImageName = (firstname, lastname) => {
  return `${IMAGES_BUCKET}${firstname.replace(" ", "")}_${lastname.replace(
    " ",
    ""
  )}.png`;
};

export const LASTROUND = 3;

export interface CalculateNextBEProps {
  currentPrice: number;
  priceChange: number;
  magicNumber: number;
  s1: number;
  s2: number;
}
export const CalculateNextBE = ({
  currentPrice,
  priceChange,
  magicNumber,
  s1,
  s2,
}: CalculateNextBEProps) => {
  //NBE = (((Price change + Current Price)/MN)*3.15)-(S1+S2)
  //nbe2 = (((cv+pc)/mn)*3.15)- (s1+s2)
  return ((priceChange + currentPrice) / magicNumber) * 3.15 - (s1 + s2);
};

export interface CalculatePriceChangeProps {
  s1: number;
  s2: number;
  s3: number;
  currentValue: number;
  magicNumber: number;
}

export const CalculatePriceChange = ({
  s1,
  s2,
  s3,
  currentValue,
  magicNumber,
}: CalculatePriceChangeProps) => {
  //Price Change = (S1-((CV/MN)*3.15)-(S2+S3))*MN)/12

  return (
    (s1 - (currentValue / magicNumber) * 3.15 - (s2 + s3) * magicNumber) / 12
  );
};

export interface CallculatePriceChange2Props {
  s1: number;
  be: number;
  magicNumber: number;
}

export const CallculatePriceChange2 = ({
  s1,
  be,
  magicNumber,
}: CallculatePriceChange2Props) => {
  //pc = ((s1 - BE)xMN)/12
  return ((s1 - be) * magicNumber) / 12;
};

export interface CalculateBreakEvenProps {
  s1: number;
  s2: number;
  currentValue: number;
  magicNumber: number;
}
export const CalculateBreakEven = ({
  s1,
  s2,
  currentValue,
  magicNumber,
}: CalculateBreakEvenProps) => {
  //BE = ((CV/MN)*3.15) - (S1+S2)
  return (currentValue / magicNumber) * 3.15 - (s1 + s2);
};

export interface CalculatePreviousBEProps {
  s2: number;
  s3: number;
  magicNumber: number;
  currentValue: number;
}

export const CalculatePreviousBE = ({
  s2,
  s3,
  magicNumber,
  currentValue,
}: CalculatePreviousBEProps) => {
  return (currentValue / magicNumber) * 3.15 - (s2 + s3);
};

export const CalculateBENew = ({ currentPrice, magicNumber, s1, s2 }) => {
  return (currentPrice / magicNumber) * 3.15 - (s1 + s2);
};
export interface CalculateBEJaymonProps {
  cv: number;
  mn: number;
  s1: number;
  s2: number;
}

export const CalculateBEJaymon = ({
  cv,
  mn,
  s1,
  s2,
}: CalculateBEJaymonProps) => {
  return (cv / mn) * 3.15 - (s1 + s2);
};

export interface CalculatePriceChangeJaymonProps {
  cv: number;
  mn: number;
  s1: number;
  s2: number;
  s3: number;
}

export const CalculatePriceChangeJaymon = ({
  s1,
  s2,
  s3,
  cv,
  mn,
}: CalculatePriceChangeJaymonProps) => {
  return ((s1 - (cv / mn) * 3.15 - (s2 + s3)) * mn) / 12;
};

export const CalculatePriceChange3 = ({
  s1,
  s2,
  s3,
  mn,
  cv,
}: CalculatePriceChangeJaymonProps) => {
  const tga = (s1 + s2 + s3) / 3;
  const lhs = 0.75 * cv;
  const rhs = 0.25 * tga * mn;
  return lhs + rhs;
};
