import React, { useEffect, useState } from 'react';
import { Match } from '../../types/types';
import { Paper, Typography, Table, TableBody, TableCell, TableRow, TableContainer } from '@mui/material';
import { TeamsList, TeamLogos, TeamsBGColors, MyContainer } from '../../Globals';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

export interface Props {
    match: Match;
}

const TablePaper = styled(Paper)`
    width: 100%;
    margin-top: 1rem;
    @media (min-width: 800px) {
        width: 40%
    }
`

const SingleMatchLogos = ({match}:Props) => {
    const navigate = useNavigate();
    let hometeamname = match.hometeam? match.hometeam.name : '';
    let hometeamlogo:string = TeamLogos[hometeamname];
    let homecolors = TeamsList[hometeamname];
    let homebg:string = TeamsBGColors[hometeamname];

    if (!homecolors) {
        homecolors = ['0','0'];
    }
    let homeColorString:string = `linear-gradient(120deg, ${homecolors[0]} 0%, ${homecolors[0]} 50%, ${homecolors[1]} 50%)`;

    let awayteamname = match.awayteam? match.awayteam.name : '';
    let awayteamlogo:string = TeamLogos[awayteamname];
    let awaycolors = TeamsList[awayteamname];
    let awaybg:string = TeamsBGColors[awayteamname];
    if (!awaycolors) {
        awaycolors = ['0','0'];
    }
    let awayColorString:string = `linear-gradient(120deg, ${awaycolors[0]} 0%, ${awaycolors[0]} 50%, ${awaycolors[1]} 50%)`;
    
    let homeboxcolor:string = '';
    let awayboxcolor:string = '';
    if (match.homescore > match.awayscore) {
        homeboxcolor = 'green';
        awayboxcolor = 'red';
    } else if (match.homescore === match.awayscore) {
        awayboxcolor = 'green';
        homeboxcolor = 'green';
    } else {
        awayboxcolor = 'green';
        homeboxcolor = 'red';
    }

    return <div style={{marginBottom: '1rem'}}>
        <Paper style={{display: "flex",  flexWrap: "wrap"}}>
            <div style={{width: "50%", background: homeColorString, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "1rem"}}>
                <Paper style={{width: "125px", height: "120px", backgroundColor: homebg, borderRadius: "50%" , padding: "0.5rem", marginBottom: "0.5rem", cursor: "pointer"}} elevation={6} onClick={e => navigate('/team/'+hometeamname)}>
                    <img src={hometeamlogo} style={{height: "90%"}}/>
                </Paper>
                <Paper style={{ marginBottom: "0.5rem", padding: "0.5rem", backgroundColor: homeboxcolor, color: "white"}}>
                    <span>{match.homescore}</span>
                </Paper>
            </div>
            <div style={{width: "50%", background: awayColorString, display: "flex", justifyContent: "center", alignItems: "center", padding: "1rem", flexDirection: "column"}}>
                <Paper style={{width: "125px", height: "120px", backgroundColor: awaybg, borderRadius: "50%", padding: "0.5rem", marginBottom: "0.5rem", cursor: "pointer"}} elevation={6} onClick={e => navigate('/team/'+awayteamname)}>
                    <img src={awayteamlogo} style={{height: "90%"}} />
                </Paper>
                <Paper style={{ marginBottom: "0.5rem", padding: "0.5rem", backgroundColor: awayboxcolor,color: "white"}}>
                    <span>{match.awayscore}</span>
                </Paper>
            </div>
    </Paper>
    <MyContainer maxWidth="xl">
        <TablePaper elevation={6} >
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Round:
                                </TableCell>
                                <TableCell>
                                    {match.round}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Season:
                                </TableCell>
                                <TableCell>
                                    {match.season}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Date:
                                </TableCell>
                                <TableCell>
                                {new Date(match.date).toLocaleDateString()}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
        </TablePaper>
    </MyContainer>
    </div>
}


export default SingleMatchLogos; 