import React from "react";
import { TeamLogos } from "../Globals";
export interface TeamIconProps {
  team: { name: string };
}

const TeamIcon = ({ team }: TeamIconProps) => {
  return (
    <>
      {team?.name && (
        <div
          style={{
            width: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={TeamLogos[team?.name]}
            style={{ width: "100%", height: "100%" }}
            alt={team?.name}
          />
        </div>
      )}
    </>
  );
};

export default TeamIcon;
