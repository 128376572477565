import React, { useEffect, useState, useRef } from "react";
import { Player, PlayerStats, PositionRank } from "../types/types";
import _, { sortBy } from "underscore";
import {
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import PlayerCard from "./PlayerCard/PlayerCard";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { AddPlayer } from "../reducers/ChosenPlayers";
import { Link, Navigate, useParams } from "react-router-dom";
import CalculatePlayerStats from "../functions/CalculatePlayerStats";
import { createNextState } from "@reduxjs/toolkit";
import styled from "styled-components";
import {
  CURRENT_SEASON,
  CURRENT_SEASON_STRING,
  TOOLTIPS,
} from "../Globals/Globals";
import PlayerCardExtraStat from "./PlayerCard/PlayerCardExtraStat";

export interface Props {
  players: Player[];
  hideposition?: boolean;
  positionname: string;
}

export interface SortObject {
  term: string;
  label: string;
}

export interface PlayerStat {
  player: Player;
  stats: PlayerStats;
  show: boolean;
  value: string | number;
}

export interface PlayerStatsList {
  players: PlayerStat[];
}

const NEGATIVESORT = ["scbe", "posrank", "avgpts"];

const CARDLABELS = {
  posrank: { label: "Rank", value: "", tooltip: TOOLTIPS["Rank"] },
  rawPrice: { label: "Price", value: "", tooltip: "Price" },
  avgpts: { label: "PTS", value: "", tooltip: TOOLTIPS["PTS"] },
  avgbase: { label: "BASE", value: "", tooltip: TOOLTIPS["Base"] },
  ppm: { label: "PPM", value: "", tooltip: TOOLTIPS["PPM"] },
  scbe: { label: "BE", value: "", tooltip: TOOLTIPS["BE"] },
  three: { label: "3RA", value: "", tooltip: TOOLTIPS["3RA"] },
  five: { label: "5RA", value: "", tooltip: TOOLTIPS["5RA"] },
  ownership: { label: "OWN%", value: "", tooltip: "SC Ownership" },
};

function PlayerCardsContainer({ players, hideposition, positionname }: Props) {
  const dispatch = useAppDispatch();
  const [theplayers, setPlayers] = useState<Player[]>(players);
  const [sortedBy, setSortedBy] = useState<string>("avgpts");
  const [sortDirection, setSortDirection] = useState<string>("descending");
  const [playerStatList, setPlayerStatList] = useState<PlayerStat[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<string>("*");

  useEffect(() => {
    let npl = CalculateStats();
    TriggerSort(npl, sortedBy, sortDirection);
  }, [players]);

  useEffect(() => {
    //TriggerSort();
  }, [sortedBy]);

  let repeatlength: number = 0;

  playerStatList.map(function (psl) {
    if (psl.show) {
      repeatlength = repeatlength + 1;
    }
  });
  if (repeatlength % 2 === 0) {
    repeatlength = repeatlength / 2;
  } else {
    repeatlength = (repeatlength + 1) / 2;
  }
  const GridContainer = styled.div`
    display: grid;
    overflow-x: scroll;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    grid-template-columns: repeat(${repeatlength}, 150px);
    grid-row-gap: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    @media (min-width: 1024px) {
      padding-left: 0.5rem;
      grid-template-columns: repeat(${repeatlength}, 170px);
    }
  `;
  function handlePositionChange(
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) {
    setSelectedPosition(event.target.value);
    TriggerFilter(playerStatList, event.target.value);
  }

  function TriggerFilter(playerstatlist: PlayerStat[], filterterm: string) {
    let newplayers = playerstatlist.slice();
    newplayers = newplayers.map(function (playerstat) {
      if (filterterm === "*") {
        playerstat.show = true;
      } else {
        playerstat.show =
          playerstat.player.stats[0].last5Games[0]?.position?.name ===
          filterterm;
      }
      return playerstat;
    });
    setPlayerStatList(newplayers);
  }

  function CalculateStats() {
    let newPlayerStatList: PlayerStat[] = [];
    players.map(function (player) {
      let thestat;
      player.stats.map(function (stat) {
        if (stat.season === CURRENT_SEASON_STRING) {
          thestat = stat;
        }
      });
      var playerstats = CalculatePlayerStats({
        games: thestat?.last5Games,
        magicnumber: 8767,
      });
      newPlayerStatList.push({
        player: player,
        stats: playerstats,
        show: true,
        value: "",
      });
    });

    //setPlayerStatList(newPlayerStatList);

    return newPlayerStatList;
  }

  function TriggerSort(
    playerstatlist: PlayerStat[],
    sortterm: string,
    sortDirection: string
  ) {
    let newplayers = playerstatlist.slice();
    newplayers = _.sortBy(newplayers, function (player) {
      let thisStat;
      let newLastGame;
      player.player.stats.map(function (stat) {
        if (stat.season === CURRENT_SEASON_STRING) {
          thisStat = stat;
          if (stat.lastgame) {
            newLastGame = stat.lastgame;
          }
        }
      });
      if (sortterm === "avgpts" || sortterm === "avgbase") {
        if (sortDirection !== "ascending") {
          player.value = player.player.stats[0][sortterm];
          return -player.player.stats[0][sortterm];
        } else {
          player.value = player.player.stats[0][sortterm];
          return player.player.stats[0][sortterm];
        }
      } else if (sortterm === "posrank") {
        let found = false;
        let thisrank = 1000;

        let lastgame = thisStat
          ? thisStat.last5Games[thisStat.last5Games.length - 1]
          : null;
        if (newLastGame) {
          lastgame = newLastGame;
        }

        if (lastgame) {
          lastgame.positionrank.map(function (rank: PositionRank) {
            if (
              rank.position.name === positionname &&
              rank.season === CURRENT_SEASON
            ) {
              found = true;
              thisrank = rank.totalrank;
            }
          });
        }

        if (sortDirection !== "ascending") {
          if (found) {
            player.value = thisrank;
            return -thisrank;
          } else {
            return -10000;
          }
        } else {
          if (found) {
            player.value = thisrank;
            return thisrank;
          } else {
            return 10000;
          }
        }
      } else if (sortterm === "ownership") {
        let lastgame = player.player.stats[0]
          ? player.player.stats[0].last5Games[
              player.player.stats[0].last5Games.length - 1
            ]
          : null;

        if (!lastgame) {
          return 10000;
        }

        player.value = lastgame.ownerShipPercentage;
        if (sortDirection !== "ascending") {
          return -lastgame.ownerShipPercentage;
        } else {
          return lastgame.ownerShipPercentage;
        }
      } else {
        player.value = player.stats[sortterm];
        if (sortDirection !== "ascending") {
          return -player.stats[sortterm];
        } else {
          return player.stats[sortterm];
        }
      }
    });
    console.log("TRIGGER SORT DIRECTION: ", newplayers);
    setPlayerStatList(newplayers);
  }

  function handleSortChange(
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) {
    setSortedBy(event.target.value);
    TriggerSort(playerStatList, event.target.value, sortDirection);
  }

  function handleSortDirectionChange(
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) {
    setSortDirection(event.target.value);
    TriggerSort(playerStatList, sortedBy, event.target.value);
  }

  return (
    <Paper style={{ padding: "0.5rem" }} elevation={5}>
      <div style={{ display: "flex" }}>
        {!hideposition ? (
          <FormControl
            component="fieldset"
            sx={{ marginBottom: "1rem", padding: "0.5rem", width: "110px" }}
          >
            <FormLabel component="legend" sx={{ textAlign: "left" }}>
              Position
            </FormLabel>
            <Select
              value={selectedPosition}
              label="Position"
              onChange={handlePositionChange}
            >
              <MenuItem value="*">All</MenuItem>
              <MenuItem value="Winger">Winger</MenuItem>
              <MenuItem value="Centre">Centre</MenuItem>
              <MenuItem value="Interchange">Interchange</MenuItem>
              <MenuItem value="Prop">Prop</MenuItem>
              <MenuItem value="2nd Row">2nd Row</MenuItem>
              <MenuItem value="Lock">Lock</MenuItem>
              <MenuItem value="Five-Eighth">Five-Eighth</MenuItem>
              <MenuItem value="Halfback">Halfback</MenuItem>
              <MenuItem value="Fullback">Fullback</MenuItem>
              <MenuItem value="Hooker">Hooker</MenuItem>
              <MenuItem value="Reserve">Reserve</MenuItem>
            </Select>
          </FormControl>
        ) : null}
        <FormControl
          component="fieldset"
          sx={{ marginBottom: "1rem", padding: "0.5rem" }}
        >
          <FormLabel component="legend" sx={{ textAlign: "left" }}>
            Sort
          </FormLabel>
          <Select value={sortedBy} onChange={handleSortChange}>
            <MenuItem value="rawPrice">Price</MenuItem>
            <MenuItem value="posrank">Rank</MenuItem>
            <MenuItem value="avgpts">Pts</MenuItem>
            <MenuItem value="avgbase">Base</MenuItem>
            <MenuItem value="ppm">PPM</MenuItem>
            <MenuItem value="scbe">BE</MenuItem>
            <MenuItem value="three">Avg 3rd</MenuItem>
            <MenuItem value="five">Avg 5rd</MenuItem>
            <MenuItem value="ownership">OWN %</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          component="fieldset"
          sx={{ marginBottom: "1rem", padding: "0.5rem" }}
        >
          <FormLabel component="legend" sx={{ textAlign: "left" }}>
            Direction
          </FormLabel>
          <Select value={sortDirection} onChange={handleSortDirectionChange}>
            <MenuItem value="ascending">Ascending</MenuItem>
            <MenuItem value="descending">Descending</MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* <p style={{textAlign: "left"}}>Players</p> */}
      {/*<div style={{display: "flex", flexDirection: "row", overflow: "scroll"}}> */}
      <GridContainer>
        {playerStatList.map(function (playerstat, index) {
          if (playerstat.show) {
            return (
              <div style={{ marginRight: "1rem" }}>
                <PlayerCardExtraStat
                  player={playerstat.player}
                  onNavClick={(player) => (
                    <Navigate to={`/player/${player.id}`} />
                  )}
                  width={"100%"}
                  onPlusClick={(player) => dispatch(AddPlayer(player))}
                  extraStat={{
                    ...CARDLABELS[sortedBy],
                    value: playerstat.value,
                  }}
                />
                {/* <PlayerCard player={playerstat.player} onPlusClick={player => dispatch(AddPlayer(player))} onNavClick={player => <Navigate to={`/player/${player.id}`}/>} width={'100%'} /> */}
              </div>
            );
          }
        })}
      </GridContainer>
    </Paper>
  );
}

export default PlayerCardsContainer;
