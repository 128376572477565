import React from "react";
import { Game } from "../types/types";
import _ from "underscore";
import { CURRENT_SEASON } from "../Globals/Globals";

interface Props {
  games: Game[];
  magicnumber: number;
}

export function CalculateBE({ games, magicnumber }: Props) {
  let currentvalue: number = 0;
  let tga: number = 0;
  let s2: number = 0;
  let s1: number = 0;
  let be: number = 0;

  if (!games) {
    return 0;
  }

  var newgames = _.sortBy(games, function (game) {
    return -game?.round;
  });
  newgames = newgames.filter(function (game) {
    if (game?.season === CURRENT_SEASON) {
      return game;
    }
  });

  let bemultiplier: number = 3;
  if (newgames.length > 2) {
    newgames.map(function (game, index) {
      if (index < 3) {
        if (index === 0) {
          currentvalue = game.price;
          s1 = game.points;
        }
        if (index === 1) {
          s1 = game.points;
        } else if (index === 2) {
          s2 = game.points;
        }
        tga = tga + game.points;
      }
    });
  } else if (newgames.length === 2) {
    s1 = newgames[0].points;
    s2 = newgames[1].points;
    currentvalue = newgames[0].price;
  } else if (newgames.length === 1) {
    bemultiplier = 2;
    s1 = newgames[0].points;
    s2 = 0;
    currentvalue = newgames[0].price;
  }
  tga = tga / 3;

  //(0.75 x CV) + (0.25 x TGA x MN) = CV
  //let nv:number = (0.75 * currentvalue) + (0.25 * tga * magicnumber);

  let nv: number = 0.75 * currentvalue + 0.25 * tga * magicnumber;
  be = (bemultiplier * currentvalue) / 9088 - (s2 + s1);
  return be;
}

export function CalculateBEPoints(
  scores: number[],
  magicnumber: number,
  price: number
) {
  let currentvalue: number;
  let tga: number = 0;
  let s2: number;
  let s1: number;
  let be: number;

  if (!scores) {
    return 0;
  }

  currentvalue = 0;
  s1 = 0;
  s2 = 0;

  scores.map(function (score, index) {
    if (index < 3) {
      if (index === 0) {
        currentvalue = score;
        s1 = score;
      }
      if (index === 1) {
        s2 = score;
      }
      tga = tga + score;
    }
  });
  tga = tga / 3;
  //(0.75 x CV) + (0.25 x TGA x MN) = CV

  let nv: number = 0.75 * price + 0.25 * tga * magicnumber;
  be = (3 * price) / magicnumber - (s2 + s1);

  return be;
}
