import { Paper } from '@mui/material';
import React, { ReactElement } from 'react';
import { LightPlayer, Game, Player } from '../../types/types';
//import { TeamLogos, TeamsList, TOOLTIPS } from '../../globals/Globals';
import { TeamLogos, TeamsList, TOOLTIPS } from '../../Globals';

import TeamLogo from '../Images/TeamLogo';
import { IMAGES_BUCKET } from '../../Globals/Globals';

interface StatObject {
    label: string;
    valueField: ReactElement;
}

export interface Props {
    game: Game;
    rightStats: StatObject[];
    player: Player;
}


function FeaturePlayerCard({game, rightStats, player}:Props) {
    if (!game) {
        return <></>
    }
    const lastgame = game
    //const lastgame:Game = player.stats[0].lastgame;
    const team = lastgame?.team?.name? lastgame.team.name : '';
    const colors = TeamsList[team]? TeamsList[team] : ['',''];
    //const playerimg = `${IMAGES_BUCKET}${player.firstname}-${player.lastname}.png`;
    
    const classname = team.replace(" ", '-');
    
    let thePlayer:Player = player;
    console.log("hero Card: ", game, rightStats, thePlayer.pic)
    const playerimg = thePlayer?.pic !== "" ? thePlayer?.pic : `${IMAGES_BUCKET}${thePlayer.firstname}_${thePlayer.lastname}.png`;

    //@ts-ignore   
    return <a href={`/player/${thePlayer?.id}`} key={`${thePlayer?.firstname}-player`}>
    <Paper style={{width: 320, minWidth: 320, maxWidth: 320, borderRadius: "5%", cursor: "pointer"}} elevation={5} className={`featured-card team-${classname}`}>
        <div style={{position: "relative", height: "100%", width: "100%"}}>
            <div
                className="card-background"
                style={{
                    background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
                    //paddingBottom: "125%",
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    //rowGap: "4rem",
                    borderRadius: "5%"
                }}
            >
                <div style={{position: "absolute", right: "0.5rem", marginBottom: '1rem',top: "5%", display: "flex", flexDirection: "column", rowGap: "1rem"}} className={"featured-card stats-container"}>
                    {rightStats.map(function(stat) {
                        return <div style={{display: "flex", alignItems: 'center', flexDirection: "column", color: "white"}}>
                            <span>{stat.valueField}</span>
                            <span style={{whiteSpace: 'pre-wrap',maxWidth: '110px',textAlign: 'center',justifyContent: 'center',lineHeight: 1.1}}>{stat.label}</span>
                        </div>
                    })}
                    
                </div>
                <TeamLogo teamname={team} />
                <div style={{display: "flex", flexDirection: "column",marginRight: "auto", paddingLeft: "1rem", alignItems: "center", color: "white", position: "relative"}}>
                    
                    <div style={{width: 200, backgroundImage: `url("${playerimg}")`,backgroundPosition: 'bottom center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat',paddingBottom: '105%'}}></div>
                    <h3 style={{width: "100%", position: 'absolute', bottom: 6, textAlign: "center",marginBottom: 0}}>
                        
                            {thePlayer?.firstname[0]} {thePlayer?.lastname}
                        
                    </h3>
                </div>
            </div>
        </div>
    </Paper>
    </a>
}

export default FeaturePlayerCard;