import React, { ReactElement, useEffect, useState, ReactNode } from 'react';
import { Paper } from '@mui/material';
import styled from "@emotion/styled"

export const RoundedCTAPaper = styled(Paper)`
    cursor: pointer;
    border-radius: 5%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    @media(min-width: 800px) {
        padding: 0.75rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    @media(max-width: 800px) {
        padding: 0.5rem;
        h4 {
            font-size: 1rem !important;
        }
    }   
`
export interface Props {
    Icon: ReactElement | ReactNode;
    text: string;
    link: string;
    newTab?: boolean;
}

export default function RoundedCTA({Icon,text,link,newTab=false}:Props) {

    return <a href={link} target={newTab ? "_blank" : ""} className="hoverme">
        <RoundedCTAPaper elevation={5}>
            {Icon}
            <h4 style={{fontSize: "1.25rem", padding: 0, margin: 0}}>{text}</h4>
        </RoundedCTAPaper>
    </a>
}