import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Stats } from 'fs';
//import { RootState, AppThunk } from '../../app/store';
import api from '../app/api';
import { RootState } from '../app/store';
//import { fetchCount } from './counterAPI';
import { LightPlayer } from '../types/types';


export interface FindPlayerState {
    data: {
        players: LightPlayer[];
    },
    status: 'idle' | 'loading' | 'failed'
}


export interface action { 
    type: String;
    payload: {players: []}
}

export interface PayloadAction { 
    players: LightPlayer[];
}


const initialState: FindPlayerState = {
    data: {
        players: []
    },
    status: "idle"
}

const fetchAll = () => {
    return new Promise<{ data: PayloadAction}>((resolve) => {
      api.get('/allplayers').then(function(payload) {
        console.log("Fetch all payload: ", payload);
        resolve(payload);
      });
    })
  }

  export const getAll = createAsyncThunk(
    'findplayers/getAll',
    async () => {
      const response = await fetchAll();
      return response;
    }
  )


  export const findPlayersSlice = createSlice({
    name: "findplayers",
    initialState,
    reducers: {
        getAll: (state, action:action) => {
            console.log("STATE");
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAll.pending, (state) => {
            state.status="loading"
        })
        .addCase(getAll.fulfilled, (state,action) => {
            console.log("Get All Fulfilled; ", state, action);
            state.status = 'idle';
            state.data.players = action.payload.data.players
        });
    }
});

export const selectAllPlayers = (state: RootState) => state.findplayers.data.players;
export const selectStatus = (state: RootState) => state.findplayers.status;

export default findPlayersSlice.reducer;