import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../app/api';
import { RootState } from '../app/store';
import StatsHeadline from '../components/SinglePlayer/StatsHeadline';
import { Player, Team, Position, Match, Game, SimplePlayer, MatchWithRanks, Placing, SCTeam, PlayerOwnership, Diff, SCTeamWithPlacings, Ownership, OwnershipWithPlayer } from '../types/types';


export interface SCTeamsState {
    scdata: {
        placings: Placing[];
        singleSCTeam?: {
          placings: Placing[];
          team: SCTeam;
          playerOwnerships: PlayerOwnership[];
        }
        diffs: Diff[];
        diffstop1000: Diff[];
        featuredTeam: {
          team: SCTeamWithPlacings;
          placing: Placing;
          ownerships: PlayerOwnership
        }
        ownerships: OwnershipWithPlayer[];
    }
    status: 'idle' | 'loading' | 'failed';
}

export interface action {
    type: String,
    payload: {
        placings: [],
        diffs: [],
        diffstop1000: [],
        featuredTeam: {
          team: SCTeamWithPlacings;
          placing: Placing;
          ownerships: PlayerOwnership
        }
        ownerships: OwnershipWithPlayer[];
        
    }
}

export interface FetchPlacingsActions {
    placings: Placing[];
    diffs: {
      team: SCTeam;
      lastround_position: number;
      thisround_position: number;
      diff: number;
    }[];
    diffstop1000: {
      team: SCTeam;
      lastround_position: number;
      thisround_position: number;
      diff: number;
    }[];
    featuredTeam: {
      team: SCTeamWithPlacings;
      placing: Placing;
      ownerships: PlayerOwnership
    }
}

export interface FetchOwnershipAction {
  ownerships: OwnershipWithPlayer[];
}

export interface FetchSingleSCTeamAction {
  placings: Placing[];
  team: SCTeam;
  playerOwnerships: PlayerOwnership[];
}

export interface FetchSingleRoundOwnershipAction {
  playerOwnership: PlayerOwnership;
}

interface GetSingleRoundOwnershipProps {
  teamid: string;
  round: number;
}


const initialState: SCTeamsState = {
    scdata: {
        placings: [],
        diffs: [],
        diffstop1000: [],
        //@ts-ignore
        featuredTeam: {},
        ownerships: [],
    },
    status: 'idle'
}

const fetchSingleRoundOwnership = (teamid:string,round:number) => {
  return new Promise<{data: FetchSingleRoundOwnershipAction}>((resolve) => {
    api.get(`/SCTeams/${teamid}/${round}`).then(function(payload) {
      resolve(payload);
    });
  });
}

export const getSingleRoundOwnership = createAsyncThunk(
  'sc/getSingleRoundOwnership',
  async ({teamid,round}:GetSingleRoundOwnershipProps) => {
    const response = await fetchSingleRoundOwnership(teamid,round);
    return response;
  }
)

const fetchSingleTeam = (teamid:string) => {
  return new Promise<{data: FetchSingleSCTeamAction}>((resolve) => {
    api.get('/SCTeams/'+teamid).then(function(payload) {
      resolve(payload);
    });
  });
}

export const getSingleSCTeam = createAsyncThunk(
  'sc/singleteam',
  async (teamid:string) => {
    const response = await fetchSingleTeam(teamid);
    return response;
  }
)

const fetchPlacings = () => {
    return new Promise<{data: FetchPlacingsActions}>((resolve) => {
      api.get('/SCTeams').then(function(payload) {
        console.log("SCTEAMS PAYLOAD: ", payload);
        resolve(payload);
      });
    });
  }

export const getPlacings = createAsyncThunk(
    'sc/getplacings',
    async () => {
      const response = await fetchPlacings();
      return response;
    }
)

export const fetchOwnerships = (position:string,round:number) => {
  return new Promise<{data:FetchOwnershipAction}>((resolve) => {
    api.get(`SCTeams/ownership/${position}/${round}`).then(function(payload) {
      resolve(payload);
    })
  })
}
export interface GetOwnershipsProps {
  position: string;
  round: number;
}

export const getOwnerships = createAsyncThunk(
  'sc/getownerships',
  async ({position,round}:GetOwnershipsProps) => {
    const response = await fetchOwnerships(position,round)
    return response
  }
)

export const scSlice = createSlice({
    name: "sc",
    initialState,
    reducers: {
      getplacings: (state, action: action) => {
        state.scdata = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getPlacings.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getPlacings.fulfilled, (state, action) => {
          state.status = 'idle';
          state.scdata.placings = action.payload.data.placings;
          state.scdata.diffs = action.payload.data.diffs;
          state.scdata.diffstop1000 = action.payload.data.diffstop1000;
          state.scdata.featuredTeam = action.payload.data.featuredTeam;
        })
        .addCase(getSingleSCTeam.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getSingleSCTeam.fulfilled, (state,action) => {
          state.status = 'idle';
          state.scdata.singleSCTeam = action.payload.data;
        })
        .addCase(getSingleRoundOwnership.pending, (state) => {
          state.status = 'loading';
          console.log("GET SINGLE LOADING");
        })
        .addCase(getSingleRoundOwnership.fulfilled, (state,action) => {
          state.status = 'idle'
          if (state.scdata.singleSCTeam) {
            
            state.scdata.singleSCTeam.playerOwnerships.push(action.payload.data.playerOwnership);
          } 
        })
        .addCase(getOwnerships.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(getOwnerships.fulfilled, (state, action) => {
          state.status = 'idle';
          console.log("Get Ownerships Fulfilled: ", action.payload)
          state.scdata.ownerships = action.payload.data.ownerships;
        })
  
    }
});


export const selectPlacings = (state: RootState) => state.sc.scdata.placings;


export const selectLoading = (state: RootState) => state.sc.status;

export const selectSingleSCTeam = (state: RootState) => state.sc.scdata.singleSCTeam? state.sc.scdata.singleSCTeam : null;

export const selectDiffs = (state: RootState) => state.sc.scdata.diffs;

export const selectDiffsTop1000 = (state: RootState) => state.sc.scdata.diffstop1000;

export const selectFeaturedTeam = (state:RootState) => state.sc.scdata.featuredTeam;

export const selectOwnerships = (state: RootState) => state.sc.scdata.ownerships;

export default scSlice.reducer;