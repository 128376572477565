import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { RootState, AppThunk } from '../../app/store';
import api from '../app/api';
import { RootState } from '../app/store';
import { Player, Position } from '../types/types';

export interface PositionState {
    singleposition: {
        players: Player[];
        position: Position;
    }
    status: 'idle' | 'loading' | 'failed';
}

const initialState: PositionState = {
    singleposition: {
        players: [],
        position: {name: ""},
    },
    status: "idle"
}

interface action { 
    type: string,
    payload: {players: [], position: {}}
}

interface payloadAction {
    players: Player[];
    position: Position;
}

const fetchSinglePosition = (name?:string) => {
    return new Promise<{ data: payloadAction}>((resolve) => {
        if (name ? name.split(" ").length > 1 : false) {
            name = name?.split(' ').join('-');
        }
        api.get('/position/'+name?.toLowerCase()).then(function(payload) {
            resolve(payload);
        })
    })
}


export const GetSinglePosition = createAsyncThunk(
    'position/GetSinglePosition',
    async (name?:string) => {
        const response = await fetchSinglePosition(name);
        return response;
    }
)

export const SelectSinglePosition = (state: RootState) => state.position.singleposition;

export const SelectSinglePositionLoadingStatus = (state: RootState) => state.position.status;

export const PositionSlice = createSlice({
    name: "team",
    initialState,
    reducers: {
        GetSinglePosition: (state, action: action) => {
            state.status = 'loading'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetSinglePosition.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(GetSinglePosition.fulfilled, (state, action) => {
                state.status = 'idle'
                state.singleposition = action.payload.data;
            })
    }   
});

export default PositionSlice.reducer;