import React, { useEffect, useRef } from 'react';

import { Placing, Player, PlayerOwnership } from '../../../types/types';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import CaptCard from '../CaptCard';
export interface Props {
    placing: Placing;
    lastPlayerOwnership?: PlayerOwnership;
}

export const SCTeamCardNumber = styled.b`
    font-size: 1.5rem;
    @media (min-width: 1024px) {
        font-size: 1.5rem ;
    }
`

export const SCTeamCardLabel = styled.span`
    font-size: 1rem;
    line-height: 1;
    @media (min-width: 1024px) {
        font-size: 1rem;
    }
`

export const SCTeamMetricsContainer = styled.div`
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;
    @media(max-width: 800px) {
        flex-direction: row;
        justify-content: center;
        row-gap: 1rem;
        .rank-metric, .rd-metric {
            width: 40%;
        }
        .price-metric {
            width: 100%;
        }
    }
`



const SCTeamMetricsContainer2 = styled.div`
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
    @media(max-width: 800px) {
        h4 {
            text-align: center;
            width: 100%;
        }
        flex-direction: row;
        justify-content: center;
        row-gap: 1rem;
        .rank-metric, .rd-metric {
            width: 40%;
            text-align: center;
        }
        .price-metric {
            width: 100%;
        }
    }
`;

export default function SCTeamHeader({placing,lastPlayerOwnership={id:'-1',players: [], team: {name:'',teamid:''}, season:"", round:-1}}:Props) {
    console.log("SCTEAMHEADER: ", placing)
    let captain = null;
    let vice = null;
    lastPlayerOwnership?.players?.forEach(function(po) {
        if (po.played === 'capt') {
            captain = po.player;
        }
        if (po.played === 'vice') {
            vice = po.player;
        }
    })
    if (placing?.team) {
    return <SCTeamMetricsContainer2 style={{marginLeft: "auto", marginRight: "auto"}}>
        
            <h2 style={{textDecoration: "underline"}}>{placing.team.name}</h2>
            <div style={{display: "flex", columnGap: "0.5rem"}}>
                {captain && <CaptCard position={'capt'} player={captain as Player} /> }
                {vice && <CaptCard position={'vice'} player={vice as Player} /> }
            </div>
            <Tooltip title={"Team's overall ranking for the season (change from last round)"}>
                <div className="rank-metric" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                    <SCTeamCardNumber style={{color: placing.position_change > -1 ? 'red' : 'green'}}>
                        {placing.position}
                        &nbsp;
                        ({placing.position_change > 0 ? '-' : '+'}
                        {Math.abs(placing.position_change)})
                    </SCTeamCardNumber>
                    <SCTeamCardLabel>Season Rank</SCTeamCardLabel>
                </div>
            </Tooltip>
            <Tooltip title={"Team's ranking for the round (change from last round)"}>
                <div className="rd-metric" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                    <SCTeamCardNumber style={{color: placing.round_position_change > -1 ? 'red' : 'green'}}>
                        {placing.round_position}
                        &nbsp;
                        ({placing.round_position_change > 0 ? '-' : '+'}
                        {Math.abs(placing.round_position_change)})
                    </SCTeamCardNumber>
                    <SCTeamCardLabel>Last Rd Rank</SCTeamCardLabel>
                </div>
            </Tooltip>
            <Tooltip title={"The total cost of owning this team (change from last round)"}>
                <div className="price-metric" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                    <SCTeamCardNumber style={{color: placing.price_change > -1 ? 'green' : 'red'}}>
                        {placing.price?.toLocaleString('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0})}
                        &nbsp;
                        ({placing.price_change > 0 ? '+' : ''}
                        {placing.price_change?.toLocaleString('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0})})
                    </SCTeamCardNumber>
                    <SCTeamCardLabel>Price</SCTeamCardLabel>
                </div>
            </Tooltip>
        </SCTeamMetricsContainer2>
    } else {
        return <></>
    }
}