import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { 
    selectPlayer,
    GetSinglePlayer,
    selectSinglePlayer,
    selectLoadingStatus
} from '../../reducers/FrontReducer';


import { Game, Match, GraphType, Team , Rank} from '../../types/types';
import { TableCell, TableRow, Accordion, AccordionSummary, AccordionDetails, Typography, Tabs, Tab , Box, Paper, Tooltip, Container} from '@mui/material';
import PlayerDataGrid from '../PlayerDataGrid';
import { GridColDef, GridCellParams, GridRowParams, GridEvents, MuiEvent, GridCallbackDetails, GridRenderCellParams } from '@mui/x-data-grid';
import { TeamLogos, TeamsList } from '../../Globals';
import { RenderPriceCell, RenderTeamCell, RenderTeamCellToolTip, RenderRankCell } from '../../Globals/TableFunctions';

export interface Props { 
    games: Game[];
}


const columns:GridColDef[]  = [
    { field: 'season', headerName: 'Season', width: 75 },
    { field: 'round', headerName: 'Rd' , type: "number", width: 50 },
    { field: 'opposition', headerName: 'Opponent', width: 140,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderTeamCellToolTip(params.row.opposition, params.row.oppositionlogo, params.row.oppositioncolors,params.row.game))
    },
    { field: 'price', headerName: 'Price' , type: "number", width: 180,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderPriceCell(params.row.price, params.row.price_change)
        
        ), 
        sortComparator: (v1, v2) => { return 1}
    },
    { field: 'points', headerName: "Points Scored", type: 'number', width:180},
    { field: 'nextroundpoints', headerName: "Next Round Predicted Points", type: 'number',width:250},
    { field: 'nextroundprice', headerName: 'Predicted Price' , type: "number", width: 250, 
        renderCell: (params: GridRenderCellParams<Date>) => (RenderPriceCell(params.row.nextroundprice, params.row.predictedPriceChange)), 
        sortComparator: (v1, v2) => { return 1}
    },
    { field: 'nextroundprice', headerName: "Next Round Predicted Price", type: 'number'},
]
const PredictionsTable = ({games}:Props) => {
    
    useEffect(() => {
        
    }, [ games]);
    
    let datagridrows: object[];
    datagridrows = games.map(function(game,index) {
        let oppositionname = game.opposition?.name ? game?.opposition?.name : '';
        let oppositioncolors:string[] = TeamsList[oppositionname];
        let predictedPriceChange:number = game.nextRoundPredictedPrice - game.price;
        return ({
            id: `${index}-${game.linkedmatchid}`,
            season: game.season,
            round: game.round,
            opposition: oppositionname,
            price: game.price?.toLocaleString('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0}),
            nextroundpoints: game.nextRoundPredictedPoints,
            nextroundprice: game.nextRoundPredictedPrice,
            predictedPriceChange: predictedPriceChange,
            game: game,
            points: game.points,
            oppositioncolors: oppositioncolors,
            price_change: game.price_change,
            oppositionlogo: TeamLogos[oppositionname],
        })
    })
    console.log("PREDICTIONS: ", {games, datagridrows});
    return(<PlayerDataGrid height={600} columns={columns} rows={datagridrows} onCellClick={e => console.log("Covid")}/>)    
}

export default PredictionsTable;