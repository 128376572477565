import React, { useEffect, useState } from "react";
import { PlayerStats, Player, LightPlayer } from "../../types/types";
import { Card, CardMedia, Divider, Paper, Tooltip } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { TeamsList } from "../../Globals";
import { Link } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "styled-components";
import { borderRadius } from "@mui/system";
import { selectChosenPlayerIds } from "../../reducers/ChosenPlayers";
import { useAppSelector } from "../../app/hooks";
import { TeamLogos, TOOLTIPS } from "../../Globals";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import PlayerCardPopover from "./PlayerCardPopover";
import { PlaylistPlayRounded } from "@mui/icons-material";
import _ from "underscore";
import {
  CURRENT_SEASON_STRING,
  IMAGES_BUCKET,
  LAST_SEASON_STRING,
} from "../../Globals/Globals";
export interface Props {
  player: LightPlayer;
  stats?: PlayerStats;
  width: string;
  minwidth?: string;
  onPlusClick: (player: Player) => void;
  onNavClick: (player: Player) => void;
  removeIcon?: boolean;
  headerElement?: React.ReactNode;
}

const CardNumber = styled.b`
  font-size: 1rem;
  @media (min-width: 1024px) {
    font-size: 1.1rem;
  }
`;

const CardLabel = styled.span`
  font-size: 0.75rem;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
  }
`;

function PlayerCard({
  player,
  stats,
  width,
  minwidth,
  onPlusClick,
  onNavClick,
  removeIcon,
  headerElement,
}: Props) {
  const DEV = false;

  let chosenPlayerids = useAppSelector(selectChosenPlayerIds);
  if (chosenPlayerids.includes(player.id)) {
    removeIcon = true;
  }

  var playerstats = CalculatePlayerStats({
    games: [player.stats[0]?.lastgame],
    magicnumber: 8767,
  });

  //Need to handle simple player as well.
  let colors = ["", ""];
  let increase: boolean = true;
  let thisseasonstat;
  let lastseasonstat;
  let teamname = "";
  player.stats.map(function (stat) {
    if (stat.season === LAST_SEASON_STRING) {
      lastseasonstat = stat;
      if (stat.lastgame) {
        teamname = stat.lastgame?.team?.name;
      }
    } else if (stat.season === CURRENT_SEASON_STRING) {
      thisseasonstat = stat;
    }
  });
  colors = TeamsList[teamname ? teamname : ""];

  increase = player.stats[0]?.lastgame?.price_change > 0;
  let avgpts = 0;
  let ppm = 0;
  let base = 0;
  let threeavg = 0;
  let mins = 0;
  let total = 0;
  if (thisseasonstat) {
    ppm =
      thisseasonstat?.lastgame?.total_points /
      thisseasonstat?.lastgame?.total_minutes_played;
    let avgMinutes =
      thisseasonstat?.lastgame?.total_minutes_played /
      thisseasonstat?.lastgame?.total_games;
    mins = Math.round(avgMinutes * 100 + Number.EPSILON) / 100;
    base = thisseasonstat?.avgbase;
    avgpts =
      thisseasonstat?.lastgame?.total_minutes_played /
      thisseasonstat?.lastgame?.total_games;
    total = thisseasonstat?.lastgame?.total_points;
  }

  let pricestring = "";
  if (typeof (playerstats.price === "string")) {
    pricestring = thisseasonstat?.lastgame?.price.toString();
  }
  if (!colors) {
    colors = ["#262626", "#161616"];
  }

  let playerimg = "";
  if (DEV) {
    playerimg = "./mysteryplayer.png";
  } else {
    playerimg =
      player.pic !== ""
        ? player.pic
        : `${IMAGES_BUCKET}${player.firstname}_${player.lastname}.png`;
  }
  let teamimg = "";
  teamimg = TeamLogos[teamname ? teamname : ""];
  return (
    <Paper
      style={{
        width: width,
        textAlign: "center",
        minWidth: minwidth ? minwidth : "",
      }}
      className="playercard"
      elevation={3}
    >
      {headerElement ? headerElement : ""}
      <a href={`/player/${player.id}`} style={{ position: "relative" }}>
        <div
          style={{
            background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
          }}
        >
          <div
            style={{
              backgroundImage: `url("${playerimg}")`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              paddingBottom: "60%",
              backgroundRepeat: "no-repeat",
              //backgroundColor: "blue",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: 6, left: 6 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {/* <span style={{color: "white"}}>{player.stats[0]?.last5Games[0]?.team?.name}</span> */}
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={teamimg}
                  alt={
                    player.stats[0]?.lastgame?.team?.name
                      ? player.stats[0]?.lastgame?.team?.name
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </a>
      <Paper>
        {/* <PlayerCardPopover player={player} /> */}
        <a href={`/player/${player.id}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            <span>
              <b style={{ color: "black" }}>
                {player.firstname} <br />
                {player.lastname}
              </b>
            </span>
            <span
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "0.25rem",
              }}
            >
              <b style={{ color: increase ? "green" : "red" }}>{pricestring}</b>
              {increase ? (
                <TrendingUpIcon
                  style={{ color: "green", marginLeft: "0.25rem" }}
                />
              ) : (
                <TrendingDownIcon
                  style={{ color: "red", marginLeft: "0.5rem" }}
                />
              )}
            </span>
          </div>
        </a>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr 1fr",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridRowGap: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <Tooltip title={TOOLTIPS["PTS"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>
                  {avgpts
                    ? avgpts?.toFixed(0)
                    : thisseasonstat.avgpts?.toFixed(0)}
                </CardNumber>
              </div>
              {/*<div><CardNumber>{playerstats.avgpts? playerstats.avgpts.toFixed(0) : player.stats[0]?.avgpts.toFixed(0)}</CardNumber></div> */}
              <CardLabel>Pts</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["PPM"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>
                  {ppm ? ppm.toFixed(2) : playerstats.ppm.toFixed(2)}
                </CardNumber>
              </div>
              <CardLabel>PPM</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["Base"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>{base?.toFixed(0)}</CardNumber>
              </div>
              <CardLabel>Base</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["BE"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>
                  {thisseasonstat?.lastgame?.scbe?.toFixed(0)}
                </CardNumber>
              </div>
              <CardLabel>BE</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["3RA"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>{total}</CardNumber>
              </div>
              <CardLabel>Total</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["Mins"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>{mins?.toFixed(0)}</CardNumber>
              </div>
              <CardLabel>Mins</CardLabel>
            </div>
          </Tooltip>
        </div>

        <Divider />
        {/* <div style={{display: "flex", padding: "0.5rem", paddingLeft: "1rem", paddingRight: "1rem", justifyContent: "space-between"}}>
                    <div style={{ width: "30%", cursor: "pointer"}} onClick={e => onPlusClick(player)}>
                        {removeIcon ? 
                            <RemoveIcon sx={{fontSize: 20}} />
                        : <AddIcon sx={{fontSize: 20}} />}
                        
                    </div>
                    
                    <div style={{ width: "30%", cursor: "pointer"}} onClick={e => onNavClick(player)}>   
                        <a href={`/player/${player.id}`}>
                            <ArrowForwardIosIcon sx={{fontSize: 20}} />
                        </a>
                    </div>
                    
                </div> */}
      </Paper>
    </Paper>
  );
}

export default PlayerCard;
