import React, { useEffect, useState } from "react";
import { Game } from "../../types/types";
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { Player } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { AddPlayer } from "../../reducers/ChosenPlayers";
import { useAppDispatch } from "../../app/hooks";

import PlayerDataGrid from "../PlayerDataGrid";
import { TeamLogos, TeamsList } from "../../Globals";

export interface Props {
  games: Game[];
}

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "team",
    headerName: "Team",
    width: 130,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "38px",
          alignItems: "center",
          background: `linear-gradient(120deg, ${params.row.oppositioncolors[0]} 0%, ${params.row.oppositioncolors[0]} 50%, ${params.row.oppositioncolors[1]} 50%)`,
          color: "white",
        }}
      >
        <img
          style={{ height: "1.5rem", width: "2rem" }}
          src={params.row.teamlogo}
          alt="Team logo"
        />
        <p>{params.row.team}</p>
      </div>
    ),
  },

  { field: "minutes_played", headerName: "Minutes Played", type: "number" },
  { field: "ppm", headerName: "PPM", type: "number" },
  { field: "points", headerName: "Points", type: "number" },
  { field: "base_points", headerName: "Base", type: "number" },
  { field: "power_points", headerName: "Power Pts", type: "number" },
  { field: "create_points", headerName: "Assists", type: "number" },
  {
    field: "negative_play_points",
    headerName: "Negative Play",
    type: "number",
  },
  { field: "scoring_points", headerName: "Scoring", type: "number" },
  { field: "evade_points", headerName: "Attack", type: "number" },
  { field: "tackles", headerName: "Tackles", type: "number" },
  { field: "missed_tackles", headerName: "Missed Tackles", type: "number" },
  { field: "tries", headerName: "Tries", type: "number" },
  { field: "h8", headerName: "H8", type: "number" },
  { field: "hu", headerName: "HU", type: "number" },
  { field: "errors", headerName: "Errors", type: "number" },

  {
    field: "addbutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => <AddIcon />,
  },
];

function SingleMatchTable({ games }: Props) {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [players, setPlayers] = useState<Player[]>([]);
  const [rows, setRows] = useState<object[]>([]);

  useEffect(() => {
    PopulateRows();
  }, [games]);

  const onRowClick = (grid: GridRowParams) => {
    return 1;
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (params.field !== "addbutton") {
      navigate("/player/" + params.row.playerid);
    } else {
      let player: Player = {
        id: "",
        firstname: "",
        lastname: "",
        pic: "",
        position: { name: "" },
        stats: [
          {
            last5Games: [],
            timestamp: "",
            avgpts: 0,
            avgbase: 0,
            ppm: 0,
            season: "",
            lastgame: null,
          },
        ],
        positionrank: [],
        ownerships: [],
      };
      players.map(function (p) {
        if (p.id === params.row.id) {
          player = p;
        }
      });
      dispatch(AddPlayer(player));
    }
  };
  // tackles, tries, hit ups, missed tackles, errors,
  const PopulateRows = () => {
    let newrows: object[] = [];
    games?.map(function (game) {
      let playername: string = "";
      let playerid = 1;
      if (game.player) {
        playername = game.player[0].firstname + " " + game.player[0].lastname;
        playerid = game.player[0].id;
      }
      let teamlogo: string = TeamLogos[game.team.name ? game.team.name : ""];
      let oppositioncolors: string[] =
        TeamsList[game.team.name ? game.team.name : ""];

      newrows.push({
        id: game.id,
        name: playername,
        position: game.position?.name,
        ppm: game.points / game.minutes_played,
        team: game.team.name,
        teamlogo: teamlogo,
        base_points: game.base_points,
        create_points: game.create_points,
        negative_play_points: game.negative_play_points,
        scoring_points: game.scoring_points,
        evade_points: game.evade_points,
        minutes_played: game.minutes_played,
        points: game.points,
        tackles: game.tackles,
        missed_tackles: game.missed_tackles,
        tries: game.tries,
        hu: game.hitups_under_8m,
        h8: game.hitups_over_8m,
        errors: game.errors,
        oppositioncolors: oppositioncolors,
        playerid: playerid,
        posrdrank: game.posrdrank,
        postotalrank: game.posrank,
        power_points: game.tackle_busts + game.effective_offloads,
      });
    });
    setRows(newrows);
  };
  return (
    <PlayerDataGrid
      height={"90vh"}
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
    />
  );
}

export default SingleMatchTable;
