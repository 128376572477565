import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import FrontReducer from '../reducers/FrontReducer';
import TeamReducer from '../reducers/TeamReducer';
import PositionReducer from '../reducers/PositionReducer';
import UserSettingsReducer from '../reducers/UserSettingsReducer';
import SearchReducer from '../reducers/SearchReducer';
import ChosenPlayerReducer from '../reducers/ChosenPlayers';
import SingleMatchReducer from '../reducers/SingleMatchReducer';
import findPlayersReducer from '../reducers/FindPlayers';
import scSliceReducer from '../reducers/SCTeams';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    front: FrontReducer,
    team: TeamReducer,
    position: PositionReducer,
    user: UserSettingsReducer,
    search: SearchReducer,
    chosenplayers: ChosenPlayerReducer,
    singlematch: SingleMatchReducer,
    findplayers: findPlayersReducer,
    sc: scSliceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
