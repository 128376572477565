import { MenuItem, Paper, Select, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PlayerOwnership, SCTeam } from "../../types/types";
import { SelectChangeEvent } from "@mui/material/Select";
import PlayerOwnershipsTable from "./PlayerOwnershipsTable";
import { isString } from "underscore";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLoading, getSingleRoundOwnership } from "../../reducers/SCTeams";
import Loading from "../Loading";
import { CURRENT_SEASON_STRING, LASTROUND } from "../../Globals/Globals";

export interface Props {
  PlayerOwnerships: PlayerOwnership[];
  theTeam: SCTeam;
}

const ROUNDS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25,
];

function PlayerOwnershipContainer({ PlayerOwnerships, theTeam }: Props) {
  const dispatch = useAppDispatch();
  const [selectedRound, setSelectedRound] = useState<number>(LASTROUND);
  const [selectedPlayerOwnership, setSelectedPlayerOwnership] =
    useState<PlayerOwnership>(PlayerOwnerships[0]);

  const isLoading = useAppSelector(selectLoading);

  function BuildSelectedPO(round: number) {
    let found: boolean = false;
    PlayerOwnerships.map(function (po) {
      if (po.round === round) {
        setSelectedPlayerOwnership(po);
        found = true;
      }
    });
    if (!found) {
      dispatch(
        getSingleRoundOwnership({
          teamid: theTeam.teamid,
          round: selectedRound,
        })
      );
      setSelectedPlayerOwnership({
        round: round,
        players: [],
        id: "-1",
        team: theTeam,
        season: CURRENT_SEASON_STRING,
      });
    }
  }

  function onChange(changeevent: SelectChangeEvent<number>) {
    PlayerOwnerships.map(function (po) {
      if (po.round === changeevent.target.value) {
        setSelectedPlayerOwnership(po);
      }
    });
    if (isString(changeevent.target.value)) {
      setSelectedRound(parseInt(changeevent.target.value));
    } else {
      setSelectedRound(changeevent.target.value);
    }
  }

  useEffect(() => {
    BuildSelectedPO(selectedRound);
  }, [PlayerOwnerships, selectedRound]);

  return (
    <Paper style={{ padding: "0.5rem" }} elevation={5}>
      <h3 style={{ marginBottom: "-2rem" }}>Players Owned</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginLeft: "auto",
          marginBottom: "1rem",
          paddingTop: "1rem",
        }}
      >
        <InputLabel id={`playerownershipselect`}>Round</InputLabel>
        <Select
          value={selectedRound}
          label={"Round"}
          onChange={(e) => onChange(e)}
          labelId={"playerownershipselect"}
          style={{ width: 250, textAlign: "left", textTransform: "capitalize" }}
        >
          {ROUNDS.map(function (po) {
            return <MenuItem value={po}>{po}</MenuItem>;
          })}
        </Select>
      </div>
      <div>
        {isLoading === "loading" ? (
          <Loading />
        ) : (
          <PlayerOwnershipsTable PlayerOwnership={selectedPlayerOwnership} />
        )}
      </div>
    </Paper>
  );
}

export default PlayerOwnershipContainer;
