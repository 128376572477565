import React, {ReactNode} from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, GridCellParams, GridRowParams, GridEventListener, GridEvents, MuiEvent, GridCallbackDetails } from '@mui/x-data-grid';
import { Game, Match } from '../types/types';
import { Paper } from '@mui/material';

interface Props {
    rows: object[];
    columns: GridColDef[];
    height: number|string;
    onRowClick?: (params: GridRowParams) => ReactNode;
    onCellClick?: (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => void;
    rowHeight?: number;
    initialSort?: any;
}


function    PlayerDataGrid({height, rows, columns, onRowClick, onCellClick, rowHeight=38,initialSort={}}:Props) {
    return <div style={{height: height, display: "flex"}}>
        <Paper style={{flexGrow: 1}} elevation={5}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                onCellClick={onCellClick}
                onRowClick={onRowClick}
                rowHeight={rowHeight}
                initialState={initialSort}
                //rowsPerPageOptions={[5]}
            />
        </Paper>
    </div>
}

export default PlayerDataGrid;