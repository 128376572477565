import React from "react";
import { PlayerStats, Player, PlayerStats2, Game } from "../../types/types";
import { Divider, Paper, Tooltip } from "@mui/material";

import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { TeamsList } from "../../Globals";

import styled from "styled-components";

import { selectChosenPlayerIds } from "../../reducers/ChosenPlayers";
import { useAppSelector } from "../../app/hooks";
import { TeamLogos, TOOLTIPS } from "../../Globals";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import PlayerCardPopover from "./PlayerCardPopover";
import {
  CURRENT_SEASON,
  CURRENT_SEASON_STRING,
  IMAGES_BUCKET,
  LAST_SEASON_STRING,
} from "../../Globals/Globals";

export interface Props {
  player: Player;
  stats?: PlayerStats;
  width: string;
  onPlusClick: (player: Player) => void;
  onNavClick: (player: Player) => void;
  removeIcon?: boolean;
}

const CardNumber = styled.b`
  font-size: 1rem;
  @media (min-width: 1024px) {
    font-size: 1.1rem;
  }
`;

const CardLabel = styled.span`
  font-size: 0.75rem;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
  }
`;

function PlayerCard({
  player,
  stats,
  width,
  onPlusClick,
  onNavClick,
  removeIcon,
}: Props) {
  const DEV = false;
  const thisSeasonStat = player.stats
    ?.filter((stat) => stat.season === CURRENT_SEASON_STRING)
    .pop();
  let chosenPlayerids = useAppSelector(selectChosenPlayerIds);
  if (chosenPlayerids.includes(player.id)) {
    removeIcon = true;
  }

  var playerstats = CalculatePlayerStats({
    games: thisSeasonStat?.last5Games,
    magicnumber: 8767,
  });

  //Need to handle simple player as well.
  let colors = ["", ""];
  let increase: boolean = true;
  if (thisSeasonStat.lastgame?.team) {
    colors = TeamsList[thisSeasonStat?.lastgame?.team?.name];
    increase = thisSeasonStat?.lastgame.price_change > 0;
  } else {
    increase =
      thisSeasonStat?.last5Games[4]?.price >
      thisSeasonStat?.last5Games[3]?.price;
    colors = TeamsList[thisSeasonStat?.last5Games[0]?.team?.name];
  }

  let thisseasonStat: PlayerStats2 = thisSeasonStat ? thisSeasonStat : null;
  let lastseasonStat: PlayerStats2 = player.stats
    .filter((stat) => stat.season === `${CURRENT_SEASON - 1}`)
    .pop();
  let newseasonStat: PlayerStats2;
  let pricestring = "";
  let ppm: number = 0;
  let avgpts: number = 0;
  // @ts-ignore
  let lastgame: Game | null;
  let thisSeasonPlayerStat;
  player.stats?.map(function (stat) {
    if (stat.season === CURRENT_SEASON_STRING) {
      thisSeasonPlayerStat = CalculatePlayerStats({
        games: stat?.last5Games,
        magicnumber: 8767,
      });
      thisseasonStat = stat;
      lastgame = stat.lastgame ? stat.lastgame : null;
      pricestring = lastgame?.price?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
    } else if (stat.season === LAST_SEASON_STRING) {
      lastseasonStat = stat;
    } else if (stat.season === CURRENT_SEASON_STRING) {
      newseasonStat = stat;
      pricestring = stat?.lastgame?.price?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
    }
  });

  if (thisSeasonStat?.last5Games?.length === 1) {
    increase = true;
  } else if (thisSeasonStat?.last5Games.length === 2) {
    increase =
      thisSeasonStat?.last5Games[thisSeasonStat?.last5Games.length - 1]?.price >
      thisSeasonStat?.last5Games[thisSeasonStat?.last5Games.length - 1]?.price -
        2;
    increase =
      thisSeasonStat?.last5Games[thisSeasonStat?.last5Games.length - 1]
        ?.price_change > 0;
  }

  if (lastgame?.price) {
    if (pricestring === "") {
      pricestring = lastgame?.price?.toString().slice(0, -3);
    }
  }
  if (!colors) {
    colors = ["#262626", "#161616"];
  }

  let playerimg = "";
  if (DEV) {
    playerimg = "./mysteryplayer.png";
  } else {
    playerimg =
      player.pic !== ""
        ? player.pic
        : `${IMAGES_BUCKET}${player.firstname}_${player.lastname}.png`;
  }

  //calculate aveminutes
  let aveminutes = 0;
  let teamname = "";
  if (lastseasonStat?.lastgame) {
    aveminutes =
      lastseasonStat?.lastgame?.total_minutes_played /
      lastseasonStat?.lastgame?.total_games;
    teamname = lastseasonStat?.lastgame?.team?.name;
    if (teamname) {
      colors = TeamsList[teamname];
    }
  }

  if (thisseasonStat) {
    ppm =
      thisseasonStat?.lastgame?.total_points /
      thisseasonStat?.lastgame?.total_minutes_played;
    let avgMinutes =
      thisseasonStat.lastgame?.total_minutes_played /
      thisseasonStat.lastgame?.total_games;
    aveminutes = Math.round(avgMinutes * 100 + Number.EPSILON) / 100;
    avgpts = lastgame?.total_minutes_played / lastgame?.total_games;
    teamname = thisseasonStat.lastgame?.team.name;
  }

  return (
    <Paper
      style={{
        width: width,
        minWidth: width,
        position: "relative",
        textAlign: "center",
      }}
      className="playercard"
      elevation={3}
    >
      <a href={`/player/${player.id}`}>
        <div
          style={{
            background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
          }}
        >
          <div
            style={{
              backgroundImage: `url("${playerimg}")`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              paddingBottom: "60%",
              backgroundRepeat: "no-repeat",
              //backgroundColor: "blue",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            }}
          >
            <div style={{ position: "absolute", top: 6, left: 6 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {/* <span style={{color: "white"}}>{player.stats[0]?.last5Games[0]?.team?.name}</span> */}
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={TeamLogos[teamname]}
                  alt={teamname}
                />
              </div>
            </div>
          </div>
        </div>
      </a>
      <Paper>
        <PlayerCardPopover player={player} />
        <a href={`/player/${player.id}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            <span>
              <b style={{ color: "black" }}>
                {player.firstname[0]}. {player.lastname}
              </b>
            </span>
            <span
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "0.25rem",
              }}
            >
              <b style={{ color: increase ? "green" : "red" }}>{pricestring}</b>
              {increase ? (
                <TrendingUpIcon
                  style={{ color: "green", marginLeft: "0.25rem" }}
                />
              ) : (
                <TrendingDownIcon
                  style={{ color: "red", marginLeft: "0.5rem" }}
                />
              )}
            </span>
          </div>
        </a>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr 1fr",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridRowGap: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <Tooltip title={TOOLTIPS["PTS"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>
                  {avgpts ? avgpts.toFixed(0) : playerstats.avgpts?.toFixed(0)}
                </CardNumber>
              </div>
              {/*<div><CardNumber>{playerstats.avgpts? playerstats.avgpts.toFixed(0) : player.stats[0]?.avgpts.toFixed(0)}</CardNumber></div> */}
              <CardLabel>PTS</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["PPM"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>{ppm !== 0 ? ppm.toFixed(2) : ""}</CardNumber>
              </div>
              <CardLabel>PPM</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["Base"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>{thisseasonStat?.avgbase?.toFixed(0)}</CardNumber>
              </div>
              <CardLabel>Base</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["BE"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>
                  {newseasonStat
                    ? newseasonStat.lastgame?.scbe?.toFixed(0)
                    : lastgame?.scbe?.toFixed(0)}
                </CardNumber>
              </div>
              <CardLabel>BE</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["3RA"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>{thisSeasonPlayerStat?.three}</CardNumber>
              </div>
              <CardLabel>3RA</CardLabel>
            </div>
          </Tooltip>
          <Tooltip title={TOOLTIPS["Mins"]} placement="top">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CardNumber>{aveminutes?.toFixed(0)}</CardNumber>
              </div>
              <CardLabel>Mins</CardLabel>
            </div>
          </Tooltip>
        </div>

        <Divider />
        {/* <div style={{display: "flex", padding: "0.5rem", paddingLeft: "1rem", paddingRight: "1rem", justifyContent: "space-between"}}>
                    <div style={{ width: "30%", cursor: "pointer"}} onClick={e => onPlusClick(player)}>
                        {removeIcon ? 
                            <RemoveIcon sx={{fontSize: 20}} />
                        : <AddIcon sx={{fontSize: 20}} />}
                        
                    </div>
                    
                    <div style={{ width: "30%", cursor: "pointer"}} onClick={e => onNavClick(player)}>   
                        <a href={`/player/${player.id}`}>
                            <ArrowForwardIosIcon sx={{fontSize: 20}} />
                        </a>
                    </div>
                    
                </div> */}
      </Paper>
    </Paper>
  );
}

export default PlayerCard;
