import React, { useEffect, useState } from 'react';
import { GridColDef, GridCellParams, GridRowParams, GridEvents, MuiEvent, GridCallbackDetails, GridRenderCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Diff } from '../../types/types';
import PlayerDataGrid from '../PlayerDataGrid';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';


export interface Props {
    diffs: Diff[]
}

const columns:GridColDef[]  = [
    { field: 'teamname', headerName: 'Name',flex:1 },
    { field: 'thisround_position', headerName: 'This Round Ranking',flex:1 },
    { field: 'lastround_position', headerName: 'Last Round Ranking',flex:1 },
    { field: 'diff', headerName: 'Change',flex:1,
        renderCell: (params: GridRenderCellParams<Date>) => {
            let increase = params.row.diff > 0;
            return <div style={{color: increase ? 'green' : 'red',display: "flex", alignItems: 'center'}}>
                {params.row.diff} 
                {increase ? <TrendingUpIcon style={{color: "green",marginLeft: "0.25rem"}} /> : <TrendingDownIcon style={{color: "red",marginLeft: "0.25rem"}} />}
            </div>
        }
    },
]

const DiffTable = ({diffs}:Props) => {
    const navigate = useNavigate();
    const [ rows, setRows ] = useState<object[]>([]);

    useEffect(() => {
        populateRows();
    },[diffs])
    
    const onCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        navigate("/scteams/"+params.row.teamid);
    }

    const populateRows = () => {
        let newrows:object[] = [];
        diffs.map(function(diff) {
            newrows.push({
                id: `${diff.team.name}-${diff.team.teamid}`,
                teamid: `${diff.team.teamid}`,
                thisround_position: diff.thisround_position,
                lastround_position: diff.lastround_position,
                diff: diff.diff,
                teamname: diff.team.name
            });
        })
        setRows(newrows);
    }
    return <div>
        <PlayerDataGrid
            height={500}
            columns={columns}
            rows={rows}
            onCellClick={onCellClick}
        />
    </div>
}

export default DiffTable;