import React, { useState } from "react";
import { Box, Paper, InputBase, IconButton } from "@mui/material";
import _ from "underscore";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  SelectResults,
  Search,
  SelectLoadingStatus,
} from "../../reducers/SearchReducer";
import SearchResultsTable from "./SearchResultsTable";
import Loading from "../Loading";

function DebouncedSearchContainer() {
  const dispatch = useAppDispatch();
  const searchresults = useAppSelector(SelectResults);
  const loadingstatus = useAppSelector(SelectLoadingStatus);
  const [searchTerm, setSearchTerm] = useState("");

  function DoSearch() {
    //ev.preventDefault();
    dispatch(Search(searchTerm));
  }
  var debounced = _.debounce(DoSearch, 600);
  return (
    <div>
      <Box>
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
          elevation={5}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Players"
            inputProps={{ "aria-label": "search players" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(ev) => {
              debounced();
            }}
          />
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        {loadingstatus === "loading" ? (
          <Loading />
        ) : (
          <SearchResultsTable results={searchresults} nonav={true} />
        )}
      </Box>
    </div>
  );
}

export default DebouncedSearchContainer;
