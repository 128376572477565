import React, { useEffect, useState } from "react";
import { Player, Game, GraphType, MatchWithRanks } from "../../types/types";
import PCCInputs from "./PCCInputs";
import { TextField, Paper } from "@mui/material";
import PCCResults from "./PCCResults";
import { CalculateBE } from "../../functions/CalculateBE";
import Graph from "../Graph";
import _ from "underscore";
import styled from "@emotion/styled";
import { RegisterEvent } from "../../functions/Analytics";
import { CURRENT_SEASON } from "../../Globals/Globals";

const MyPaper = styled(Paper)`
  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 1rem;
  }
`;

const ContainerDiv = styled(Paper)`
  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 1rem;
  }
`;

const GridDiv = styled(Paper)`
  width: 100%;
  @media (min-width: 800px) {
    width: 48%;
  }
`;
export interface Props {
  player: Player;
  games: Game[];
  upcomingGames?: MatchWithRanks[];
}

export interface ChangeProps {
  round: number;
  index: number;
  value: number;
}

export interface Results {
  price: number;
  plusminus: number;
  totalplusminus: number;
  be: number;
  newprice: number;
}

const BASEROUNDS = [12, 12, 12, 12, 12, 12, 12, 12, 12, 12];

let graphs: GraphType[] = [
  {
    name: "Price",
    term: ["price"],
    data: [],
    categories: [],
  },
  {
    name: "Breakeven",
    term: ["be"],
    data: [],
    categories: [],
  },
  {
    name: "Round +/-",
    term: ["plusminus"],
    data: [],
    categories: [],
  },

  {
    name: "Total +/-",
    term: ["totalplusminus"],
    data: [],
    categories: [],
  },
];

function PCCContainer({ player, games, upcomingGames }: Props) {
  //const [ baserounds, setBaseRounds ] = useState(BASEROUNDS || []);
  const [rounds, setRounds] = useState<number[]>(BASEROUNDS);
  const [results, setResults] = useState<Results[]>([]);
  const [newgames, setNewGames] = useState<Game[]>(games || []);
  const [graphData, setGraphData] = useState<GraphType[]>(graphs);
  const [newRounds, setNewRounds] = useState<number[]>(BASEROUNDS);

  useEffect(() => {
    let filteredgames: Game[] = FilterSortGames();
    PopulateRounds(filteredgames.sort((a, b) => (a.round > b.round ? -1 : 0)));
    PopulateRounds2(filteredgames.sort((a, b) => (a.round > b.round ? -1 : 0)));
  }, [games]);

  useEffect(() => {
    PopulateGraphs();
  }, [rounds, results]);

  const onChange = (round: number, index: number, value: number) => {
    if (!isNaN(value)) {
      let newrounds: number[] = rounds.slice();
      newrounds[index] = value;
      RegisterEvent({
        category: "Price Change Calculator",
        action: "changescore",
        label: "Used PCC",
      });
      setRounds(newrounds);
    }
  };

  function FilterSortGames() {
    let newGames: Game[] = games.slice();
    newGames = _.filter(newGames, function (game) {
      return game.season === CURRENT_SEASON && game.round !== 0;
    });
    newGames = _.sortBy(newGames, function (game) {
      return game.round;
    });
    setNewGames(newGames);
    return newGames;
  }

  //function to populate the rounds from the player

  const PopulateRounds = (filteredGames: Game[]) => {
    let newrounds: number[] = rounds.slice();
    let avg: number = 0;

    if (filteredGames.length > 1) {
      filteredGames.map(function (game, index) {
        if (index < 2) {
          if (index === 0) {
            newrounds[index] = filteredGames[0].points;
          } else if (index === 1) {
            newrounds[index] = filteredGames[1].points;
          }
        } else if (index === 2) {
          avg = (newrounds[0] + newrounds[1]) / 2;
          avg = Math.round(avg * 100 + Number.EPSILON) / 100;
          newrounds[index] = avg;
        } else {
          newrounds[index] = avg;
        }
      });
    } else {
      newrounds = [
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
      ];
    }

    setRounds(newrounds);
  };

  const PopulateRounds2 = (filteredGames: Game[]) => {
    let newrounds: number[] = rounds.slice();
    let avg: number = 0;

    if (filteredGames.length > 1) {
      filteredGames.map(function (game, index) {
        if (index < 3) {
          if (index === 0) {
            newrounds[index] = filteredGames[0].points;
          } else if (index === 1) {
            newrounds[index] = filteredGames[1].points;
          } else if (index === 2) {
            newrounds[index] = filteredGames[2].points;
          }
        } else if (index === 3) {
          avg = (newrounds[0] + newrounds[1]) / 2;
          avg = Math.round(avg * 100 + Number.EPSILON) / 100;
          newrounds[index] = avg;
        } else {
          newrounds[index] = avg;
        }
      });
    } else {
      newrounds = [
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
        filteredGames[0].points,
      ];
    }

    setNewRounds(newrounds);
  };

  const PopulateGraphs = () => {
    let newGraphData = graphData.slice();
    newGraphData.map(function (g) {
      g.data = [];
      g.categories = [];
    });

    // dont care about the last 2 games :)
    let myresults = results.slice();
    let old = myresults.splice(0, 2);
    if (old.length > 0) {
      myresults = [old[0], ...myresults];
    }

    myresults.map(function (result, resultindex) {
      newGraphData.map(function (graph) {
        graph.term.map(function (term) {
          if (term in result) {
            graph.data.push(result[term]);
            graph.categories.push(
              `Round: ${resultindex > 0 ? "+" : ""}${resultindex}`
            );
          } else {
          }
        });
      });
    });
    setGraphData(newGraphData);
  };

  return (
    <ContainerDiv elevation={5}>
      {/*<PCCInputs rounds={rounds} onChange={onChange} /> */}
      <PCCResults
        games={games}
        rounds={rounds}
        newRounds={newRounds}
        results={results}
        setResults={setResults}
        onScoreChange={onChange}
        upcomingGames={upcomingGames}
      />
      {/*<div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gridTemplateRows: "1fr 1fr"}}> */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "0.5rem",
          columnGap: "0.25rem",
          rowGap: "0.25rem",
        }}
      >
        {graphData.map(function (graph) {
          if (graph.data.length > 0) {
            return (
              <GridDiv elevation={5}>
                <Graph
                  data={graph.data}
                  title={graph.name}
                  categories={graph.categories}
                  showtitle={graph.name}
                  type={graph.type}
                />
              </GridDiv>
            );
          }
        })}
      </div>
    </ContainerDiv>
  );
}

export default PCCContainer;
