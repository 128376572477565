import React from 'react';
import { PositionRank } from '../../types/types';
import { Chip } from '@mui/material';
import styled from 'styled-components';
import { get_suffix } from '../../functions/Ordinal';
import { useNavigate } from 'react-router-dom';

export interface PosRankChipProps {
    posrank: PositionRank
}

const PositionChip = styled(Chip)`
    background-color: white;
    margin-top: 0.5rem;
    cursor: pointer;
    .MuiChip-deleteIcon {
        padding-right: 0.5rem;
        @media (min-width: 800px) {
            line-height: 21.6px !important;
        }
    }
    &:hover {
        background-color: white;
    }
`

const PosRankChip = ({posrank}:PosRankChipProps) => {
    const navigate = useNavigate();
    return <PositionChip label={posrank.position.name} deleteIcon={<p>{get_suffix(posrank.totalrank)}</p>} onClick={e => navigate(`/position/${posrank.position.name}`)} onDelete={e => navigate(`/position/${posrank.position.name}`)}/>
}

export default PosRankChip;