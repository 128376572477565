import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  GetSinglePosition,
  SelectSinglePosition,
  SelectSinglePositionLoadingStatus,
} from "../reducers/PositionReducer";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from "@mui/material";
import PlayerCard from "../components/PlayerCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
  GridEvents,
  MuiEvent,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import CalculatePlayerStats from "../functions/CalculatePlayerStats";
import { PlayerStats, Player } from "../types/types";
import PlayerDataGrid from "../components/PlayerDataGrid";
import { selectMagicNumber } from "../reducers/UserSettingsReducer";
import AddIcon from "@mui/icons-material/Add";
import { AddPlayer } from "../reducers/ChosenPlayers";
import PlayerCardsContainer from "../components/PlayerCardsContainer";

import { MyContainer, TeamLogos, TeamsList } from "../Globals";
import NavContainer from "../components/Navigation/NavContainer";
import Loading from "../components/Loading";
import { RegisterPageView } from "../functions/Analytics";
import AdsenseAdd from "../components/AdsenseAdd";
import Header from "../components/Header/Header";
import PositionHeader from "../components/Positions/PositionHeader";
import { CURRENT_SEASON_STRING } from "../Globals/Globals";

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 180 },
  {
    field: "team",
    headerName: "Team",
    width: 140,
    renderCell: (params: GridRenderCellParams<Date>) => {
      const colors = params.row.teamcolors ?? [0, 0];
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "38px",
            alignItems: "center",
            background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
            color: "white",
          }}
        >
          <img
            style={{ height: "1.5rem", width: "2rem" }}
            src={params.row.teamlogo}
            alt="Team logo"
          />
          <p>{params.row.teamname}</p>
        </div>
      );
    },
  },
  {
    field: "price",
    headerName: "Price",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <span>
          {params.row.price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
        <span style={{ color: params.row.pricechange > -1 ? "green" : "red" }}>
          &nbsp;
          {params.row.pricechange
            ? `(${params.row.pricechange?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })})`
            : ""}
        </span>
      </div>
    ),
  },
  { field: "scbe", headerName: "BE", type: "number" },
  { field: "avgpts", headerName: "Avg Pts", type: "number" },
  { field: "avgbase", headerName: "Avg Base", type: "number" },
  { field: "ppm", headerName: "PPM", type: "number" },
  { field: "three", headerName: "3rd Avg", type: "number" },
  { field: "five", headerName: "5rd Avg", type: "number" },
  {
    field: "addbutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => <AddIcon />,
  },
  //{ field: 'threeFive', headerName: '3/5 rd' , type: "number"},
];

interface rowclickparam {
  grid: GridRowParams;
}

const SinglePosition = () => {
  let navigate = useNavigate();
  let magicnumber = useAppSelector(selectMagicNumber);
  let loadingstatus = useAppSelector(SelectSinglePositionLoadingStatus);
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<object[]>([]);

  let { name } = useParams<{ name: string }>();
  let singleposition = useAppSelector(SelectSinglePosition);
  let players = singleposition?.players;
  //let rows: object[];
  console.log("Single Position players; ", players);
  useEffect(() => {
    dispatch(GetSinglePosition(name));
    PopulateRows();
    RegisterPageView({ path: `/position/${name}` });
  }, [name]);

  useEffect(() => {
    PopulateRows();
  }, [singleposition]);

  useEffect(() => {
    PopulateRows();
  }, [name, magicnumber]);

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (params.field !== "addbutton") {
      navigate("/player/" + params.row.id);
    } else {
      let player: Player = {
        id: "",
        firstname: "",
        lastname: "",
        pic: "",
        position: { name: "" },
        stats: [
          {
            last5Games: [],
            timestamp: "",
            avgpts: 0,
            avgbase: 0,
            ppm: 0,
            lastgame: null,
            season: "",
          },
        ],
        positionrank: [],
        ownerships: [],
      };
      players.map(function (p) {
        if (p.id === params.row.id) {
          player = p;
        }
      });
      dispatch(AddPlayer(player));
    }
  };

  const onRowClick = (grid: GridRowParams) => {
    navigate("/player/" + grid.row.id);
    return 1;
  };
  const PopulateRows = () => {
    var newrows: object[] = [];
    singleposition.players.map(function (player) {
      if (player) {
        if (player.stats[0]?.last5Games) {
          let thisstat;
          let lastgame;
          player.stats.map(function (stat) {
            if (stat.season === CURRENT_SEASON_STRING) {
              thisstat = stat;
              if (stat.lastgame) {
                lastgame = stat.lastgame;
              }
            }
          });
          var playerstats = CalculatePlayerStats({
            games: thisstat.last5Games,
            magicnumber: magicnumber,
          });
          //var position = player.stats[0].last5Games[0].position;
          let increase: boolean = lastgame?.price_change > 0;
          let teamname = lastgame?.team.name
            ? lastgame.team.name
            : player.stats[0].last5Games[0]?.team.name;
          let teamcolors: string[] = TeamsList[teamname];
          newrows.push({
            id: player.id,
            name: `${player.firstname} ${player.lastname}`,
            team: teamname,
            three: playerstats.three,
            five: playerstats.five,
            price: lastgame?.price,
            priceincrease: increase,
            avgpts: playerstats.avgpts
              ? playerstats.avgpts
              : player.stats[0].avgpts,
            avgbase: lastgame?.base,
            teamlogo: TeamLogos[teamname],
            teamcolors: teamcolors,
            teamname: teamname,
            pricechange: lastgame?.price_change,
            ...playerstats,
          });
        }
      }
    });

    setRows(newrows);
  };
  if (loadingstatus === "loading") {
    return <Loading />;
  }
  if (singleposition?.players?.length > 0) {
    return (
      <div>
        <NavContainer />
        <Header />

        <MyContainer
          maxWidth="xl"
          style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        >
          <PositionHeader thisPosition={name ? name : ""} />
          <PlayerCardsContainer
            players={players}
            hideposition={true}
            positionname={name ? name : ""}
          />
          <PlayerDataGrid
            height={"90vh"}
            rows={rows}
            columns={columns}
            onCellClick={onCellClick}
          />
          <AdsenseAdd />
        </MyContainer>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default SinglePosition;
