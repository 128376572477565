import React, { useEffect, useState } from "react";
import NavContainer from "../components/Navigation/NavContainer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  selectChosenPlayers,
  GetMultiplePlayerGames,
  selectSelectedGames,
} from "../reducers/ChosenPlayers";
import SelectedPlayersTable from "../components/SelectedPlayers/SelectedPlayersTable";
import {
  Select,
  MenuItem,
  InputLabel,
  Tabs,
  Tab,
  Box,
  Button,
  Paper,
} from "@mui/material";
import MultiSeriesGraph from "../components/MultiSeriesGraph";
import ComparePlayersTable from "../components/ComparePlayers/ComparePlayersTable";
import { FILTERITEMS, MyContainer } from "../Globals";
import { SelectChangeEvent } from "@mui/material/Select";
import DebounceSearchContainer from "../components/Search/DebouncedSearchContainer";
import _, { isArray } from "underscore";
import { Player, Game } from "../types/types";
import {
  RegisterEvent,
  RegisterModalView,
  RegisterPageView,
} from "../functions/Analytics";
import styled from "styled-components";
import AdsenseAdd from "../components/AdsenseAdd";
import Header from "../components/Header/Header";
import { CURRENT_SEASON } from "../Globals/Globals";
interface Series {
  name: string;
  data: number[];
}

const BASEROUNDS = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  14: [],
  15: [],
  16: [],
  17: [],
  18: [],
  19: [],
  20: [],
  21: [],
  22: [],
  23: [],
  24: [],
  25: [],
};

let CATEGORIES = [
  "Round 1",
  "Round 2",
  "Round 3",
  "Round 4",
  "Round 5",
  "Round 6",
  "Round 7",
  "Round 8",
  "Round 9",
  "Round 10",
  "Round 11",
  "Round 12",
  "Round 13",
  "Round 14",
  "Round 15",
  "Round 16",
  "Round 17",
  "Round 18",
  "Round 19",
  "Round 20",
  "Round 21",
  "Round 22",
  "Round 23",
  "Round 24",
  "Round 25",
];

const PlayerHeadLineContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

const ComparePlayers = () => {
  const dispatch = useAppDispatch();
  const selectedPlayers = useAppSelector(selectChosenPlayers);
  const selectedPlayerGames = useAppSelector(selectSelectedGames);
  const [selectedTab, setSelectedTab] = useState(
    selectedPlayers.length === 0 ? 0 : 1
  );
  const [graphSeries, setGraphSeries] = useState<Series[]>([]);
  const [selectedSeason, setSelectedSeason] = useState(CURRENT_SEASON);
  const [selectedTerm, setSelectedTerm] = useState("points");
  const [tableGames, setTableGames] = useState<Game[]>([]);
  const [rounds, setRounds] = useState<string[]>([]);

  RegisterPageView({ path: "/compare" });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    RegisterModalView({ name: `/compareplayers/${newValue}` });
  };

  useEffect(() => {
    FetchSelectedPlayerGames();
    RegisterEvent({
      category: "ComparePlayers",
      action: "add player",
      label: `${selectedPlayers
        .map(function (p) {
          return `${p.id} ${p.firstname} ${p.lastname}`;
        })
        .join(",")}`,
    });
  }, [selectedPlayers]);

  useEffect(() => {
    BuildSeries();
  }, [selectedPlayerGames, selectedSeason, selectedTerm]);

  const FetchSelectedPlayerGames = () => {
    let ids: string[] = selectedPlayers.map(function (player) {
      return player.id;
    });
    let idstring: string = ids.join(",");
    dispatch(GetMultiplePlayerGames(idstring));
  };

  function onChange(changeevent: SelectChangeEvent<string>) {
    let newvalue = changeevent.target.value;
    setSelectedTerm(changeevent.target.value);
  }

  function onChangeSeason(changeevent: string | number) {
    console.log("onChangeSeason: ", changeevent);
    if (typeof changeevent !== "string") {
      setSelectedSeason(changeevent);
    }
  }

  function BuildSeries() {
    let rds: string[] = [];
    let rounds: object = BASEROUNDS;
    let newseries: Series[] = [];
    let playerobj = {};
    let playerobj2 = {};
    let mygames = selectedPlayerGames.slice();
    mygames = _.chain(mygames)
      .filter(function (game) {
        return game.season === selectedSeason && game.round !== 0;
      })
      .sortBy("round")
      .value();

    //need to get all the rounds :)

    mygames.map(function (game) {
      let player: Player;
      if (isArray(game.player)) {
        player = game.player[0];
      } else {
        // @ts-ignore
        player = game.player
          ? game.player
          : {
              id: "",
              position: { name: "" },
              pic: "",
              stats: [
                {
                  last5Games: [],
                  timestamp: "",
                  avgpts: 0,
                  avgbase: 0,
                  ppm: 0,
                },
              ],
              firstname: "",
              lastname: "",
              positionrank: [],
              ownerships: [],
            };
      }
      let name: string = player.firstname + " " + player.lastname;
      if (playerobj2[name]) {
        playerobj2[name][game.round] = game[selectedTerm];
      } else {
        playerobj2[name] = { ...BASEROUNDS };
        playerobj2[name][game.round] = game[selectedTerm];
      }
    });

    Object.entries(playerobj2).map(function (name) {
      let numberarray: number[] = [];
      Object.entries(playerobj2[name[0]]).forEach(function (entry) {
        if (isArray(entry[1])) {
          numberarray.push(0);
        } else {
          numberarray.push(parseInt(playerobj2[name[0]][entry[0]]?.toString()));
        }
      });

      newseries.push({
        name: name[0],
        data: numberarray,
      });
    });

    setGraphSeries(newseries);
    setTableGames(mygames);
    setRounds(rds);
  }

  return (
    <div style={{ paddingBottom: "5rem" }}>
      <NavContainer />
      <Header />
      <MyContainer maxWidth="xl">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
            style={{ marginBottom: "1rem" }}
          >
            <Tab label="Add A Player" />
            <Tab label="Compare" />
          </Tabs>
        </Box>
        {selectedTab === 0 ? (
          <div>
            <div style={{ marginTop: "1.5rem" }}>
              <PlayerHeadLineContainer>
                <p
                  style={{
                    textAlign: "left",
                    marginBottom: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  Selected Players
                </p>
                {selectedPlayers.length > 0 ? (
                  <Button variant="outlined" onClick={(e) => setSelectedTab(1)}>
                    Compare
                  </Button>
                ) : null}
              </PlayerHeadLineContainer>

              <SelectedPlayersTable
                players={selectedPlayers}
                setOpen={(e) => console.log("helo")}
              />
            </div>
            <div style={{ marginTop: "1.5rem", marginBottom: "1rem" }}>
              <DebounceSearchContainer />
            </div>
          </div>
        ) : null}
        {selectedTab === 1 ? (
          <div>
            {selectedPlayers.length !== 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <InputLabel id={`inputlabel`}>Graph Items</InputLabel>
                    <Select
                      value={selectedTerm}
                      label={"Compare"}
                      onChange={(e) => onChange(e)}
                      labelId={"inputlabel"}
                    >
                      {FILTERITEMS.map(function (option) {
                        return <MenuItem value={option}>{option}</MenuItem>;
                      })}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginLeft: "0.5rem",
                    }}
                  >
                    <InputLabel id={`seasoninputlabel`}>Season</InputLabel>
                    <Select
                      value={selectedSeason}
                      label={"Season"}
                      onChange={(e) => onChangeSeason(e.target.value)}
                      labelId={"seasoninputlabel"}
                    >
                      <MenuItem value={2024}>2024</MenuItem>
                      <MenuItem value={2023}>2023</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                      <MenuItem value={2020}>2020</MenuItem>
                    </Select>
                  </div>
                </div>
                <Paper elevation={5}>
                  <MultiSeriesGraph
                    series={graphSeries}
                    title=""
                    categories={CATEGORIES}
                    showtitle={selectedTerm}
                    type={"line"}
                  />
                  <SelectedPlayersTable
                    players={selectedPlayers}
                    setOpen={(e) => console.log("helo")}
                  />
                </Paper>
                <p
                  style={{
                    width: "100%",
                    textAlign: "left",
                    marginBottom: "0.5rem",
                    marginTop: "2rem",
                  }}
                >
                  Players Games
                </p>
                <ComparePlayersTable games={tableGames} />
              </div>
            ) : (
              <Button variant="outlined" onClick={(e) => setSelectedTab(0)}>
                Add players to compare
              </Button>
            )}
          </div>
        ) : null}

        <AdsenseAdd />
      </MyContainer>
    </div>
  );
};

export default ComparePlayers;
