import React, { useEffect, useState } from "react";
import { PlayerStats, Player } from "../../types/types";
import { Card, CardMedia, Divider, Paper, Tooltip } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { TeamsList } from "../../Globals";
import { Link } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "styled-components";
import { borderRadius } from "@mui/system";
import { selectChosenPlayerIds } from "../../reducers/ChosenPlayers";
import { useAppSelector } from "../../app/hooks";
import { TeamLogos, TOOLTIPS } from "../../Globals";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import PlayerCardPopover from "./PlayerCardPopover";
import {
  CURRENT_SEASON_STRING,
  GetPlayerImage,
  IMAGES_BUCKET,
  LAST_SEASON_STRING,
} from "../../Globals/Globals";

export interface Props {
  player: Player;
  ownershipPercentage: number;
  stats?: PlayerStats;
  width: string;
  onPlusClick: (player: Player) => void;
  onNavClick: (player: Player) => void;
  removeIcon?: boolean;
}

const CardNumber = styled.b`
  font-size: 1rem;
  @media (min-width: 1024px) {
    font-size: 1.1rem;
  }
`;

const LargeCardNumber = styled.b`
  font-size: 2rem;
  color: green;
  @media (min-width: 1024px) {
    font-size: 1.8rem;
  }
`;

const CardLabel = styled.span`
  font-size: 0.75rem;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const LargeCardLabel = styled.span`
  font-size: 0.75rem;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
  }
`;

function PlayerCardOwnership({
  player,
  stats,
  width,
  onPlusClick,
  onNavClick,
  removeIcon,
  ownershipPercentage,
}: Props) {
  let lastseasonstats;
  let thisseasonstat;
  player.stats.map(function (stat) {
    if (stat.season === LAST_SEASON_STRING) {
      lastseasonstats = stat;
    }
    if (stat.season === CURRENT_SEASON_STRING) {
      thisseasonstat = stat;
    }
  });
  let teamname = "";
  if (lastseasonstats?.lastgame) {
    teamname = lastseasonstats.lastgame?.team?.name;
  }
  if (thisseasonstat?.lastgame) {
    teamname = thisseasonstat.lastgame?.team?.name;
  }
  let lastgame = player.stats[0].lastgame;
  const DEV = false;
  let chosenPlayerids = useAppSelector(selectChosenPlayerIds);
  if (chosenPlayerids.includes(player.id)) {
    removeIcon = true;
  }
  //Need to handle simple player as well.
  let colors = ["", ""];
  if (teamname) {
    colors = TeamsList[teamname];
  } else {
    colors = TeamsList[lastgame?.team?.name];
  }

  if (!colors) {
    colors = ["#262626", "#161616"];
  }

  let playerimg = "";
  if (DEV) {
    playerimg = "./mysteryplayer.png";
  } else {
    playerimg = player.pic !== "" ? player.pic : GetPlayerImage(player);
  }
  return (
    <Paper
      style={{
        width: width,
        minWidth: width,
        position: "relative",
        textAlign: "center",
      }}
      className="playercard"
      elevation={3}
    >
      <a href={`/player/${player.id}`}>
        <div
          style={{
            background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
          }}
        >
          <div
            style={{
              backgroundImage: `url("${playerimg}")`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              paddingBottom: "60%",
              backgroundRepeat: "no-repeat",
              //backgroundColor: "blue",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            }}
          >
            <div style={{ position: "absolute", top: 6, left: 6 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {/* <span style={{color: "white"}}>{player.stats[0]?.last5Games[0]?.team?.name}</span> */}
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={TeamLogos[teamname]}
                  alt={teamname}
                />
              </div>
            </div>
          </div>
        </div>
      </a>
      <Paper>
        <PlayerCardPopover player={player} />
        <a href={`/player/${player.id}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            <span>
              <b style={{ color: "black" }}>
                {player.firstname[0]}. {player.lastname}
              </b>
            </span>
          </div>
        </a>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <Tooltip
            title={"% Of SC Players who own this player"}
            placement="top"
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <LargeCardNumber style={{ color: "green" }}>
                  {`${ownershipPercentage}%`}
                </LargeCardNumber>
              </div>
              <LargeCardLabel>Ownership</LargeCardLabel>
            </div>
          </Tooltip>
        </div>

        <Divider />
      </Paper>
    </Paper>
  );
}

export default PlayerCardOwnership;
