import React from 'react';
import { GetPlayerImage, IMAGES_BUCKET } from '../../Globals/Globals';
import { Typography } from '@mui/material';

const size = '5rem'

const CaptCard = ({position, player}) => {
    const crownlogo = position === 'capt' ? '/images/icons/crown-gold.svg' : '/images/icons/crown-silver.svg';
    console.log("img: ", `${IMAGES_BUCKET}${player.firstname.replace(" ",'')}_${player.lastname}.png`)
    return <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
        <div style={{
            backgroundImage: `url(${GetPlayerImage(player)})`,
            width: size, 
            height: size,
            backgroundSize:"cover",
            backgroundPosition: "center center",
            position: "relative",
            borderRadius: "50%", 
            border: `4px solid ${position === 'capt' ? 'gold' : 'grey'}`
        }} >
            <img src={crownlogo} style={{color: position==='capt' ? 'gold' : 'silver', position: "absolute", top: -6, right: -12, width: 24, height:24}} /> 
            </div>
        
        <b >{player.firstname[0]} {player.lastname}</b>
    </div>
}

export default CaptCard;