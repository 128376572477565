import React, { useEffect, useState } from "react";
import { CalculateBE, CalculateBEPoints } from "../../functions/CalculateBE";
import { useAppSelector } from "../../app/hooks";
import { selectMagicNumber } from "../../reducers/UserSettingsReducer";
import { Game, MatchWithRanks } from "../../types/types";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { Results } from "./PCCContainer";
import styled from "@emotion/styled";
import _ from "underscore";
import SingleInput from "./SingleInput";
import {
  CalculateNextBE,
  CallculatePriceChange2,
  CalculatePreviousBE,
  CalculateBENew,
  CURRENT_SEASON,
} from "../../Globals/Globals";
import TeamIcon from "../TeamIcon";

const InputTableCell = styled(TableCell)`
  padding: 0.5rem;
  @media (min-width: 800px) {
    padding: 1rem;
  }
`;

const GeneralTableCell = styled(TableCell)`
  font-size: 0.75rem;
  padding: 0.25rem;
  @media (min-width: 800px) {
    font-size: 0.8rem;
  }
`;

export interface Props {
  games: Game[];
  rounds: number[];
  newRounds: number[];
  upcomingGames?: MatchWithRanks[];
  setResults: (results: Results[]) => void;
  results: Results[];
  onScoreChange: (round: number, index: number, value: number) => void;
}

function CalculatePrice(games: Game[], magicnumber: number) {
  let avg: number = 0;
  games.map(function (game, index) {
    if (index < 3) {
      avg = avg + game.points;
    }
  });
  avg = avg / 3;
  let price: number = avg * magicnumber;
  return price;
}

function CalculatePriceRounds(rounds: number[], magicnumber: number) {
  let avg: number = 0;
  rounds.map(function (round, index) {
    if (index < 3) {
      avg = avg + round;
    }
  });
  avg = avg / 3;
  avg = avg * magicnumber;
  return avg;
}

function CalculatePriceRounds2(
  rounds: number[],
  magicnumber: number,
  lastprice: number
) {
  let avg: number = 0;
  //new cv: (0.75 x CV) + (0.25 x TGA x MN) = CV

  rounds.map(function (round, index) {
    if (index < 3) {
      avg = avg + round;
    }
  });
  avg = avg / 3;
  let cv: number = 0;
  cv = 0.75 * lastprice + 0.25 * avg * magicnumber;
  console.log("CalculatePriceRounds2: ", cv, rounds, lastprice, avg);
  return cv;
}

function getOppositionFromTeam(
  game: MatchWithRanks,
  myTeam: { name: string },
  index: number
) {
  return game?.teams?.find((team) => team?.name !== myTeam?.name);
}

function PCCResults({
  games,
  rounds,
  newRounds,
  results,
  upcomingGames = [],
  setResults,
  onScoreChange,
}: Props) {
  const [newGames, setNewGames] = useState<Game[]>(games);
  const [thisPlayersTeam, setThisPlayersTeam] = useState<{ name: string }>(
    null
  );
  let statemagicnumber = useAppSelector(selectMagicNumber);
  statemagicnumber = 9800;

  //need to update game scores from rounds.
  function UpdateGameScores() {
    let newgames: Game[] = games.slice();
    newgames = _.filter(newgames, function (game) {
      return game.season === CURRENT_SEASON && game.round !== 0;
    });
    newgames = _.sortBy(newgames, function (game) {
      return -game.round;
    });

    newgames = newgames.map(function (game, index) {
      var newgame = { ...game };
      if (index > 8) {
        newgame["points"] = rounds[index + 2];
      }
      return newgame;
    });
    setThisPlayersTeam(newgames[0]?.team);
    setNewGames(newgames);
  }

  useEffect(() => {
    UpdateGameScores();
    //RoundCalculateStats();
    RoundCalculateStats2();
  }, [games]);

  useEffect(() => {
    UpdateGameScores();
    //RoundCalculateStats();
    RoundCalculateStats2();
  }, [rounds]);

  useEffect(() => {}, [rounds]);

  function CalculateStats() {
    //Get BE based off scores for matches 0-9
    // Calculate BE from rd 6
    let newresults: Results[] = [];
    let totalplusminus: number = 0;
    if (newGames.length > 0) {
      for (let i = 0; i < 7; i++) {
        let thisgames: Game[] = newGames.slice(i, i + 3);
        let be: number = CalculateBE({
          games: thisgames,
          magicnumber: statemagicnumber,
        });
        let newprice = CalculatePrice(thisgames, statemagicnumber);
        totalplusminus = totalplusminus + games[i].price;
        newresults.push({
          price: Math.round(newprice * 100 + Number.EPSILON) / 100,
          plusminus: newGames[i].price - newGames[i + 1].price,
          be: Math.round(newGames[i].price * 100 + Number.EPSILON) / 100,
          totalplusminus: totalplusminus,
          newprice: newprice,
        });
      }
    }

    setResults(newresults);
  }

  function RoundCalculateStats() {
    //let newresults:Results[] = results.slice();
    let newresults: Results[] = [];
    let totalplusminus: number = 0;

    let myrounds = rounds.slice();
    myrounds.splice(0, 2);

    rounds.map(function (round, roundindex) {
      if (roundindex < 8) {
        let price: number = 0;
        let be: number = 0;
        let lastprice: number = 0;
        let plusminus: number = 0;

        if (roundindex < 2) {
          //lastprice = initialPrice;
          //price = initialPrice;
        } else {
          lastprice = newresults[roundindex - 1].price;
        }
        if (roundindex === 2) {
          lastprice = newresults[0].price;
        }

        if (roundindex > 1) {
          let thisrounds: number[] = rounds.slice(
            roundindex - 2,
            roundindex + 1
          );
          //price =  CalculatePriceRounds(thisrounds,8767);
          //if (roundindex === 2) {
          //  price = newGames[roundindex-1]?.price
          //} else {
          console.log("CalculatePriceRounds2: ", roundindex, lastprice, rounds);
          price = CalculatePriceRounds2(
            thisrounds,
            statemagicnumber,
            lastprice
          );
          //}

          be = CalculateBEPoints(thisrounds, statemagicnumber, price);
          price = Math.round(price * 100 + Number.EPSILON) / 100;
          be = Math.round(be * 100 + Number.EPSILON) / 100;
        } else {
          price = newGames[roundindex]?.price;
        }

        if (roundindex > 2) {
          plusminus = price - lastprice;
          totalplusminus = totalplusminus + plusminus;
        } else if (roundindex === 1) {
          totalplusminus = 0;
          plusminus = 0;
        } else {
          plusminus = 0;
        }
        console.log(`PLusminus: rd:${round} ${plusminus}`);
        newresults.push({
          price: price,
          plusminus: Math.round(plusminus * 100 + Number.EPSILON) / 100,
          be: be,
          totalplusminus:
            Math.round(totalplusminus * 100 + Number.EPSILON) / 100,
          newprice: price,
        });
      }
    });
    setResults(newresults);
  }

  function RoundCalculateStats2() {
    // rounds = array of []
    let newresults: Results[] = [];
    let totalplusminus: number = 0;

    newRounds.map(function (round, roundindex) {
      let s1 = 0;
      let s2 = 0;
      let s3 = 0;
      let currentValue = newGames[0]?.price;
      let previousValue = newGames[1]?.price;
      // Need to handle case where we don't have a previous price
      if (newGames.length === 1) {
        previousValue = currentValue;
      }
      if (roundindex > 8) {
        return;
      }
      if (roundindex < 4) {
        if (roundindex === 0) {
          s1 = rounds[0];
          s2 = rounds[0];
          s3 = rounds[0];
        } else if (roundindex === 1) {
          s1 = rounds[0];
          s2 = rounds[1];
          s3 = rounds[1];
        } else if (roundindex === 2) {
          s1 = rounds[2];
          s2 = rounds[0];
          s3 = rounds[1];
        } else if (roundindex === 3) {
          s1 = rounds[3];
          s2 = rounds[2];
          s3 = rounds[1];
        }
        //lastprice = initialPrice;
        //price = initialPrice;
      } else {
        s1 = rounds[roundindex - 2];
        s2 = rounds[roundindex - 1];
        s3 = rounds[roundindex];
        currentValue = newresults[roundindex - 1]?.price;
        previousValue = newresults[roundindex - 2]?.price;
      }

      let previousBE = CalculatePreviousBE({
        s3,
        s2,
        magicNumber: statemagicnumber,
        currentValue: previousValue,
      });
      if (roundindex <= 2) {
        previousBE = games[games.length - 1].scbe;
      }
      let priceChange2 = CallculatePriceChange2({
        s1,
        be: previousBE,
        magicNumber: statemagicnumber,
      });

      let nextBE = CalculateBENew({
        s1,
        s2,
        currentPrice: currentValue,
        magicNumber: statemagicnumber,
      });

      let theBe = CalculateNextBE({
        s1,
        s2,
        currentPrice: currentValue,
        priceChange: priceChange2,
        magicNumber: statemagicnumber,
      });

      totalplusminus = totalplusminus + priceChange2;
      if (roundindex === 0) {
        priceChange2 = 0;
        totalplusminus = 0;
      }
      if (roundindex === 2) {
        // priceChange2 = previousValue - newresults[0]["price"];
        previousValue = newresults[0]["price"];
      } else if (roundindex > 2) {
        previousValue = newresults[roundindex - 1]["price"];
      }
      if (roundindex === 1) {
        totalplusminus = 0;
      }

      newresults.push({
        price: roundindex === 0 ? currentValue : previousValue + priceChange2,
        plusminus: priceChange2,
        be: Math.round((nextBE + Number.EPSILON) * 100) / 100,
        totalplusminus,
        newprice: previousValue + priceChange2,
        //@ts-ignore
        previousBE,
        priceChange2,
        nextBE,
        currentValue,
        previousValue,
        s1,
        s2,
        s3,
        theBe,
        roundindex,
        statemagicnumber,
      });
    });
    console.log("New results:", newresults);
    setResults(newresults);
  }

  return (
    <Table style={{ fontSize: "0.5rem", padding: "1rem", maxWidth: "100%" }}>
      <TableHead>
        <TableRow>
          <TableCell>Points</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>RD +/-</TableCell>
          <TableCell>Total +/-</TableCell>
          <TableCell>BE</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <InputTableCell>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "0.5rem",
              }}
            >
              <SingleInput
                value={rounds[1]}
                label={`Round: -2 (2 Games ago)`}
                onChange={(val) => onScoreChange(1, 1, parseInt(val))}
                id={"single-input-1"}
              />
              <TeamIcon team={newGames[1]?.opposition} />
            </div>
          </InputTableCell>
        </TableRow>
        <TableRow>
          <InputTableCell>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "0.5rem",
              }}
            >
              <SingleInput
                label={`Round: -1  (1 Game ago)`}
                value={rounds[0]}
                onChange={(val) => onScoreChange(0, 0, parseInt(val))}
                id={"single-input-2"}
              />
              <TeamIcon team={newGames[0]?.opposition} />
            </div>
          </InputTableCell>
        </TableRow>

        {results.map(function (result, index) {
          if (index > 1) {
            let label: string = "";
            if (index === 2) {
              label = "Next Game";
            } else {
              label = `Round: +${index - 2}`;
            }
            return (
              <TableRow>
                <InputTableCell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "0.5rem",
                    }}
                  >
                    <SingleInput
                      label={label}
                      value={rounds[index]}
                      onChange={(val) =>
                        onScoreChange(index, index, parseInt(val))
                      }
                      id={`single-input-${index}`}
                    />
                    <TeamIcon
                      team={getOppositionFromTeam(
                        upcomingGames[index - 2],
                        thisPlayersTeam,
                        index - 2
                      )}
                    />
                  </div>
                </InputTableCell>
                <GeneralTableCell>
                  {result.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumSignificantDigits: 6,
                  })}
                </GeneralTableCell>
                <GeneralTableCell>
                  {result.plusminus.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumSignificantDigits: 5,
                  })}
                </GeneralTableCell>
                <GeneralTableCell>
                  {result.totalplusminus.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumSignificantDigits: 6,
                  })}
                </GeneralTableCell>
                <GeneralTableCell>{result.be}</GeneralTableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
    </Table>
  );
}

export default PCCResults;
