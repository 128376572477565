import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  GetSingleTeam,
  SelectTeam,
  SelectRanks,
  SelectStatus,
} from "../reducers/TeamReducer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  AccordionDetails,
  AccordionSummary,
  Paper,
  Accordion,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import PlayerCard from "../components/PlayerCard/PlayerCard";
import { AddPlayer } from "../reducers/ChosenPlayers";
import SingleTeamTable from "../components/SingleTeam/SingleTeamTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlayerCardsContainer from "../components/PlayerCardsContainer";
import { TeamLogos, TeamsList } from "../Globals";
import UpcomingMatches from "../components/SingleTeam/UpcomingMatches";
import SingleTeamHeader from "../components/SingleTeam/SingleTeamHeader";
import SingleTeamsGames from "../components/SingleTeam/SingleTeamGames";
import NavContainer from "../components/Navigation/NavContainer";
import RanksTable from "../components/SingleTeam/RanksTable";
import Loading from "../components/Loading";
import { MyContainer } from "../Globals";
import { RegisterPageView } from "../functions/Analytics";
import AdsenseAdd from "../components/AdsenseAdd";
import Header from "../components/Header/Header";
import { CURRENT_SEASON, CURRENT_SEASON_STRING } from "../Globals/Globals";

const SingleTeam = () => {
  const dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState(0);

  let { name } = useParams<{ name: string }>();
  let singleteam = useAppSelector(SelectTeam);
  let ranks = useAppSelector(SelectRanks);
  const status = useAppSelector(SelectStatus);

  let matches = singleteam.matches;
  matches = matches.filter(function (match) {
    if (match.awayteam) {
      return match;
    }
  });
  let team = singleteam.team;
  let players = singleteam.players;
  let upcoming = singleteam.upcoming;

  useEffect(() => {
    dispatch(GetSingleTeam(name));
    RegisterPageView({ path: `/team/${name}` });
  }, [name]);

  let teamname = team.name ? team.name : "";
  var colors = TeamsList[teamname];
  if (!colors) {
    colors = ["#262626", "#161616"];
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (status === "loading") {
    return <Loading />;
  }

  return (
    <div>
      <NavContainer />
      <Header />
      <SingleTeamHeader
        team={team}
        upcoming={upcoming}
        lastmatch={singleteam.lastmatch}
      />
      <MyContainer maxWidth="xl">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
            style={{ marginBottom: "1rem" }}
          >
            <Tab label="Players" />
            <Tab label="Games" />
            <Tab label="Upcoming" />
          </Tabs>
        </Box>

        {selectedTab === 0 ? (
          <div>
            <RanksTable ranks={ranks} />
            <PlayerCardsContainer players={players} positionname={"any"} />
            <SingleTeamTable players={players} />
          </div>
        ) : null}
        {selectedTab === 1 ? (
          <div>
            <SingleTeamsGames
              matches={matches.filter(
                (match) => match.season === CURRENT_SEASON
              )}
              teamname={teamname}
            />
          </div>
        ) : null}
        {selectedTab === 2 ? (
          <div>
            <UpcomingMatches matches={upcoming} thisteam={teamname} />
          </div>
        ) : null}
        <div style={{ marginBottom: "1rem" }}></div>
        <AdsenseAdd />
      </MyContainer>
    </div>
  );
};
export default SingleTeam;
