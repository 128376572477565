import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { Player } from '../../types/types';
import PlayerCard from '../PlayerCard/PlayerCard';
import { RemovePlayer } from '../../reducers/ChosenPlayers';

export interface Props {
    players: Player[];
}

function SelectedPlayersCards({players}:Props) {
    const dispatch = useAppDispatch();

    function onPlusClick(player:Player) {
        dispatch(RemovePlayer(player.id));
    }

    return <div style={{display: "flex", overflow: "scroll", paddingBottom: "0.5rem", paddingLeft: "0.5rem"}}>
        {players.map(function(player) {
            return <PlayerCard player={player} onPlusClick={onPlusClick} onNavClick={e => console.log("nav")} width={"auto"} removeIcon={true} />
        })}
    </div>
}

export default SelectedPlayersCards