import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { RootState, AppThunk } from '../../app/store';
import api from '../app/api';
import { RootState } from '../app/store';
import { Team, Match, Player } from '../types/types';

export interface SearchState {
    searchterm: string;
    status: 'idle' | 'loading' | 'failed';
    results: {
        players: Player[];
    }
}

const initialState: SearchState = {
    searchterm: "",
    status: "idle",
    results: {
        players: []
    }
}

interface SearchAction {
    type: string;
    payload: {
        results: Player[];
    }
}

interface SearchPayload {
    players: Player[];
}

const FireSearch = (term: string) => {
    return new Promise<{data: SearchPayload}>((resolve) => {
        api.post('/search/', {term: term}).then(function(payload) {
            resolve(payload);
        })
    })
}

export const Search = createAsyncThunk(
    'search/Search',
    async (term: string) => {
        const response = await FireSearch(term);
        return response;
    }
)


export const SelectResults = (state: RootState ) => state.search.results;

export const SelectLoadingStatus = (state: RootState ) => state.search.status;

export const SearchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        Search: (state, action: SearchAction) =>{
            state.status = 'loading';
            state.results = {players: []};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(Search.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(Search.fulfilled, (state, action) => {
                state.status = "idle";
                state.results = action.payload.data
            })
    }
})

export default SearchSlice.reducer;