import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Game, LightPlayer, PlayerOwnershipPlayer } from '../../../types/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HealingIcon from '@mui/icons-material/Healing';
import { Tooltip } from '@mui/material';
import PlayerCard from '../../PlayerCard/PlayerCard';
import PlayerGameCard from '../../PlayerCard/PlayerGameCard';
import PlayerGameCard2 from '../../PlayerCard/PlayerGameCard2';
export interface Props {
    captain: {
        id: string;
        player: LightPlayer;
        played: string;
        game: Game;
    };
    vice: {
        id: string;
        player: LightPlayer;
        played: string;
        game: Game;
    };
    players: PlayerOwnershipPlayer[];
}

export default function FeaturedTeamPlayers({captain, vice, players}:Props){
    console.log("FeaturedTeamPlayers: ", players);
    const PlayersContainer = styled.div`
        display: grid;
        grid-template-rows; 200px 200px;
        grid-template-columns: 2fr repeat(${(Math.ceil((players.length-2) / 2))},170px);

        overflow-x: scroll;
        overflow-y: visible;
        grid-template-rows: auto auto;
        grid-auto-flow: column;

        row-gap: 0.5rem;
        column-gap: 0.5rem;
        padding-bottom: 1rem;
        .smallplayercard .playerImage {
            padding-bottom: 88% !important;
        }
        .playercard { 
            height: 100%;
        }
        padding-right: 1rem;
        padding-left: 0.5rem;
        padding-top: 1rem;

    `;
    let playedIndex = 0;
    return (<div>
        <div style={{display: "flex", width: "100%", overflowX: "scroll",overflowY: "visible", paddingTop: "0.5rem"}}>
            <PlayersContainer>
                <div style={{position: "relative"}}>
                    <Tooltip title={"Captain"}>
                        <img src={'/images/icons/crown-gold.svg'} style={{position: "absolute", top: 3, right: 4, width: 24, height: 24, zIndex: 1000}} ></img>
                    </Tooltip>
                    {captain.player ? <PlayerGameCard2
                        Game={captain.game} 
                        player={captain.player}
                        large
                    />
                    : null}
                    
                </div>
                <div style={{position: "relative"}}>
                    <Tooltip title={"Vice Captain"}>
                        <img src={'/images/icons/crown-silver.svg'} style={{position: "absolute", top: 3, right: 4, width: 24, height: 24, zIndex: 1000}} />
                    </Tooltip>
                    {vice.player ? <PlayerGameCard2
                        Game={vice.game}
                        player={vice.player}
                        large
                    />
                    : null }
                    
                </div>
            
            
            {players.map(function(playerOwnership, index) {
                if (playerOwnership.played !== "capt" && playerOwnership.played !== 'vice' && playerOwnership.game) {
                    playedIndex = playedIndex + 1;
                    let icon = <div></div>;
                    let tooltiptext = ''
                    if (playerOwnership.played === 'true') {
                        icon = <CheckCircleIcon sx={{color: "green", position: "absolute", top: 3, right: 17, width: 24, height: 24, zIndex: 1000}} />
                        tooltiptext = 'Started'
                    } else if (playerOwnership.played === 'false') {
                        icon = <CancelIcon sx={{color: "red", position: "absolute",top: 3, right: 17, width: 24, height: 24, zIndex: 1000}} />
                        tooltiptext = 'Bench';
                    } else if (playerOwnership.played === 'emerg') {
                        icon = <HealingIcon sx={{color: "yellow", position: "absolute",top: 3, right: 17, width: 24, height: 24, zIndex: 1000}} />
                        tooltiptext = 'Emergency';
                    }
                    
                    return <div className="smallplayercard" style={{position: "relative"}}>
                        <Tooltip title={tooltiptext}>{icon}</Tooltip>
                        <PlayerGameCard2
                            Game={playerOwnership.game}
                            player={playerOwnership.player}
                            
                        />
                    </div>
                }
            })}
            </PlayersContainer>
        </div>
    </div>
    )
}