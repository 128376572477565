import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import TeamCard from "../components/TeamCard";
import {
  getFront,
  selectPlayers,
  selectTeams,
  selectAVGBase,
  selectTopPlayers,
  selectLastMatches,
  selectTopLastRound,
  selectLoadingStatus,
  selectAllPlayersList,
  selectTopPlayerPositions,
  selectFeaturedTeam,
  GetMostPopular,
  selectMostViewed,
  selectMostOwned,
  getAllPlayers,
  getFrontSC,
  getPositionPlayers,
  selectLowBEPlayers,
} from "../reducers/FrontReducer";
import { Paper, Tab, Tabs, Typography, Box, Container } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SearchContainer from "../components/Search/SearchContainer";
import PlayerCard from "../components/PlayerCard/PlayerCard";
import { AddPlayer } from "../reducers/ChosenPlayers";
import styled from "styled-components";
import NavContainer from "../components/Navigation/NavContainer";
import SingleMatchCard from "../components/SingleMatch/SingleMatchCard";

import { Player, PlayerStats2 } from "../types/types";

import Loading from "../components/Loading";
import SearchAutoComplete from "../components/SearchAutoComplete";
import { RegisterPageView } from "../functions/Analytics";
import PositionContainer from "../components/Positions/PositionContainer";
import Header from "../components/FrontPage/Header";
import _ from "underscore";

import AdsenseAdd from "../components/AdsenseAdd";
import CarouselContainer from "../components/Containers/CarouselContainer";
import FeaturePlayerCard from "../components/PlayerCard/FeaturePlayerCard";
import { RoundedCTAContainer } from "../components/Containers/RoundedCTAContainer";
import RoundedCTA from "../components/CTA/RoundedCTA";
import { CompareArrows, PersonSearch } from "@mui/icons-material";
import PlayerCardSingleOwnership from "../components/PlayerCard/PlayerCardSingleOwnership";
import PlayerCardExtraStat from "../components/PlayerCard/PlayerCardExtraStat";
import {
  CURRENT_SEASON,
  CURRENT_SEASON_STRING,
  LAST_SEASON_STRING,
} from "../Globals/Globals";

const ListDiv = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    width: 49% !important;
  }
`;

const TabsContainer = styled(Box)`
  border-bottom: 1;
  border-color: divider;
  display: flex;
  justify-content: center;
`;

const FrontPage = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectLoadingStatus);
  const players = useAppSelector(selectPlayers);
  const teams = useAppSelector(selectTeams);

  const topPlayers = useAppSelector(selectTopPlayers);
  const lowbePlayers = useAppSelector(selectLowBEPlayers);
  const allplayers = useAppSelector(selectAllPlayersList);
  const topPlayerPositions = useAppSelector(selectTopPlayerPositions);
  let highAVGBase = useAppSelector(selectAVGBase);
  let lastmatches = useAppSelector(selectLastMatches);
  let toplastround = useAppSelector(selectTopLastRound);

  let mostOwned = useAppSelector(selectMostOwned);

  mostOwned = _.uniq(mostOwned, function (own) {
    return own.player.id;
  });

  const mostPopular = useAppSelector(selectMostViewed);
  const [selectedTab, setSelectedTab] = useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    dispatch(getFront());
    dispatch(getFrontSC());
    dispatch(getAllPlayers());
    dispatch(GetMostPopular());
  }, []);

  useEffect(() => {
    selectedTab === 3 && dispatch(getPositionPlayers());
  }, [selectedTab]);

  RegisterPageView({ path: "/" });
  if (status === "loading") {
    return <Loading />;
  }

  lastmatches = _.sortBy(lastmatches, function (match) {
    return new Date(match.date);
  });

  let avgBaseSort = [...highAVGBase];
  avgBaseSort?.sort((a, b) => {
    //@ts-ignore

    const stat = a.stats.filter(
      (stat) => stat.season === CURRENT_SEASON_STRING
    );
    const stat2 = b.stats.filter(
      (stat) => stat.season === CURRENT_SEASON_STRING
    );
    return stat2[0]?.avgbase - stat[0]?.avgbase;
  });

  toplastround = _.sortBy(toplastround, function (player) {
    // Going to sort by the top avg of last year
    // Sort by top score last game
    let lastgame = null;
    player.stats.map(function (stat) {
      if (stat.season === CURRENT_SEASON_STRING) {
        lastgame = stat.lastgame;
      }
    });
    if (lastgame) {
      return -lastgame.points;
    } else {
      console.log("Dont have last game for : ", player);
    }
  });

  const lowBeSorted = _.sortBy(lowbePlayers, function (lowbeplayer) {
    const lastStat = lowbeplayer?.stats?.find(
      (stat) => stat.season === CURRENT_SEASON_STRING
    );
    if (lastStat && lastStat.lastgame) {
      return lastStat.lastgame?.scbe;
    } else {
      return 1000;
    }
  });

  return (
    <Container maxWidth="lg" style={{ marginBottom: "2rem" }}>
      <NavContainer />
      <Header />
      {selectedTab !== 0 ? <SearchAutoComplete players={allplayers} /> : null}
      <TabsContainer>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          style={{ marginBottom: "1rem" }}
        >
          <Tab icon={<SearchIcon />} />
          <Tab label="Players" />
          <Tab label="Teams" />
          <Tab label="Positions" />
        </Tabs>
      </TabsContainer>

      {selectedTab === 1 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <CarouselContainer
            title={selectedTab.toString()}
            description={""}
            icon={""}
            hideTop={true}
            hideWhite={true}
          >
            {topPlayers.map(function (player: Player) {
              let lastSeasonStat: PlayerStats2;
              let thisSeasonStat: PlayerStats2;
              let theGame;
              let thisSeasonLastGame;
              let preseasonstat;
              player.stats.forEach(function (stat) {
                if (stat.season === LAST_SEASON_STRING) {
                  lastSeasonStat = stat;
                }
                if (stat.season === CURRENT_SEASON_STRING) {
                  thisSeasonStat = stat;
                }
                if (stat.season === "2024") {
                  preseasonstat = stat;
                }
              });
              if (lastSeasonStat) {
                theGame = lastSeasonStat.lastgame;
              }
              if (thisSeasonStat) {
                thisSeasonLastGame = thisSeasonStat.lastgame;
              }

              let thisYearAVG = "";
              if (thisSeasonLastGame) {
                thisYearAVG = (
                  thisSeasonLastGame.total_points /
                  thisSeasonLastGame.total_games
                ).toFixed(2);
              }

              if (thisSeasonLastGame) {
                return (
                  <FeaturePlayerCard
                    game={theGame}
                    player={player}
                    rightStats={[
                      {
                        label: "2024 AVG PTS",
                        valueField: (
                          <h2 style={{ marginBottom: "0", marginTop: 0 }}>
                            {thisYearAVG}
                          </h2>
                        ),
                      },
                      {
                        label: "BE",
                        valueField: (
                          <h2 style={{ marginBottom: "0", marginTop: 0 }}>
                            {preseasonstat
                              ? preseasonstat?.lastgame?.scbe
                              : thisSeasonLastGame.scbe}
                          </h2>
                        ),
                      },
                      {
                        label: "Ownership",
                        valueField: (
                          <h2 style={{ marginBottom: "0", marginTop: 0 }}>
                            {preseasonstat
                              ? preseasonstat.lastgame?.ownerShipPercentage
                              : thisSeasonLastGame.ownerShipPercentage}
                            %
                          </h2>
                        ),
                      },
                      {
                        label: "Price",
                        valueField: (
                          <h2 style={{ marginBottom: "0", marginTop: 0 }}>
                            {preseasonstat?.lastgame?.price
                              ? preseasonstat?.lastgame?.price.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }
                                )
                              : ""}
                            {/* {thisSeasonLastGame.price
                              ? thisSeasonLastGame.price.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }
                                )
                              : ""} */}
                          </h2>
                        ),
                      },
                    ]}
                  />
                );
              }
            })}
          </CarouselContainer>

          <RoundedCTAContainer>
            <RoundedCTA
              Icon={
                <img
                  src={"/images/icons/sclogo-blue.svg"}
                  style={{ width: 48, height: 48 }}
                />
              }
              link="/scteams"
              text="SC Teams"
              newTab={false}
            />
            <RoundedCTA
              Icon={
                <CompareArrows
                  style={{ width: 48, height: 48, color: "#21ace1" }}
                />
              }
              link="/compareplayers"
              text="Compare Players"
              newTab={false}
            />
            <RoundedCTA
              Icon={
                <PersonSearch
                  style={{ width: 48, height: 48, color: "#21ace1" }}
                />
              }
              link="/findplayers"
              text="Find A Player"
              newTab
            />
            <RoundedCTA
              Icon={
                <img
                  src={"/images/icons/Icon-Blue-Black.svg"}
                  style={{ width: 48, height: 48 }}
                />
              }
              link="https://scplaybook.com.au"
              text="SC News"
              newTab
            />
          </RoundedCTAContainer>
          {/* <div style={{ width: "100%" }}>
            <Paper style={{ padding: "1rem" }} elevation={5}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Most Popular
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "left" }}
              ></Typography>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    paddingBottom: "1rem",
                    columnGap: "0.5rem",
                    paddingTop: "1rem",
                  }}
                >
                  {topPlayers.map(function (player, index) {
                    return (
                      <PlayerCard
                        player={player}
                        onPlusClick={(player) => dispatch(AddPlayer(player))}
                        onNavClick={(player) => (
                          <Navigate to={`/player/${player.id}`} />
                        )}
                        width={"160px"}
                      />
                    );
                  })}
                </div>
              </div>
            </Paper>
          </div> */}

          <div style={{ width: "100%" }}>
            <Paper style={{ padding: "1rem" }} elevation={5}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Highest Owned Players
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "left" }}
              >
                Hot property 🔥 - Highest owned players in the top 1,000 teams
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  overflowX: "scroll",
                  paddingBottom: "1rem",
                  columnGap: "0.5rem",
                  paddingTop: "1rem",
                }}
              >
                {mostOwned.map(function (mostOwned, index) {
                  return (
                    <PlayerCardSingleOwnership
                      player={mostOwned.player as Player}
                      onPlusClick={(player) =>
                        dispatch(AddPlayer(player as Player))
                      }
                      onNavClick={(player) => (
                        <Navigate to={`/player/${mostOwned.player.id}`} />
                      )}
                      width={"160px"}
                      //@ts-ignore
                      ownershipPercentage={mostOwned.ownership}
                    />
                  );
                })}
              </div>
            </Paper>
          </div>
          <div style={{ width: "100%" }}>
            <Paper style={{ padding: "1rem" }} elevation={5}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Top scorers of 2024
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "left" }}
              >
                Best performers
              </Typography>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    paddingBottom: "1rem",
                    columnGap: "0.5rem",
                    paddingTop: "1rem",
                  }}
                >
                  {players.map(function (player, index) {
                    let total_points;

                    player.stats.map(function (stat) {
                      if (stat.season === CURRENT_SEASON_STRING) {
                        if (stat.lastgame) {
                          total_points = stat.lastgame?.total_points;
                        }
                      }
                    });

                    return (
                      <PlayerCardExtraStat
                        player={player}
                        onPlusClick={(player) => dispatch(AddPlayer(player))}
                        onNavClick={(player) => (
                          <Navigate to={`/player/${player.id}`} />
                        )}
                        width={"160px"}
                        extraStat={{
                          label: "Total",
                          value: total_points,
                          tooltip: "Total points this season",
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </Paper>
          </div>
          <div style={{ width: "100%" }}>
            <Paper style={{ padding: "1rem" }} elevation={5}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Money Makers 💵💵
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "left" }}
              >
                Lowest BE Players
              </Typography>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    paddingBottom: "1rem",
                    columnGap: "0.5rem",
                    paddingTop: "1rem",
                  }}
                >
                  {lowBeSorted.map(function (player, index) {
                    let scbe;

                    player.stats.map(function (stat) {
                      if (stat.season === CURRENT_SEASON_STRING) {
                        if (stat.lastgame) {
                          scbe = stat.lastgame?.total_points;
                        }
                      }
                    });

                    return (
                      <PlayerCardExtraStat
                        player={player}
                        onPlusClick={(player) => dispatch(AddPlayer(player))}
                        onNavClick={(player) => (
                          <Navigate to={`/player/${player.id}`} />
                        )}
                        width={"160px"}
                        extraStat={{
                          label: "Total",
                          value: scbe,
                          tooltip: "Total Points 2024",
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </Paper>
          </div>
          {/* <div style={{ width: "100%" }}>
            <Paper style={{ padding: "1rem" }} elevation={5}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Top Hitters{" "}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "left" }}
              >
                Last round's highest scorers
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  overflowX: "scroll",
                  paddingBottom: "1rem",
                  columnGap: "0.5rem",
                  paddingTop: "1rem",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
              >
                {toplastround.map(function (player: Player) {
                  let points_last_round;
                  let theirstat;
                  player.stats.map(function (stat) {
                    if (stat.season === "2023") {
                      if (stat.lastgame) {
                        points_last_round = stat.lastgame?.points;
                        theirstat = stat.lastgame;
                      }
                    }
                  });

                  return (
                    <PlayerCardExtraStat
                      player={player}
                      onPlusClick={(player) => dispatch(AddPlayer(player))}
                      onNavClick={(player) => (
                        <Navigate to={`/player/${player.id}`} />
                      )}
                      width={"160px"}
                      extraStat={{
                        label: "PTS",
                        value: points_last_round,
                        tooltip: "Points last round",
                      }}
                    />
                  );
                })}
              </div>
            </Paper>
          </div> */}

          <div style={{ width: "100%" }}>
            <Paper style={{ padding: "1rem" }} elevation={5}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Base Stat Beasts 2024{" "}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "left" }}
              >
                (Ave: runs + tackles - missed tackles)
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  overflowX: "scroll",
                  paddingBottom: "1rem",
                  columnGap: "0.5rem",
                  paddingTop: "1rem",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
              >
                {avgBaseSort.map(function (player: Player) {
                  let avgbase;
                  let theirstat;
                  player.stats.map(function (stat) {
                    if (stat.season === CURRENT_SEASON_STRING) {
                      if (stat.avgbase) {
                        avgbase = stat.avgbase;
                        theirstat = stat.lastgame;
                      }
                    }
                  });

                  return (
                    <PlayerCardExtraStat
                      player={player}
                      onPlusClick={(player) => dispatch(AddPlayer(player))}
                      onNavClick={(player) => (
                        <Navigate to={`/player/${player.id}`} />
                      )}
                      width={"160px"}
                      extraStat={{
                        label: "AVG Base",
                        value: avgbase,
                        tooltip: "Average Base Stats",
                      }}
                    />
                  );
                })}
              </div>
            </Paper>
          </div>

          {/* {placings && placings.length > 0 ? (
            <div style={{ width: "100%" }}>
              <Paper style={{ paddingTop: "1rem" }} elevation={5}>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: "left",
                    paddingLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Top Super Coach Teams
                </Typography>
                <PlacingsTable
                  placings={placings}
                  height={480}
                  showPrice={false}
                />
              </Paper>
            </div>
          ) : null} */}

          {/* {featuredTeam?.team ? (
            <FeaturedTeamContainer
              featuredTeam={featuredTeam.team}
              players={
                featuredTeam.ownerships.players
                  ? featuredTeam.ownerships.players
                  : []
              }
              placing={featuredTeam.placing}
            />
          ) : null} */}

          <div style={{ width: "100%", marginBottom: "1rem" }}>
            <Paper style={{ padding: "1rem" }} elevation={5}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ textAlign: "left" }}
              >
                Last Round
              </Typography>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  paddingBottom: "1rem",
                }}
              >
                {lastmatches.map(function (match) {
                  return <SingleMatchCard match={match} />;
                })}
              </div>
            </Paper>
          </div>
          <AdsenseAdd />
        </div>
      ) : null}
      {selectedTab === 2 ? (
        <div>
          <div style={{ display: "flex", flexWrap: "wrap", rowGap: "1rem" }}>
            {teams.map(function (team) {
              if (team) {
                if (team.name !== "Wests Tigers" && team.name !== "nan") {
                  let teamname = team.name ? team.name : "/";
                  return (
                    <Link to={`/team/${team.name}`}>
                      <TeamCard team={team} />
                    </Link>
                  );
                }
              }
            })}
          </div>
          <AdsenseAdd />
        </div>
      ) : null}

      {selectedTab === 3 ? (
        <div style={{ display: "flex", flexWrap: "wrap", rowGap: "1rem" }}>
          <PositionContainer positions={topPlayerPositions} />
          <AdsenseAdd />
        </div>
      ) : null}
      {selectedTab === 0 ? <SearchContainer /> : null}
    </Container>
  );
};
export default FrontPage;
