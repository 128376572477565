import React from 'react';
import { RankColors, RankText } from '../../Globals';

import { Chip, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Rank } from '../../types/types';

export interface Props {
    ranks: Rank[];
}

const RETTYPE:string = 'Table1';

function RanksTable({ranks}:Props) {
    if (ranks.length === 0) {
        return <div></div>
    }
    return (<Paper style={{display: "flex", flexDirection: "column", marginBottom: "1rem", padding: "1rem"}} elevation={5}> 
        <b style={{textAlign: "left"}}>Points Conceeded By Position</b>
        <div style={{width: "100%", overflowY:"scroll"}}>
        <Table>
            <TableHead>
                <TableRow>
                {ranks.map(function(rank) {
                    return (<TableCell align="center">
                        {rank.position.name}
                        </TableCell>)
                })}
                </TableRow>
            </TableHead>
            <TableBody>
                {ranks.map(function(rank) {
                        return (RETTYPE === 'Table' ? 
                            <TableCell align="center" style={{backgroundColor: RankColors[rank.rank]}}>
                            {RankText[rank.rank]}
                        </TableCell>
                        : <TableCell align="center"><Chip style={{backgroundColor: RankColors[rank.rank]}} label={RankText[rank.rank]}/></TableCell> )
                })}
            </TableBody>
        </Table>
        </div>
    </Paper>
    )
}

export default RanksTable;