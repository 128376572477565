import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//import { RootState, AppThunk } from '../../app/store';
import api from "../app/api";
import { RootState } from "../app/store";
import { Match, Game } from "../types/types";

export interface SingleMatchState {
  singlematch: {
    match: Match;
    games: Game[];
  };

  status: "idle" | "loading" | "failed";
}

const initialState: SingleMatchState = {
  singlematch: {
    match: {
      id: -1,
      season: -1,
      round: -1,
      teams: [],
      location: "",
      games: [],
      date: "",
      matchId: "",
      homescore: 0,
      awayscore: 0,
    },
    games: [],
  },

  status: "idle",
};

export interface GetMatchAction {
  type: String;

  payload: {
    match: Match;
    games: Game[];
  };
}

interface GetMatchPayload {
  match: Match;
  games: Game[];
}

const FetchSingleMatch = (matchid?: string) => {
  return new Promise<{ data: GetMatchPayload }>((resolve) => {
    api.get("/match/" + matchid).then(function (payload) {
      resolve(payload);
    });
  });
};

export const GetSingleMatch = createAsyncThunk(
  "match/GetSingleMatch",
  async (name?: string) => {
    const response = await FetchSingleMatch(name);
    return response;
  }
);

export const SelectMatch = (state: RootState) => state.singlematch.singlematch;

export const SelectSingleMatchLoadingStatus = (state: RootState) =>
  state.singlematch.status;

export const SingleMatchSlice = createSlice({
  name: "match",
  initialState,
  reducers: {
    GetSingleMatch: (state, action: GetMatchAction) => {
      state.status = "loading";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSingleMatch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetSingleMatch.fulfilled, (state, action) => {
        console.log("Single Match: ", action.payload.data);
        state.singlematch = action.payload.data;
        state.status = "idle";
      });
  },
});

export default SingleMatchSlice.reducer;
