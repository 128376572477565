import React, { useEffect, useState } from "react";
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
  GridEvents,
  MuiEvent,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { LightPlayer } from "../../types/types";
import { RenderPriceCell, RenderTeamCell } from "../../Globals/TableFunctions";
import InfoIcon from "@mui/icons-material/Info";
import RemoveIcon from "@mui/icons-material/Remove";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
  RemovePlayer,
  AddPlayer,
  AddPlayerByID,
} from "../../reducers/ChosenPlayers";
import PlayerDataGrid from "../PlayerDataGrid";
import { TeamsList, TeamLogos } from "../../Globals";
import { selectChosenPlayerIds } from "../../reducers/ChosenPlayers";
import AddIcon from "@mui/icons-material/Add";
import { CURRENT_SEASON_STRING } from "../../Globals/Globals";
export interface Props {
  players: LightPlayer[];
}

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 180 },
  {
    field: "team",
    headerName: "Team",
    width: 160,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderTeamCell(
        params.row.teamname,
        params.row.teamlogo,
        params.row.teamcolors
      ),
  },
  { field: "position", headerName: "Position", width: 300 },
  {
    field: "price",
    headerName: "Price",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderPriceCell(params.row.price, params.row.pricechange),
  },
  { field: "be", headerName: "BE", type: "number" },
  { field: "avgpts", headerName: "Avg Pts", type: "number" },
  { field: "avgbase", headerName: "Avg Base", type: "number" },
  //{ field: 'ppm', headerName: 'PPM', type: "number" },
  //{field: "three", headerName: "3rd Avg", type: "number"},
  //{field: "five", headerName: "5rd Avg", type: "number"},
  {
    field: "removebutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) =>
      params.row.playerChosen ? <RemoveIcon /> : <AddIcon />,
  },
  {
    field: "viewbutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => <InfoIcon />,
  },
];

const FindPlayersTable = ({ players }: Props) => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  let chosenPlayerids = useAppSelector(selectChosenPlayerIds);
  const [rows, setRows] = useState<object[]>([]);
  const [height, setHeight] = useState("500px");

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (params.field !== "removebutton") {
      if (params.field === "name" || params.field === "viewbutton") {
        navigate("/player/" + params.row.id);
      }
    } else {
      if (chosenPlayerids.includes(params.row.id)) {
        dispatch(RemovePlayer(params.row.id));
      } else {
        dispatch(AddPlayerByID(params.row.id));
      }
      PopulateRows();
    }
  };

  const PopulateRows = () => {
    var newrows: object[] = [];
    players.map(function (player, index) {
      if (player.stats?.length > 0) {
        const thestat = player.stats
          .filter((stat) => stat.season === CURRENT_SEASON_STRING)
          .pop();
        const lastgame = thestat.lastgame;
        if (lastgame) {
          let increase = lastgame.price_change > -1 ? true : false;
          let teamname = lastgame.team.name ? lastgame.team.name : "";
          let teamcolors: string[] = TeamsList[teamname];
          if (!teamcolors) {
            teamcolors = ["", ""];
          }
          let position = player.position.map(function (position) {
            return position.name;
          });
          let positionnames = position.join(", ");
          let pricechange = lastgame.price_change ? lastgame.price_change : 0;
          let playerChosen = chosenPlayerids.includes(player.id);
          newrows.push({
            id: player.id,
            img: player.pic,
            name: `${player.firstname} ${player.lastname}`,
            team: teamname,
            avgpts: thestat.avgpts,
            avgbase: thestat.avgbase,
            teamlogo: TeamLogos[teamname],
            priceincrease: increase,
            teamname: teamname,
            teamcolors: teamcolors,
            be: lastgame.scbe,
            price: lastgame.price,
            position: positionnames,
            pricechange: pricechange,

            playerChosen: playerChosen,
          });
        }
      }
    });
    setRows(newrows);
  };
  useEffect(() => {
    PopulateRows();
  }, [players]);

  return (
    <PlayerDataGrid
      height={height}
      columns={columns}
      rows={rows}
      onCellClick={onCellClick}
    />
  );
};

export default FindPlayersTable;
