import React, { useEffect } from "react";
import { Team, Match, Game } from "../../types/types";
import { TeamsList, TeamLogos, MyContainer } from "../../Globals";
import { Card, Paper, CardContent, Typography, Container } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import UpcomingGameCard from "../UpcomingGameCard";
import styled from "@emotion/styled";

interface Props {
  team: Team;
  upcoming: Match[];
  lastmatch: Match;
}

const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    img {
      height: 10rem !important;
      width: 10rem !important;
      margin-right: 0 !important;
    }
  }
`;

const CardsWrapperContainer = styled.div`
  display: flex;
  margin-left: 2rem;
  overflow: hidden;
  @media (max-width: 800px) {
    margin-left: 0;
  }
`;

function SingleTeamHeader({ team, upcoming, lastmatch }: Props) {
  let teamname = team.name ? team.name : "";
  if (team.name === "Wests Tigers") {
    teamname = "Tigers";
  }
  var colors = TeamsList[teamname];
  if (!colors) {
    colors = ["#262626", "#161616"];
  }
  const TeamHeaderContianer = styled(MyContainer)`
    display: flex;
    align-items: center;
    padding: 1rem;
    background: linear-gradient(
      120deg,
      ${colors[0]} 0%,
      ${colors[0]} 50%,
      ${colors[1]} 50%
    );
    margin-bottom: 1rem;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
  `;

  let lastopponentname: string = "";
  lastmatch?.teams?.map(function (team) {
    if (team.name !== teamname) {
      lastopponentname = team.name ? team.name : "";
    }
  });
  let lastopponentcolors = TeamsList[lastopponentname];
  if (!lastopponentcolors) {
    lastopponentcolors = ["#262626", "#161616"];
  }

  let nextopponentname: string = "";
  let nextround: number = 0;
  if (upcoming.length > 0) {
    // Last game in the upcoming
    let nextgame: Match = upcoming[0];
    nextround = nextgame.round;
    nextgame.teams.map(function (team) {
      if (team.name !== teamname) {
        nextopponentname = team.name ? team.name : "";
      }
    });
  }
  console.log("SINGLE TEAM HEADER: ", { upcoming, team, lastmatch });

  return (
    <TeamHeaderContianer maxWidth="xl">
      <TeamContainer>
        <img
          style={{ width: "50px", height: "50px", marginRight: "1rem" }}
          src={TeamLogos[teamname]}
          alt={team.name}
        />

        <b style={{ color: "white" }}>{team.name}</b>
      </TeamContainer>
      <CardsWrapperContainer>
        <Card
          style={{
            overflow: "visible",
            background: `linear-gradient(120deg, ${lastopponentcolors[0]} 0%, ${lastopponentcolors[0]} 50%, ${lastopponentcolors[1]} 50%)`,
          }}
        >
          <Link
            to={`/match/${lastmatch.id}`}
            style={{ color: "white", textDecoration: "none" }}
          >
            <CardContent
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                display: "flex",
                paddingBottom: 12,
              }}
              style={{ paddingBottom: 12 }}
            >
              <Typography
                sx={{ fontSize: 12, textDecoration: "none" }}
                color="white"
                gutterBottom
              >
                Last Game
              </Typography>
              <img
                src={TeamLogos[lastopponentname]}
                style={{ width: "60px", height: "60px", margin: "auto" }}
              />
              <Typography
                sx={{ fontSize: 14, marginBottom: 0, whiteSpace: "nowrap" }}
                color="white"
                gutterBottom
              >
                Round: {lastmatch.round}
              </Typography>
            </CardContent>
          </Link>
        </Card>
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              overflow: "scroll",
              width: "100%",
              height: "100%",
            }}
          >
            {upcoming.map(function (upcomingMatch) {
              return (
                <UpcomingGameCard
                  match={upcomingMatch}
                  thisteamname={teamname}
                />
              );
            })}
          </div>
        </div>
      </CardsWrapperContainer>
    </TeamHeaderContianer>
  );
}

export default SingleTeamHeader;
