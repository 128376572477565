import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetSinglePlayer,
  selectSinglePlayer,
  selectLoadingStatus,
} from "../reducers/FrontReducer";

import { useAppDispatch, useAppSelector } from "../app/hooks";

import _ from "underscore";

import NavContainer from "../components/Navigation/NavContainer";

import Loading from "../components/Loading";
import { RegisterPageView } from "../functions/Analytics";
import SinglePlayerScreenContent from "../components/PlayerScreen/SinglePlayerScreenContent";
import { getAll, selectAllPlayers } from "../reducers/FindPlayers";
import FloatingMenu from "../components/PlayerScreen/FloatingMenu";

const SinglePlayerScreen = () => {
  const dispatch = useAppDispatch();
  const allplayers = useAppSelector(selectAllPlayers);
  let { id } = useParams<{ id: string }>();
  const [playerId, setPlayerId] = useState<string>("130");
  let spd = useAppSelector(selectSinglePlayer);
  let loadingstatus = useAppSelector(selectLoadingStatus);

  useEffect(() => {
    dispatch(GetSinglePlayer(playerId));
    RegisterPageView({ path: `/playerscreen/${id}` });
  }, [playerId]);

  useEffect(() => {
    dispatch(getAll());
  }, []);

  return (
    <div>
      <NavContainer />

      <FloatingMenu
        selectedPlayer={playerId}
        setSelectedPlayer={setPlayerId}
        players={allplayers}
      />
      {loadingstatus !== "loading" ? (
        <>
          <SinglePlayerScreenContent
            singlePlayerData={spd}
            playerid={playerId}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default SinglePlayerScreen;
