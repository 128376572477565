import React, { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectSnackbarMessage, selectSnackbarOpen, SetSnackBarOpen } from '../reducers/UserSettingsReducer';

function AppSnackbar() {
    const dispatch = useAppDispatch();

    const snackbarmessage = useAppSelector(selectSnackbarMessage);
    const snackbaropen = useAppSelector(selectSnackbarOpen);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        dispatch(SetSnackBarOpen(false));
      };
    return (
        <Snackbar
            open={snackbaropen}
            autoHideDuration={5000}
            onClose={handleClose}
            message={snackbarmessage}
        />
    )
}   

export default AppSnackbar;