import React, { useEffect, useState } from "react";
import { Player } from "../../types/types";
import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { useNavigate } from "react-router-dom";
import PlayerDataGrid from "../PlayerDataGrid";
import { selectMagicNumber } from "../../reducers/UserSettingsReducer";
import { AddPlayer } from "../../reducers/ChosenPlayers";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
  GridEvents,
  MuiEvent,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { TeamLogos, TeamsList } from "../../Globals";
import { CURRENT_SEASON_STRING } from "../../Globals/Globals";

export interface Props {
  results: {
    players: Player[];
  };
  nonav?: boolean;
}

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 180 },
  {
    field: "team",
    headerName: "Team",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "38px",
          alignItems: "center",
          background: `linear-gradient(120deg, ${params.row.teamcolors[0]} 0%, ${params.row.teamcolors[0]} 50%, ${params.row.teamcolors[1]} 50%)`,
          color: "white",
        }}
      >
        <img
          style={{ height: "1.5rem", width: "2rem" }}
          src={params.row.teamlogo}
          alt={params.row.team}
        />
        <p>{params.row.team}</p>
      </div>
    ),
  },
  {
    field: "price",
    headerName: "Price",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <span>{params.row.price?.slice(0, -3)}</span>
        <span style={{ color: params.row.pricechange > -1 ? "green" : "red" }}>
          (
          {params.row.pricechange.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          )
        </span>
      </div>
    ),
  },
  { field: "scbe", headerName: "BE", type: "number" },
  { field: "avgpts", headerName: "Avg Pts", type: "number" },
  { field: "avgbase", headerName: "Avg Base", type: "number" },
  { field: "ppm", headerName: "PPM", type: "number" },
  { field: "three", headerName: "3rd Ave", type: "number" },
  { field: "five", headerName: "5rd Ave", type: "number" },
  {
    field: "addbutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => <AddIcon />,
  },
];

function SearchResultsTable({ results, nonav = false }: Props) {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  let magicnumber = useAppSelector(selectMagicNumber);
  const [rows, setRows] = useState<object[]>([]);

  const onRowClick = (grid: GridRowParams) => {
    //navigate('/player/'+grid.row.id);
    return 1;
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (
      params.field !== "addbutton" &&
      params.field !== "navigatebutton" &&
      !nonav
    ) {
      navigate("/player/" + params.row.id);
    } else {
      if (params.field === "addbutton" || nonav) {
        let player: Player = {
          id: "",
          firstname: "",
          lastname: "",
          pic: "",
          position: { name: "" },
          stats: [
            {
              last5Games: [],
              timestamp: "",
              avgpts: 0,
              avgbase: 0,
              ppm: 0,
              season: "",
              lastgame: null,
            },
          ],
          positionrank: [],
          ownerships: [],
        };
        results.players.map(function (p) {
          if (p.id === params.row.id) {
            player = p;
          }
        });
        dispatch(AddPlayer(player));
      }
    }
  };

  useEffect(() => {
    PopulateRows();
  }, [results]);

  const PopulateRows = () => {
    var newrows: object[] = [];
    results.players.map(function (player) {
      if (player) {
        const thisSeasonStat = player.stats
          ?.filter((stat) => stat.season === CURRENT_SEASON_STRING)
          .pop();
        if (thisSeasonStat) {
          var playerstats = CalculatePlayerStats({
            games: thisSeasonStat.last5Games,
            magicnumber: magicnumber,
          });
          let increase: boolean =
            thisSeasonStat?.last5Games[4]?.price_change > 0;
          let teamname = thisSeasonStat.last5Games[0]?.team?.name
            ? thisSeasonStat.last5Games[0]?.team?.name
            : "";
          let pricechange = thisSeasonStat?.last5Games[4]?.price_change
            ? thisSeasonStat?.last5Games[4]?.price_change
            : 0;
          let teamcolors: string[] = TeamsList[teamname];
          if (!teamcolors) {
            teamcolors = ["", ""];
          }
          newrows.push({
            id: player.id,
            img: "https://www.nrlsupercoachstats.com/highcharts/PlayerPictures/2021/Trbojevic,%20Tom.png",
            name: `${player.firstname} ${player.lastname}`,
            team: thisSeasonStat.last5Games[0]?.team?.name,
            three: playerstats.three,
            five: playerstats.five,
            avgpts: thisSeasonStat.avgpts,
            avgbase: thisSeasonStat.avgbase,
            teamlogo: TeamLogos[thisSeasonStat.last5Games[0]?.team?.name],
            priceincrease: increase,
            teamcolors: teamcolors,
            pricechange: pricechange,
            ...playerstats,
          });
        }
      }
    });

    setRows(newrows);
  };
  if (results.players.length > 0) {
    return (
      <PlayerDataGrid
        height={"90vh"}
        rows={rows}
        columns={columns}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
      />
    );
  } else {
    return <div></div>;
  }
}

export default SearchResultsTable;
