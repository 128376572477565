import React, { useEffect, useState } from "react";
import { ReactComponent as Smalllogo } from "../Images/Logo-Blue-Black.svg";

const Header = () => {
  return (
    <div>
      <Smalllogo
        style={{
          width: "300px",
          height: "70px",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      />
    </div>
  );
};

export default Header;
