import React, { useEffect } from 'react';
import { Game } from '../../types/types';
import styled from 'styled-components';
import { get_suffix } from '../../functions/Ordinal';
import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import _ from 'underscore';

export interface Props {
    game: Game;
}

const PositionChip = styled(Chip)`
    background-color: white;
    margin-top: 0.5rem;
    cursor: pointer;
    .MuiChip-deleteIcon {
        padding-right: 0.5rem;
        @media (min-width: 800px) {
            line-height: 21.6px !important;
        }
        
    }
    &:hover {
        background-color: white;
    }
`

const PosRank = ({game}:Props) => {
    const navigate = useNavigate();
    if (!game) {
        return null
    }
    let posranks = _.uniq(game?.positionrank,function(posrank) {
        return posrank.position.name;
    })
    return <div>
        {posranks.map(function(rank) {
            return <PositionChip label={rank.position.name} deleteIcon={<p>{get_suffix(rank.totalrank)}</p>} onClick={e => navigate(`/position/${rank.position.name}`)} onDelete={e => navigate(`/position/${rank.position.name}`)}/>
        })}
    </div>
}

export default PosRank;