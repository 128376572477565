import React, { useEffect, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText, Collapse, List, Icon } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

//type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;


export interface Props {
    items: Array<React.ReactNode>;
    headingText: string;
    icon: React.ReactNode;
}

function ExpandableList({items, headingText, icon}:Props) {
    const [ open, setOpen ] = useState(false);
    
    return <ListItem style={{flexWrap: "wrap", cursor: "pointer"}} onClick={e => setOpen(!open)}>
        <ListItemIcon onClick={e => setOpen(true)}>
            {icon}
        </ListItemIcon>
        <ListItemText primary={headingText} />
        {open ? <ExpandLess onClick={e => setOpen(false)}/> : <ExpandMore onClick={e => setOpen(true)}/>}
        <Collapse in={open} timeout="auto" unmountOnExit style={{width: "100%"}}>
            <List component="div" disablePadding>
                {items}
            </List>
        </Collapse>
    </ListItem>
}

export default ExpandableList;