import React, { useEffect, useState, useRef } from "react";
import { Player } from "../../types/types";
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
  GridEvents,
  MuiEvent,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import RemoveIcon from "@mui/icons-material/Remove";
import { selectMagicNumber } from "../../reducers/UserSettingsReducer";
import { useNavigate } from "react-router-dom";
import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { RemovePlayer } from "../../reducers/ChosenPlayers";
import PlayerDataGrid from "../PlayerDataGrid";
import AddIcon from "@mui/icons-material/Add";
import { AddPlayer } from "../../reducers/ChosenPlayers";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { CURRENT_SEASON_STRING } from "../../Globals/Globals";

export interface Props {
  players: Player[];
}

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 180 },
  {
    field: "price",
    headerName: "Price",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <span>
          {params.row.price?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          ,
        </span>
        <span style={{ color: params.row.price_change > -1 ? "green" : "red" }}>
          (
          {params.row.price_change.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
          )
        </span>
      </div>
    ),
  },
  { field: "scbe", headerName: "BE", type: "number" },
  { field: "avgpts", headerName: "Avg Points", type: "number" },
  { field: "avgbase", headerName: "Avg Base", type: "number" },
  { field: "ppm", headerName: "PPM", type: "number" },
  { field: "three", headerName: "3rd Avg", type: "number" },
  { field: "five", headerName: "5rd Avg", type: "number" },
  {
    field: "addbutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => <AddIcon />,
  },
];

function SingleTeamTable({ players }: Props) {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  let magicnumber = useAppSelector(selectMagicNumber);
  const [rows, setRows] = useState<object[]>([]);

  const onRowClick = (grid: GridRowParams) => {
    //navigate('/player/'+grid.row.id);
    return 1;
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (params.field !== "addbutton") {
      navigate("/player/" + params.row.id);
    } else {
      let player: Player = {
        id: "",
        firstname: "",
        pic: "",
        lastname: "",
        position: { name: "" },
        stats: [
          {
            last5Games: [],
            timestamp: "",
            avgpts: 0,
            avgbase: 0,
            ppm: 0,
            season: "",
            lastgame: null,
          },
        ],
        positionrank: [],
        ownerships: [],
      };
      players.map(function (p) {
        if (p.id === params.row.id) {
          player = p;
        }
      });
      dispatch(AddPlayer(player));
    }
  };

  useEffect(() => {
    PopulateRows();
  }, [players]);

  const PopulateRows = () => {
    var newrows: object[] = [];
    players.map(function (player) {
      if (player) {
        if (player.stats[0].last5Games) {
          let thisStat;
          player.stats.map(function (stat) {
            if (stat.season === CURRENT_SEASON_STRING) {
              thisStat = stat;
            }
          });
          let lastGame;
          if (thisStat) {
            if (thisStat.lastgame) {
              lastGame = thisStat.lastgame;
            }
          }
          var playerstats = CalculatePlayerStats({
            games: thisStat?.last5Games,
            magicnumber: magicnumber,
          });
          //var position = player.stats[0].last5Games[0].position;

          let increase: boolean = lastGame
            ? lastGame.price_change > 0
            : thisStat?.last5Games[4]?.price_change > 0;
          let price_change = lastGame
            ? lastGame.price_change
            : player.stats[0]?.last5Games[4]?.price_change;
          let ppm = 0;
          ppm = lastGame?.total_points / lastGame?.total_minutes_played;
          newrows.push({
            id: player.id,
            img: "https://www.nrlsupercoachstats.com/highcharts/PlayerPictures/2021/Trbojevic,%20Tom.png",
            name: `${player.firstname} ${player.lastname}`,
            opponent: thisStat?.lastgame?.opposition?.name,
            position: thisStat?.lastgame?.position?.name,
            three: playerstats.three,
            five: playerstats.five,
            avgpts: thisStat?.avgpts,
            avgbase: thisStat?.avgbase,
            priceincrease: increase,
            price_change: price_change,
            ppm,
            ...thisStat?.lastgame,
          });
        }
      }
    });
    setRows(newrows);
  };

  return (
    <PlayerDataGrid
      height={"90vh"}
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
    />
  );
}

export default SingleTeamTable;
