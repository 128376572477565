import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getSingleSCTeam, selectSingleSCTeam } from '../reducers/SCTeams';
import NavContainer from '../components/Navigation/NavContainer';
import { MyContainer } from '../Globals';
import PlayerOwnershipContainer from '../components/SingleSCTeam/PlayerOwnerShipContainer';
import RankGraph from '../components/SingleSCTeam/RankGraph';
import AdsenseAdd from '../components/AdsenseAdd';
//import SCTeamHeader from '../components/SingleSCTeam/SCTeamHeader';
import SCTeamHeader from '../components/SCTeams/FeaturedTeam/SCTeamHeader';
import Header from '../components/Header/Header';
import { Placing, PlayerOwnership } from '../types/types';
import _ from 'underscore';
import { Paper } from '@mui/material';

const SingleSCTeam = () => {
    const dispatch = useAppDispatch();
    let { teamid } = useParams<{teamid: string}>();
    const singleteam = useAppSelector(selectSingleSCTeam);
    const playerownerships = singleteam?.playerOwnerships? singleteam.playerOwnerships : [];
    const placings = singleteam?.placings? singleteam?.placings : [];
    let totalrank = 0;
    let rdrank = 0;
    
    let sortedPlacings = _.sortBy(placings,function(placing:Placing) {
        return -placing.round;
    })
    let lastplacing = sortedPlacings[0];  
    
    
    if (placings.length > 1) {
        totalrank = lastplacing.position;
        rdrank = lastplacing.round_position;
    }

    useEffect(() => {
        dispatch(getSingleSCTeam(teamid? teamid : ''));
    },[teamid]);

    useEffect(() => {}, [singleteam]);
    let sortedplayerOwnerships = _.sortBy(playerownerships, function(po) {
        return -po.round;
    });
    let lastPlayerOwnership = sortedplayerOwnerships[0];

    return <div>
        <NavContainer />
        <Header />

        <MyContainer maxWidth="xl" style={{paddingTop: '1rem', display: "flex", flexDirection: "column", rowGap: "1rem"}}>
            <Paper style={{padding: "1rem"}}>
                <SCTeamHeader placing={lastplacing} lastPlayerOwnership={lastPlayerOwnership as PlayerOwnership}/>
            </Paper>
            <RankGraph placings={singleteam?.placings ? singleteam.placings : []} /> 
            
            <PlayerOwnershipContainer 
                PlayerOwnerships={playerownerships}
                theTeam={singleteam?.team ? singleteam.team : {teamid: '-1', name: ''}}
            />
            <AdsenseAdd />
        </MyContainer>
    </div>
}

export default SingleSCTeam;