import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
  Paper,
  Divider,
  Collapse,
  ListItemButton,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import styled from "styled-components";
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { selectTeams, selectPositions } from "../../reducers/FrontReducer";
import { TeamLogos } from "../../Globals";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ExpandableListItem from "./ExpandableList";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import BuildIcon from "@mui/icons-material/Build";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
export interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TEAMS = [
  { name: "Broncos" },
  { name: "Bulldogs" },
  { name: "Cowboys" },
  { name: "Dragons" },
  { name: "Dolphins" },
  { name: "Eels" },
  { name: "Knights" },
  { name: "Panthers" },
  { name: "Rabbitohs" },
  { name: "Raiders" },

  { name: "Roosters" },
  { name: "Sea Eagles" },
  { name: "Sharks" },
  { name: "Storm" },
  { name: "Tigers" },
  { name: "Titans" },
  { name: "Warriors" },
];

const POSITIONS = [
  { name: "2RF" },
  { name: "CTW" },
  { name: "Five-Eighth" },
  { name: "Fullback" },
  { name: "Halfback" },
  { name: "Hooker" },
  { name: "Prop" },
];

const TOOLS = [{ url: "VCLoopHole", name: "VC Loop Calculator" }];

const MyDrawer = styled(Drawer)`
  height: 100%;
  .MuiPaper-root {
    height: 100%;
    padding: 1rem;
    background-color: rgb(13, 21, 37);
    color: white;
    width: 300px;
  }
`;
const MyDivider = styled(Divider)`
  background-color: white;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const mypaper = <Paper style={{ height: "100%" }} />;

function NavDrawer({ open, setOpen }: Props) {
  let teams = useAppSelector(selectTeams);
  let positions = useAppSelector(selectPositions);

  const navigate = useNavigate();
  const [teamsOpen, setTeamsOpen] = useState(false);

  if (teams.length === 0) {
    teams = TEAMS;
  }
  if (positions.length === 0) {
    positions = POSITIONS;
  }

  let teamitems = teams.map(function (team) {
    let teamname: string = team.name ? team.name : "";
    if (team.name !== "nan") {
      return (
        <ListItem onClick={(e) => navigate("/team/" + team.name)}>
          <ListItemAvatar>
            <Avatar alt={teamname} src={TeamLogos[teamname]} />
          </ListItemAvatar>
          <ListItemText primary={team.name} />
        </ListItem>
      );
    }
  });

  let positionitems: React.ReactNode[] = positions.map(function (position) {
    if (position.name !== "Interchange") {
      return (
        <ListItem onClick={(e) => navigate("/position/" + position.name)}>
          <ListItemText primary={position.name} />
        </ListItem>
      );
    }
  });

  let toolitems: React.ReactNode[] = TOOLS.map(function (tool) {
    return (
      <ListItem onClick={(e) => navigate("/tools/" + tool.url)}>
        <ListItemText primary={tool.name} />
      </ListItem>
    );
  });

  return (
    <MyDrawer
      open={open}
      onClose={(e) => setOpen(!open)}
      style={{ height: "100%" }}
      sx={{ height: "100%" }}
      PaperProps={mypaper}
      className="navdrawer"
    >
      <Box
        //role="presentation"
        style={{ height: "100%" }}
        sx={{
          height: "100%",
          overflowX: "scroll",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundImage: `url('/images/icons/Logo-Blue-White.svg')`,
            backgroundSize: "contain",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            height: "3rem",
            width: "100%",
            marginBottom: "1rem",
            paddingBottom: "3rem",
          }}
        ></div>
        <List>
          <ListItem onClick={(e) => navigate("/")}>
            <ListItemIcon>
              <HomeIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <MyDivider />
          <ListItem onClick={(e) => navigate("/search")}>
            <ListItemIcon>
              <SearchIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Search" />
          </ListItem>
          <MyDivider />
          <ExpandableListItem
            items={teamitems}
            headingText="Teams"
            icon={<GroupsIcon style={{ color: "white" }} />}
          />
          <MyDivider />

          <ExpandableListItem
            items={positionitems}
            headingText="Positions"
            icon={<SportsHandballIcon style={{ color: "white" }} />}
          />
          <MyDivider />

          <ListItem onClick={(e) => navigate("/compareplayers")}>
            <ListItemIcon>
              <CompareArrowsIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Compare Players" />
          </ListItem>
          <MyDivider />
          <ExpandableListItem
            items={toolitems}
            headingText="Tools"
            icon={<BuildIcon style={{ color: "white" }} />}
          />
          <MyDivider />

          {/*<ListItem onClick={(e) => navigate("/scteams")}>
            <ListItemIcon>
              <LocalPoliceIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Super Coach Teams" />
        </ListItem> */}
        </List>
        <div style={{ marginTop: "auto" }}>
          <b>Sponsored By</b>
          <div
            style={{
              backgroundImage: `url('https://scplaybook.com.au/wp-content/themes/scpb_2022-04-14_12-27/dist/assets/images/mortgagechoicename2_1.svg')`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              height: "3rem",
              width: "100%",
              marginTop: "1rem",
            }}
          ></div>
        </div>
      </Box>
    </MyDrawer>
  );
}

export default NavDrawer;
