import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import AdsenseAdd from '../components/AdsenseAdd';
import NavContainer from '../components/Navigation/NavContainer';
import PlacingsTable from '../components/SCTeams/PlacingsTable';
import SCTeamsTableContianer from '../components/SCTeams/SCTeamsTableContainer';
import { MyContainer } from '../Globals';
import { selectPlacings, selectLoading, getPlacings, selectDiffs, selectDiffsTop1000, selectFeaturedTeam } from '../reducers/SCTeams';
import styled from '@emotion/styled';
import Header from '../components/Header/Header';
import FeaturedTeamContainer from '../components/SCTeams/FeaturedTeam';
import Loading from '../components/Loading';

const TableContainer = styled('div')`
    @media (max-width: 1024px) {
        flex-wrap: wrap;
        .MuiPaper-elevation5 {
            width: 100% !important;
        }
    }
`;


const SCTeams = () => {
    const dispatch = useAppDispatch();
    let status = useAppSelector(selectLoading);
    let placings = useAppSelector(selectPlacings);
    let diffs = useAppSelector(selectDiffs);
    let topDiffs = useAppSelector(selectDiffsTop1000);
    let featuredTeam = useAppSelector(selectFeaturedTeam);
    useEffect(() => {
        dispatch(getPlacings());
    },[]);
    
    return <div>
        <NavContainer />
        <Header />
        {status === 'loading' && <Loading />}
        {status !== 'loading' && <MyContainer maxWidth="xl" style={{paddingTop: "1rem", display :"flex", flexDirection: "column", rowGap: "1rem"}}>
            <h1>Super Coach Teams</h1>
            <FeaturedTeamContainer featuredTeam={featuredTeam?.team} players={featuredTeam?.ownerships?.players ? featuredTeam.ownerships.players : []} placing={featuredTeam?.placing} />
            <PlacingsTable placings={placings} showPrice={true} />
            <AdsenseAdd />
            <TableContainer style={{display: "flex",columnGap: "1rem"}}>
                <SCTeamsTableContianer diffs={diffs} title="Biggest Changers" width={"50%"}/>
                <SCTeamsTableContianer diffs={topDiffs} title="Biggest Changers (Top 1k Teams)" width={"50%"}/>
            </TableContainer>
            
        </MyContainer>
        }
    </div>
}

export default SCTeams;