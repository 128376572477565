import React, { useEffect, useState } from 'react';
import { GridColDef, GridCellParams, GridRowParams, GridEvents, MuiEvent, GridCallbackDetails, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { PlayerOwnership } from '../../types/types';
import PlayerDataGrid from '../PlayerDataGrid';
import { TeamLogos, TeamsList } from '../../Globals';
import { RenderPriceCell, RenderTeamCell, RenderTeamCellToolTip, RenderRankCell } from '../../Globals/TableFunctions';

export interface Props {
    PlayerOwnership: PlayerOwnership;
}

const PLAYED_TRANSLATION = {
    'true': 'Played',
    'false': "Benched",
    'capt': "Captain",
    'vice': "Vice",
    'emerg': "Emergency"
}

const columns:GridColDef[]  = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'played', headerName: 'Played', width: 100 },
    { field: 'opposition', headerName: 'Opponent', width: 140,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderTeamCellToolTip(params.row.opposition, params.row.oppositionlogo, params.row.oppositioncolors,params.row.game))
    },
    { field: 'price', headerName: 'Price' , type: "number", width: 180, 
        renderCell: (params: GridRenderCellParams<Date>) => (RenderPriceCell(params.row.price, params.row.price_change)
        
    ), 
    sortComparator: (v1, v2) => { return 1}
    },
    { field: 'points', headerName: 'Points' , type: "number" },
    { field: 'scbe', headerName: 'BE' },
    { field: 'position', headerName: 'Position' },
    { field: "posrdrank", headerName: "Pos RD Rnk"},
    { field: "postotalrank", headerName: "Pos Total Rnk"},
    { field: 'minutes_played', headerName: 'Minutes' , type: "number" },
    { field: 'ppm', headerName: 'PPM' , type: "number" },
    { field: 'base_points', headerName: 'Base' , type: "number" },
    { field: "power_points", headerName: "Power Pts", type: "number"},
    { field: 'create_points', headerName: 'Assist' , type: "number" },
    { field: 'negative_play_points', headerName: 'Negative Play' , type: "number" },
    { field: 'scoring_points', headerName: 'Scoring' , type: "number" },
    { field: 'evade_points', headerName: 'Attack' , type: "number" },
    { field: 'tackles', headerName: 'Tackles' , type: "number" },
    { field: 'missed_tackles', headerName: 'Missed Tackles' , type: "number" },
    { field: 'tries', headerName: 'Tries' , type: "number" },
    { field: 'hit_upso8', headerName: 'H8' , type: "number" },
    { field: 'hit_ups', headerName: 'HU' , type: "number" },
    { field: 'errors', headerName: 'Errors' , type: "number" },
]


function PlayerOwnershipsTable({PlayerOwnership}:Props) {
    const [ rows, setRows ] = useState<object[]>([]);
    
    
    const PopulateRows = () => {
        let newrows:object[] = [];
        if (PlayerOwnership) {

        
        PlayerOwnership.players.map(function(playerplay) {
            let thegame = playerplay.game;
            if (!thegame) {
                if (!playerplay.player) {

                } else {
                    
                    newrows.push({
                        id: `${playerplay.player.id}`,
                        name: `${playerplay.player.firstname} ${playerplay.player.lastname}`,
                        played: playerplay.played ? `${PLAYED_TRANSLATION[playerplay.played.toString()]}` : '',
                        opposition: 'oppositionname',
                        oppositionlogo: '',
                        minutes_played: 0,
                        points: 0,
                        ppm: 0,
                        price: 0,
                        price_change: 0,
                        position: '',
                        base_points: 0,
                        create_points: 0,
                        negative_play_points: 0,
                        scoring_points: 0,
                        evade_points: 0,
                        base: 0,
                        tackles: 0,
                        missed_tackles: 0,
                        tries: 0,
                        hit_upso8: 0,
                        hit_ups: + 0,
                        errors: 0,
                        oppositioncolors: 'oppositioncolors',
                        game: 0,
                        posrdrank: 0,
                        postotalrank: 0,
                        scbe: 0,
                        nextroundpoints: 0,
                        nextroundprice: 0,
                        predictedPriceChange: 0,
                        power_points: 0
                    })
                }
            } else {

            
                let base:number = (thegame.tackles + thegame.hitups_over_8m + thegame.hitups_under_8m + thegame.missed_tackles);
                let oppositionname = thegame.opposition?.name ? thegame?.opposition?.name : '';
                let oppositioncolors:string[] = TeamsList[oppositionname];
                let predictedPriceChange:number = thegame.nextRoundPredictedPrice - thegame.price;
                console.log("Played: ", playerplay.played.toString())
                newrows.push({
                    id: `${playerplay.player.id}`,
                    name: `${playerplay.player.firstname} ${playerplay.player.lastname}`,
                    played: playerplay.played  ? `${PLAYED_TRANSLATION[playerplay.played.toString()]}` : '',
                    opposition: oppositionname,
                    oppositionlogo: TeamLogos[oppositionname],
                    minutes_played: thegame.minutes_played,
                    points: thegame.points,
                    ppm: thegame.points / thegame.minutes_played,
                    price: thegame.price?.toLocaleString('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0}),
                    price_change: thegame.price_change,
                    position: thegame.position?.name,
                    base_points: thegame.base_points,
                    create_points: thegame.create_points,
                    negative_play_points: thegame.negative_play_points,
                    scoring_points: thegame.scoring_points,
                    evade_points: thegame.evade_points,
                    base: base,
                    tackles: thegame.tackles,
                    missed_tackles: thegame.missed_tackles,
                    tries: thegame.tries,
                    hit_upso8: thegame.hitups_over_8m,
                    hit_ups: + thegame.hitups_under_8m,
                    errors: thegame.errors,
                    oppositioncolors: oppositioncolors,
                    game: thegame,
                    posrdrank: thegame.posrdrank,
                    postotalrank: thegame.posrank,
                    scbe: thegame.scbe,
                    nextroundpoints: thegame.nextRoundPredictedPoints,
                    nextroundprice: thegame.nextRoundPredictedPrice,
                    predictedPriceChange: predictedPriceChange,
                    power_points: thegame.tackle_busts + thegame.effective_offloads
                })
            }
        })
        setRows(newrows);
        }
    }

    useEffect(() => {
        PopulateRows();
    },[PlayerOwnership])

    return <PlayerDataGrid
        rows={rows}
        columns={columns}
        height={800}
    />
}

export default PlayerOwnershipsTable;