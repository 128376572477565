import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Diff } from '../../types/types';
import DiffTable from './DiffTable';

export interface Props {
    diffs: Diff[];
    title: string;
    width: string;
}

const SCTeamsTableContianer = ({diffs,title, width}:Props) => {
    useEffect(() => {},[diffs])

    return <Paper elevation={5} style={{width: width}}>
        <h4>{title}</h4>
        <DiffTable diffs={diffs} />
    </Paper>
}

export default SCTeamsTableContianer;