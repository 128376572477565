import React, { useState, useEffect } from 'react';
import { Placing } from '../../../types/types';
import { InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import MultiSeriesGraph from '../../MultiSeriesGraph';

export interface Props {
    placings: Placing[];
}

interface ChartSeries {
    name: string;
    data: number[];
}


const GRAPHITEMS = [
    ['price', "Price"],
    ['price_change', "Price Change"],
    ['position', "Rank"],
    ['round_position', "Round Position"],
]

function RankGraph({placings}:Props) {
    const [ graphSeries, setGraphSeries ] = useState<ChartSeries[]>([]);
    const [ selectedGraphItems, setSelectedGraphItems ] = useState<string[]>(['position'])
    const [ categories, setCategories ] = useState<string[]>([]);
    
    useEffect(() => {
        PopulateGraph();
    },[placings, selectedGraphItems])

    function onChange(changeevent:SelectChangeEvent<string[]>) {
        let newvalues = changeevent.target.value;
        if (Array.isArray(newvalues)) {
            setSelectedGraphItems(newvalues);
        }
        
    }

    const PopulateGraph = () => {
        let newGraphitems:ChartSeries[] = [];
        let newCategories:string[] = [];
        selectedGraphItems.map(function(gi) {
            newGraphitems.push({
                name: gi === 'position' ? "Rank" : gi,
                data: []
            });
        })
        placings.map(function(placing) {
            selectedGraphItems.map(function(gi,index) {
                newGraphitems[index].data.push(placing[gi]);
            })
            newCategories.push(`${placing.round}`);
        });
        setCategories(newCategories);
        setGraphSeries(newGraphitems);
    }

    let showtitle = '';
    let showtitleArray:string[]= [];
    selectedGraphItems.map(function(gi) {
        showtitleArray.push(`${gi === 'position' ? 'Rank' : gi}`)
    });

    showtitle = showtitleArray.join(", ");

    return <Paper style={{display: "flex", flexWrap: "wrap", padding: "0.5rem"}} elevation={5}>
    <div style={{width: "100%"}}>
        <MultiSeriesGraph 
            series={graphSeries} 
            title="title" 
            categories={categories} 
            showtitle={showtitle} 
            type="line" 
        /> 
    </div>
    <div style={{display: "flex", flexDirection: "column", justifyContent: 'flex-start', alignItems: "flex-start", }}> 
        <InputLabel id={`multiinputlabel`}>Graph Items</InputLabel>
        <Select 
            value={selectedGraphItems}
            label={'Graph Items'}
            onChange={e => onChange(e)}
            labelId={'multiinputlabel'}
            multiple
            style={{width: 250, textAlign: "left", textTransform: 'capitalize'}}
        >
            {GRAPHITEMS.map(function(option) {
                return <MenuItem value={option[0]}>{option[1]}</MenuItem>
            })}
        </Select>
    </div>
</Paper>
}
export default RankGraph;