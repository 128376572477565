import React, { useEffect, useState } from "react";
import { Trade } from "../../types/types";
import { Tabs, Tab, Paper } from "@mui/material";
import PlayerCardSimpleStats from "../PlayerCard/PlayerCardSimpleStats";

export interface Props {
  trades: Trade[];
}

const Trades = ({ trades }: Props) => {
  const [direction, setDirection] = useState(0);

  useEffect(() => {}, [direction]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setDirection(newValue);
  };

  return (
    <div>
      <Tabs
        value={direction}
        onChange={handleChange}
        style={{ marginBottom: "1rem" }}
      >
        <Tab label="In" />
        <Tab label="Out" />
      </Tabs>
      <Paper style={{ padding: "1rem" }} elevation={5}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            overflowX: "scroll",
            paddingBottom: "1rem",
            columnGap: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          {trades.map(function (trade) {
            let include = false;
            if (direction === 0) {
              if (trade.direction === "in") {
                include = true;
              }
            } else {
              if (trade.direction === "out") {
                include = true;
              }
            }
            if (include) {
              return (
                <PlayerCardSimpleStats
                  player={trade.forPlayer}
                  width=""
                  minwidth={"200px"}
                  onPlusClick={(e) => console.log("")}
                  onNavClick={(e) => console.log("")}
                  headerElement={
                    <div>
                      <p>Trades: {trade.amount}</p>
                    </div>
                  }
                />
              );
            }
          })}
        </div>
      </Paper>
    </div>
  );
};

export default Trades;
