import React from "react";
import { Game, PlayerStats } from "../types/types";
import { CalculateBE } from "./CalculateBE";
import _ from "underscore";
import { CURRENT_SEASON } from "../Globals/Globals";
export interface Props {
  games: Game[];
  magicnumber: number;
}

function CalculatePlayerStats({ games, magicnumber }: Props) {
  let threeRd: number;
  let fiveRd: number;
  let ppm: number;
  let price: number;
  let be: number;
  let formattedPrice: string;
  let threeFive: string;
  let basetotal: number = 0;
  let ret: PlayerStats;
  let aveminutes: number = 0;
  let thisseasontotal: number = 0;
  let thisseasonBaseTotal: number = 0;
  let thisseasongamesCount: number = 0;
  let thisseasonminutes: number = 0;
  let scbe: number = 0;
  threeFive = "";
  if (!games) {
    games = [];
  }
  be = CalculateBE({ games: games, magicnumber: magicnumber });
  formattedPrice = "";
  threeRd = 0;
  fiveRd = 0;
  ppm = 0;
  price = 0;
  games = games.filter(function (game) {
    return game?.season === CURRENT_SEASON;
  });
  games = _.sortBy(games, function (game) {
    return -game?.round;
  });

  var totalMinutes = 0;

  games.map(function (game, index) {
    if (game.season === CURRENT_SEASON) {
      thisseasongamesCount = thisseasongamesCount + 1;
      thisseasontotal = thisseasontotal + game.points;
      thisseasonBaseTotal =
        thisseasonBaseTotal +
        (game.tackles +
          game.hitups_over_8m +
          game.hitups_under_8m +
          game.missed_tackles);
      thisseasonminutes = thisseasonminutes + game.minutes_played;
    }
    if (index < 3) {
      threeRd = threeRd + game.points;
    }

    fiveRd = fiveRd + game.points;
    totalMinutes = totalMinutes + game.minutes_played;
    basetotal =
      basetotal +
      (game.tackles +
        game.hitups_over_8m +
        game.hitups_under_8m +
        game.missed_tackles);
  });
  price = games[0] ? games[0].price : 0;
  scbe = games[0] ? games[0].scbe : 0;
  threeRd = threeRd / (games.length < 3 ? games.length : 3);
  ppm = thisseasontotal / thisseasonminutes;

  let avgpts: number = 0;

  avgpts = thisseasontotal / thisseasongamesCount;
  fiveRd = fiveRd / games.length;
  threeRd = Math.round(threeRd);
  fiveRd = Math.round(fiveRd);
  basetotal = thisseasonBaseTotal / thisseasongamesCount;
  basetotal = Math.round(basetotal * 100 + Number.EPSILON) / 100;
  ppm = Math.round(ppm * 100 + Number.EPSILON) / 100;
  be = Math.round(be);
  formattedPrice = price?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  threeFive = `${threeRd}/${fiveRd}`;
  let posrank: number = games[games.length - 1]
    ? games[games.length - 1].posrank
    : 0;
  aveminutes = thisseasonminutes / thisseasongamesCount;

  ret = {
    price: formattedPrice,
    be: be,
    ppm: ppm,
    threeFive: threeFive,
    three: threeRd,
    five: fiveRd,
    rawPrice: price,
    base: basetotal,
    aveminutes: aveminutes,
    avgpts: avgpts,
    scbe: scbe,
    posrank: posrank,
  };
  return ret;
}

export default CalculatePlayerStats;
