import React, { useState, useEffect } from 'react';
import { Ownership } from "../../types/types";
import { GridColDef } from '@mui/x-data-grid';
import GenericChangeAbleGraph from '../Graph/GenericChangeableGraph';
import { Paper } from '@mui/material';
import PlayerDataGrid from '../PlayerDataGrid';
import _ from 'underscore';

export interface Props {
    ownerships: Ownership[];
    lastround:number;
}

const columns:GridColDef[] = [
    { field: 'round', headerName: 'Round', width: 180 },
    { field: 'top100', headerName: 'Top 100', width: 180 },
    { field: 'top1000', headerName: 'Top 1000', width: 180 },
    { field: 'top5000', headerName: 'Top 5000', width: 180 },
    { field: 'top10000', headerName: 'Top 10,000', width: 180 },
    { field: 'top20000', headerName: 'Top 20,000', width: 180 },
    { field: 'top100000', headerName: 'Top 100,000', width: 180 },
]

const OwnershipGraph=({ownerships,lastround}:Props) => {
    const [rows, setRows ] = useState<object[]>([]);
    const [ theOwnerships, setTheOwnerships ] = useState(ownerships || []);


    useEffect(() => {
        let myOwnerships = _.sortBy(ownerships, function(own) {
            return own.round
        })
        setTheOwnerships(myOwnerships);
        PopulateRows();
    },[ownerships]);
    console.log("Ownerships: ", theOwnerships);
    
    const PopulateRows = () => {
        let newrows:object[] = [];
        let myOwnerships = _.uniq(ownerships, function(ownership) {
            return ownership.round
        })
        
        myOwnerships.map(function(ownership) {
            newrows.push({
                top100: ownership.top100 + '%',
                top1000: ownership.top1000 + '%',
                top5000: ownership.top5000 + "%",
                top10000: ownership.top10000 + "%",
                top20000: ownership.top20000 + "%",
                top100000: ownership.top100000 + "%",
                round: ownership.round,
                id: ownership.round
            });
        })
        
        // @ts-ignore
        
        setRows(newrows);
    }



    return (<div style={{marginTop: '2rem', display: "flex", flexDirection: "column", rowGap: "1rem"}}>
        <h4>Ownership %</h4>
        <Paper elevation={5} style={{padding: "0.25rem"}}>
            <GenericChangeAbleGraph
                title=""
                defaultGraphItems={['top1000']}
                filteritems={
                    [{
                    name: "Ownership",
                    options: [
                        ['top100', "Top 100"],
                        ['top1000', "Top 1,000"],
                        ['top5000', "Top 5,000"],
                        ['top10000', "Top 10,000"],
                        ['top20000', "Top 20, 000"],
                        ['top100000', "Top 100,000"],
                    ]}
                    ]
                }
                games={theOwnerships}

            />
        </Paper>
        <PlayerDataGrid
            rows={rows}
            columns={columns}
            height={"500px"}
            initialSort={{
                sorting: {
                  sortModel: [{ field: 'round', sort: 'asc' }],
                },
              }}
        />
    </div>)

}

export default OwnershipGraph;