import React, { useEffect, useState } from 'react';
import { Player } from '../../types/types';
import { selectChosenPlayers } from '../../reducers/ChosenPlayers';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Popover, List, ListItemText, ListItem, ListItemIcon, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import { RemovePlayer, AddPlayer, GetMultiplePlayerGames } from '../../reducers/ChosenPlayers';
import { SetSnackBarOpen, SetSnackBarText } from '../../reducers/UserSettingsReducer';

export interface Props { 
    player: Player;
}

function PlayerCardPopover({player}:Props) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    
    const selectedPlayers = useAppSelector(selectChosenPlayers);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    //const [ open, setOpen ] = useState(false);
    const open = Boolean(anchorEl);

    let isin = false;
    selectedPlayers.map(function(players) {
        if (player.id === players.id) {
            isin = true;
        }
    })

    

    function handleplusclick(player:Player) {
        if (isin) {
            dispatch(RemovePlayer(player.id));
            dispatch(SetSnackBarText(`Removed ${player.firstname} ${player.lastname} from Selected Players`));
            dispatch(SetSnackBarOpen(true));

        } else {
            dispatch(AddPlayer(player));
            dispatch(SetSnackBarText(`Added ${player.firstname} ${player.lastname} to Selected Players`));
            dispatch(SetSnackBarOpen(true));
        }
        setAnchorEl(null);
    }
    return <div>
        <IconButton sx={{position: "absolute", right: '0', top: '0'}} onClick={e => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon sx={{ color: 'white' }}/>
        </IconButton>
        <Popover
            id={`popover-${player.id}`}
            open={open}
            onClose={e => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
        >
            <List>
                <ListItem sx={{cursor: "pointer"}} onClick={e => handleplusclick(player)}>
                    <ListItemIcon style={{minWidth: "36px"}}>
                        { isin ? <RemoveIcon style={{minWidth: "36px"}}/> : <AddIcon style={{minWidth: "36px"}}/> }
                    </ListItemIcon>
                    <ListItemText primary={isin ? "Remove From Comparison List" : "Add To Comparison List"} />
                    
                </ListItem>
                <ListItem sx={{cursor: "pointer"}} onClick={e => navigate(`/player/${player.id}`)}>
                    <ListItemIcon style={{minWidth: "36px"}}>
                        <NavigateNextIcon style={{minWidth: "36px"}}/>
                    </ListItemIcon>
                    <ListItemText primary="View Player" />
                </ListItem>
            </List>
        </Popover>
    </div>
};

export default PlayerCardPopover;