import { TextField } from "@mui/material";
import React, { useEffect,useState } from "react";

export interface SingleInputProps {
    value: number;
    onChange: (val: string)=> void;
    label: string;
    id: string;
}   
const regex = /^(|\d+)$/;

function SingleInput({value, onChange, label, id}:SingleInputProps) {
    const [ val, setVal ] = useState(value);
    useEffect(() => {
        setVal(value)
    },[value])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (regex.test(e.target.value)) {
            console.log("Handle Chnage: ", {value: e.target.value,int: parseInt(e.target.value)})
            onChange(e.target.value);
        }
        //@ts-ignore
        setVal(e.target.value);
    }

    return <TextField 
        label={label}
        id={id}
        key={id}
        value={val}
        onChange={handleChange} 
    />
}

export default SingleInput;