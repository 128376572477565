import React, { useState, useEffect } from "react";
import { Game, Match } from "../../types/types";
import _ from "underscore";
import PlayerGameCard from "../PlayerCard/PlayerGameCard";
import { Paper, Select, MenuItem, InputLabel } from "@mui/material";
import styled from "styled-components";
import { SelectChangeEvent } from "@mui/material/Select";

export interface Props {
  games: Game[];
}

const FILTERITEMS = [
  "points",
  "tackles",
  "missed_tackles",
  "base_points",
  "create_points",
  "negative_play_points",
  "scoring_points",
  "evade_points",
  "minutes_played",
  "missed_tackles",
  "tries",
  "hitups_over_8m",
  "hitups_under_8m",
  "errors",
];

const HIDEIF = ["points", "minutes_played", "base_points"];

const SingleMatchTop = ({ games }: Props) => {
  const [topGames, setTopGames] = useState<Game[]>([]);
  const [filterItem, setFilterItem] = useState("points");

  useEffect(() => {
    GetTop();
  }, [games.length, filterItem]);

  function GetTop() {
    let mygames: Game[] = games.slice();
    let top = _.chain(mygames)
      .sortBy(function (game) {
        return -game[filterItem];
      })
      .value();

    setTopGames(top);
  }

  let repeatlength: number = 5;

  const GridContainer = styled.div`
    margin-top: 6rem;
    display: grid;
    overflow-x: scroll;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    grid-template-columns: repeat(${repeatlength}, 220px);
    grid-row-gap: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-top: 1rem;
    @media (min-width: 1024px) {
      grid-template-columns: repeat(${repeatlength}, 220px);
    }
  `;

  function onChange(changeevent: SelectChangeEvent<string>) {
    let newvalue = changeevent.target.value;
    if (!Array.isArray(newvalue)) {
      setFilterItem(changeevent.target.value);
    }
  }

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0.5rem",
        marginBottom: "0.5rem",
        marginTop: "0.5rem",
        width: "100%",
        overflow: "scroll",
        position: "relative",
      }}
      elevation={5}
    >
      <div
        style={{
          position: "sticky",
          top: "0rem",
          left: "0.5rem",
          height: "3rem",
          textAlign: "left",
        }}
      >
        <p style={{ marginBottom: 0 }}>Top Players</p>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <InputLabel id={`sortbylabel`}>Sort By</InputLabel>
          <Select
            value={filterItem}
            onChange={onChange}
            label={"Sort By"}
            labelId={`sortbylabel`}
            style={{
              width: 250,
              textAlign: "left",
              textTransform: "capitalize",
            }}
          >
            {FILTERITEMS.map(function (option) {
              return (
                <MenuItem value={option}>
                  {option.split("_").join(" ")}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <GridContainer>
        {topGames.map(function (game, index) {
          if (index < 14) {
            return (
              <div style={{ marginRight: "1rem" }}>
                <PlayerGameCard
                  Game={game}
                  filteritem={
                    HIDEIF.includes(filterItem) ? undefined : filterItem
                  }
                />
              </div>
            );
          }
        })}
      </GridContainer>
    </Paper>
  );
};

export default SingleMatchTop;
