import React, { useEffect, useState } from "react";
import { ReactComponent as Smalllogo } from "../Images/Logo-Blue-Black.svg";

const Header = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {/* <img style={{width: '200px', height: "100px", marginBottom: "1rem",marginTop: "1rem"}} src="/icons/scpblogo.svg" /> */}
      <Smalllogo
        style={{
          width: "200px",
          height: "50px",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      />
    </div>
  );
};

export default Header;
