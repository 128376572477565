import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Smalllogo } from "./Images/Logo-Blue-Black.svg";
import { LinearProgress } from "@mui/material";

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Smalllogo
        style={{ width: "300px", height: "70px", marginBottom: "2rem" }}
      />
      <LinearProgress style={{ width: "50%" }} />
    </div>
  );
}

export default Loading;
