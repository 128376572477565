import React, { useEffect, useState } from "react";
import { Player } from "../../types/types";
import {
  GridColDef,
  GridCellParams,
  MuiEvent,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  selectMagicNumber,
  selectPlayerDisplay,
} from "../../reducers/UserSettingsReducer";
import { useNavigate } from "react-router-dom";
import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { RemovePlayer } from "../../reducers/ChosenPlayers";
import PlayerDataGrid from "../PlayerDataGrid";
import SelectedPlayersCards from "./SelectedPlayersCards";

import { TeamLogos, TeamsList } from "../../Globals";
import { RenderPriceCell, RenderTeamCell } from "../../Globals/TableFunctions";
import InfoIcon from "@mui/icons-material/Info";
import { CURRENT_SEASON, CURRENT_SEASON_STRING } from "../../Globals/Globals";

export interface Props {
  players: Player[];
  setOpen: (setopen: boolean) => void;
}

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 180 },
  {
    field: "team",
    headerName: "Team",
    width: 160,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderTeamCell(
        params.row.teamname,
        params.row.teamlogo,
        params.row.teamcolors
      ),
  },
  {
    field: "price",
    headerName: "Price",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date>) =>
      RenderPriceCell(params.row.price, params.row.pricechange),
  },
  { field: "scbe", headerName: "BE", type: "number" },
  { field: "avgpts", headerName: "Avg Pts", type: "number" },
  { field: "avgbase", headerName: "Avg Base", type: "number" },
  { field: "ppm", headerName: "PPM", type: "number" },
  { field: "three", headerName: "3rd Avg", type: "number" },
  { field: "five", headerName: "5rd Avg", type: "number" },
  {
    field: "removebutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => <RemoveIcon />,
  },
  {
    field: "viewbutton",
    headerName: "",
    renderCell: (params: GridRenderCellParams<Date>) => <InfoIcon />,
  },
];

function SelectedPlayersTable({ players, setOpen }: Props) {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  let magicnumber = useAppSelector(selectMagicNumber);
  let statePlayerDisplay = useAppSelector(selectPlayerDisplay);
  const [rows, setRows] = useState<object[]>([]);
  const [height, setHeight] = useState("200px");

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (params.field !== "removebutton") {
      if (params.field === "name" || params.field === "viewbutton") {
        navigate("/player/" + params.row.id);
        setOpen(false);
      }
    } else {
      dispatch(RemovePlayer(params.row.id));
    }
  };

  const PopulateRows = () => {
    var newrows: object[] = [];
    players.map(function (player, index) {
      if (player) {
        if (player.stats) {
          const thisSeasonStat = player.stats
            .filter((stat) => stat.season === CURRENT_SEASON_STRING)
            .pop();
          if (thisSeasonStat) {
            var playerstats = CalculatePlayerStats({
              games: thisSeasonStat.last5Games,
              magicnumber: magicnumber,
            });
            let increase: boolean =
              thisSeasonStat.last5Games[4]?.price_change > 0;
            let teamname = thisSeasonStat.last5Games[0]?.team?.name
              ? thisSeasonStat.last5Games[0]?.team?.name
              : "";
            let teamcolors: string[] = TeamsList[teamname];
            let pricechange = thisSeasonStat.last5Games[4]?.price_change
              ? thisSeasonStat.last5Games[4]?.price_change
              : 0;
            if (!teamcolors) {
              teamcolors = ["", ""];
            }
            //var position = player.stats[0].last5Games[0].position;
            newrows.push({
              id: player.id,
              img: "https://www.nrlsupercoachstats.com/highcharts/PlayerPictures/2021/Trbojevic,%20Tom.png",
              name: `${player.firstname} ${player.lastname}`,
              team: thisSeasonStat.last5Games[0]?.team?.name,
              five: playerstats.five,
              three: playerstats.three,
              avgpts: thisSeasonStat.avgpts,
              avgbase: thisSeasonStat.avgbase,
              teamlogo: TeamLogos[thisSeasonStat.last5Games[0]?.team?.name],
              priceincrease: increase,
              teamname: teamname,
              teamcolors: teamcolors,
              pricechange: pricechange,
              ...playerstats,
            });
          }
        }
      }
    });
    setRows(newrows);
    setHeight(`${players.length * 38 + 150}px`);
  };
  useEffect(() => {
    PopulateRows();
  }, [players]);

  if (statePlayerDisplay === "table") {
    return (
      <PlayerDataGrid
        height={height}
        columns={columns}
        rows={rows}
        onCellClick={onCellClick}
      />
    );
  } else {
    return <SelectedPlayersCards players={players} />;
  }
}

export default SelectedPlayersTable;
