import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  SelectMatch,
  GetSingleMatch,
  SelectSingleMatchLoadingStatus,
} from "../reducers/SingleMatchReducer";
import SingleMatchTable from "../components/SingleMatch/SingleMatchTable";
import SingleMatchTop from "../components/SingleMatch/SingleMatchTop";
import { Paper, Box, Tabs, Tab } from "@mui/material";
import { MyContainer, TeamLogos } from "../Globals";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackArrow from "../components/Navigation/BackArrow";
import NavContainer from "../components/Navigation/NavContainer";
import SingleMatchLogos from "../components/SingleMatch/SingleMatchLogos";
import Loading from "../components/Loading";
import { RegisterPageView } from "../functions/Analytics";
import AdsenseAdd from "../components/AdsenseAdd";
import Header from "../components/Header/Header";
export interface Props {
  type: "matchid" | "seasonround";
}

const SingleMatch = ({ type }: Props) => {
  const dispatch = useAppDispatch();
  let status = useAppSelector(SelectSingleMatchLoadingStatus);
  const [selectedTab, setSelectedTab] = useState(0);

  let { matchId, season, round, team } = useParams<{
    matchId?: string;
    season?: string;
    round?: string;
    team?: string;
  }>();
  let singlematch = useAppSelector(SelectMatch);

  useEffect(() => {
    if (matchId) {
      dispatch(GetSingleMatch(matchId));
      RegisterPageView({ path: `/match/${matchId}` });
    }
  }, [matchId]);

  useEffect(() => {
    GetSingleMatchRound();
  }, [season, round, team]);

  function GetSingleMatchRound() {
    let stringSeason: string = season ? season : "";
    let stringRound: string = round ? round : "";
    let stringTeam: string = team ? team : "";
    if (season && round && team) {
      dispatch(GetSingleMatch(`${stringSeason}/${stringRound}/${stringTeam}`));
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (status === "loading") {
    return <Loading />;
  }

  return (
    <div>
      <NavContainer />
      <Header />
      <SingleMatchLogos match={singlematch.match} />
      <MyContainer maxWidth="xl">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
            style={{ marginBottom: "1rem" }}
          >
            <Tab label="Top Players" />
            <Tab label="Player Stats" />
          </Tabs>
        </Box>
        {selectedTab === 0 ? (
          <SingleMatchTop games={singlematch.games} />
        ) : null}
        {selectedTab === 1 ? (
          <SingleMatchTable games={singlematch.games} />
        ) : null}
        <AdsenseAdd />
      </MyContainer>
    </div>
  );
};

export default SingleMatch;
