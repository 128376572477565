import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectChosenPlayers } from '../../reducers/ChosenPlayers';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Drawer, Paper, SwipeableDrawer, Box, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Button} from '@mui/material';
import { Global } from '@emotion/react';
import SelectedPlayersTable from './SelectedPlayersTable';
import { selectMagicNumber, ChangeMagicNumber, selectPlayerDisplay, TogglePlayerDisplay } from '../../reducers/UserSettingsReducer';
import { useNavigate } from 'react-router-dom';
import PlayerCardPopover from '../PlayerCard/PlayerCardPopover';


function SelectedPlayersContainer() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let statePlayerDisplay = useAppSelector(selectPlayerDisplay);
    let drawerBleeding:number = 56; 

    const [ drawerOpen, setDrawerOpen ] = useState(false);
    
    const [ playerDisplay, setPlayerDisplay ] = useState<string>( statePlayerDisplay || 'table');
    const selectedPlayers = useAppSelector(selectChosenPlayers);
    
    function handleDisplayChange(change:React.ChangeEvent<HTMLInputElement>) {
        
        setPlayerDisplay(change.target.value);
        dispatch(TogglePlayerDisplay(change.target.value));
    }

    if (selectedPlayers.length === 0) {
        drawerBleeding = 0;
    } else {
        drawerBleeding = 56;
    }
    
    return <>
        <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
            zIndex: 3000,
          },
        }}
      />
    <div style={{position: "fixed",left: 0, bottom: drawerBleeding - 20, zIndex: 1000, width: "100%", height: "1rem"}} onClick={e => setDrawerOpen(!drawerOpen)}>
        <ExpandLessIcon onClick={e => setDrawerOpen(!drawerOpen)} sx={{fontSize:40}}/>
    </div>
    <SwipeableDrawer
        open={drawerOpen}
        anchor="bottom"
        onClose={e => setDrawerOpen(false)}
        onOpen={e => setDrawerOpen(true)}
        ModalProps={{
            keepMounted: true,
        }}
    >
        <Box
            sx={{
            position: 'absolute',
            top: drawerOpen? -drawerBleeding - 50 : -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            }}
        >
            <Paper style={{padding: "1rem", zIndex: 1000}} elevation={5}>
                <Box 
                    onClick={e => {setDrawerOpen(!drawerOpen);}}
                    sx={{
                        width: 30,
                        height: 6,
                        backgroundColor: '#e0e0e0',
                        borderRadius: 3,
                        position: 'absolute',
                        top: 8,
                        left: 'calc(50% - 15px)',
                }} />
                <div style={{display: 'flex', flexDirection: "row", flexWrap: 'wrap', alignItems: "center", marginBottom: "0.5rem"}}>
                    <p style={{width: '100%', marginTop: 0, marginBottom: "1rem"}}>Selected Players: {selectedPlayers.length}</p>
                    <div style={{display: "flex", alignItems: "center"}}>
                    <FormControl component="fieldset" sx={{display: "flex", flexDirection:"row"}}>
                    
                        <FormLabel component="legend">Display</FormLabel>
                        <RadioGroup
                            row
                            aria-label="gender"
                            name="controlled-radio-buttons-group"
                            value={playerDisplay}
                            onChange={handleDisplayChange}
                        >
                            <FormControlLabel value="card" control={<Radio />} label="Card" />
                            <FormControlLabel value="table" control={<Radio />} label="Table" />
                        </RadioGroup>
                        <Button variant="outlined" onClick={e => {navigate("/compareplayers"); setDrawerOpen(false);}}>Compare Players</Button>
                    </FormControl>
                    
                    </div>
                </div>
                
                <SelectedPlayersTable players={selectedPlayers} setOpen={setDrawerOpen} />       
            </Paper>
        </Box>
                
        </SwipeableDrawer>
    </>
}

export default SelectedPlayersContainer;