import styled from "@emotion/styled"
export const RoundedCTAContainer = styled.div`
    display: grid;
    column-gap: 0.5rem;
    width: 100%;
    @media(max-width: 800px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 0.5rem;
        h4 {
            font-size: 0.75rem;
        }
    }
    
    @media(min-width: 800px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        
    }
    
`