import React, { useEffect, useState } from "react";
import { PlayerStats, Player, PlayerStats2, Game } from "../../types/types";
import { Card, CardMedia, Divider, Paper, Tooltip } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import CalculatePlayerStats from "../../functions/CalculatePlayerStats";
import { TeamsList } from "../../Globals";
import { Link } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "styled-components";
import { borderRadius } from "@mui/system";
import { selectChosenPlayerIds } from "../../reducers/ChosenPlayers";
import { useAppSelector } from "../../app/hooks";
import { TeamLogos, TOOLTIPS } from "../../Globals";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import PlayerCardPopover from "./PlayerCardPopover";
import {
  CURRENT_SEASON_STRING,
  IMAGES_BUCKET,
  LAST_SEASON_STRING,
} from "../../Globals/Globals";

export interface Props {
  player: Player;
  stats?: PlayerStats;
  width: string;
  onPlusClick: (player: Player) => void;
  onNavClick: (player: Player) => void;
  removeIcon?: boolean;
  extraStat: {
    label: string;
    value: string;
    tooltip: string;
  };
}

const CardNumber = styled.b`
  font-size: 1rem;
  @media (min-width: 1024px) {
    font-size: 1.1rem;
  }
`;

const CardLabel = styled.span`
  font-size: 0.75rem;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
  }
`;

function PlayerCardExtraStat({
  player,
  stats,
  width,
  onPlusClick,
  onNavClick,
  removeIcon,
  extraStat,
}: Props) {
  const DEV = false;

  let chosenPlayerids = useAppSelector(selectChosenPlayerIds);
  if (chosenPlayerids.includes(player.id)) {
    removeIcon = true;
  }

  var playerstats = CalculatePlayerStats({
    games: player.stats[0]?.last5Games,
    magicnumber: 8767,
  });

  //Need to handle simple player as well.
  let colors = ["", ""];
  let increase: boolean = true;
  if (player.stats[0].lastgame) {
    colors = TeamsList[player.stats[0]?.lastgame.team.name];
    increase = player.stats[0]?.lastgame.price_change > 0;
  } else {
    increase =
      player.stats[0]?.last5Games[4]?.price >
      player.stats[0]?.last5Games[3]?.price;
    colors = TeamsList[player.stats[0]?.last5Games[0]?.team?.name];
  }

  if (player.stats[0]?.last5Games?.length === 1) {
    increase = true;
  } else if (player.stats[0]?.last5Games.length === 2) {
    increase =
      player.stats[0]?.last5Games[player.stats[0]?.last5Games.length - 1]
        ?.price >
      player.stats[0]?.last5Games[player.stats[0]?.last5Games.length - 1]
        ?.price -
        2;
    increase =
      player.stats[0]?.last5Games[player.stats[0]?.last5Games.length - 1]
        ?.price_change > 0;
  }
  let pricestring = "";
  if (typeof (playerstats.price === "string") && playerstats.price) {
    pricestring = playerstats.price.slice(0, -3);
  }
  if (!colors) {
    colors = ["#262626", "#161616"];
  }
  let lastgame: Game | null;
  let thisseasonStat: PlayerStats2 = player.stats ? player.stats[0] : null;
  let lastseasonStat: PlayerStats2 = player.stats ? player.stats[0] : null;
  let ppm = 0;
  player.stats?.map(function (stat) {
    if (stat.season === CURRENT_SEASON_STRING) {
      thisseasonStat = stat;
      lastgame = stat.lastgame ? stat.lastgame : null;
      pricestring = lastgame?.price?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
      if (lastgame) {
        ppm = lastgame.total_points / lastgame.total_minutes_played;
        increase = lastgame.price_change > 0;
      }
    }
  });
  let playerimg = "";
  if (DEV) {
    playerimg = "./mysteryplayer.png";
  } else {
    playerimg =
      player.pic !== ""
        ? player.pic
        : `${IMAGES_BUCKET}${player.firstname}_${player.lastname}.png`;
  }
  let newSeasonPlayerStats;
  let preseasonplayerstats;
  let teamname = "";
  player.stats.map(function (stat) {
    if (stat.season === CURRENT_SEASON_STRING) {
      newSeasonPlayerStats = CalculatePlayerStats({
        games: stat.last5Games,
        magicnumber: 0,
      });
      teamname = stat.lastgame?.team?.name;
    } else if (stat.season === CURRENT_SEASON_STRING) {
      pricestring = stat.lastgame?.price?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
      teamname = stat.lastgame?.team?.name;
    }
  });

  let CardItems = [
    {
      label: extraStat.label,
      value: extraStat.value,
      tooltip: extraStat.tooltip,
    },
    {
      label: "AVG",
      value: (lastgame?.total_points / lastgame?.total_games).toFixed(2),
      tooltip: TOOLTIPS["AVG"],
    },
    { label: "PPM", value: ppm.toFixed(2), tooltip: TOOLTIPS["PPM"] },
    { label: "BE", value: lastgame?.scbe?.toFixed(0), tooltip: TOOLTIPS["BE"] },
    {
      label: "3RA",
      value: newSeasonPlayerStats?.three,
      tooltip: TOOLTIPS["3RA"],
    },
    {
      label: "Mins",
      value: (lastgame?.total_minutes_played / lastgame?.total_games).toFixed(
        0
      ),
      tooltip: TOOLTIPS["Mins"],
    },
  ];

  if (extraStat.label === "Price") {
    CardItems.push({
      label: "Base",
      value: playerstats.base,
      tooltip: TOOLTIPS["BASE"],
    });
  }

  return (
    <Paper
      style={{
        width: width,
        minWidth: width,
        position: "relative",
        textAlign: "center",
      }}
      className="playercard"
      elevation={3}
    >
      <a href={`/player/${player.id}`}>
        <div
          style={{
            background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`,
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
          }}
        >
          <div
            style={{
              backgroundImage: `url("${playerimg}")`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              paddingBottom: "60%",
              backgroundRepeat: "no-repeat",
              //backgroundColor: "blue",
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            }}
          >
            <div style={{ position: "absolute", top: 6, left: 6 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {/* <span style={{color: "white"}}>{player.stats[0]?.last5Games[0]?.team?.name}</span> */}
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={TeamLogos[teamname]}
                  alt={teamname}
                />
              </div>
            </div>
          </div>
        </div>
      </a>
      <Paper>
        <PlayerCardPopover player={player} />
        <a href={`/player/${player.id}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            <span>
              <b style={{ color: "black" }}>
                {player.firstname[0]}. {player.lastname}
              </b>
            </span>
            <span
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "0.25rem",
              }}
            >
              <b style={{ color: increase ? "green" : "red" }}>{pricestring}</b>
              {increase ? (
                <TrendingUpIcon
                  style={{ color: "green", marginLeft: "0.25rem" }}
                />
              ) : (
                <TrendingDownIcon
                  style={{ color: "red", marginLeft: "0.5rem" }}
                />
              )}
            </span>
          </div>
        </a>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr 1fr",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridRowGap: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          {CardItems.map(function (carditem) {
            if (carditem.label !== "Price") {
              return (
                <Tooltip title={carditem.tooltip} placement="top">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <CardNumber>{carditem.value}</CardNumber>
                    </div>
                    <CardLabel>{carditem.label}</CardLabel>
                  </div>
                </Tooltip>
              );
            }
          })}
        </div>

        <Divider />
      </Paper>
    </Paper>
  );
}

export default PlayerCardExtraStat;
