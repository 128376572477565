import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { RootState, AppThunk } from '../../app/store';
import api from '../app/api';
import { RootState } from '../app/store';
import { Team, Match, Player, Game, TeamWithRanks, MatchWithRanks } from '../types/types';

export interface TeamState {
    singleteam: {
        team: TeamWithRanks;
        players: Player[];
        upcoming: MatchWithRanks[];
        lastmatch: Match;
        matches: Match[];
    }
    status: 'idle' | 'loading' | 'failed';
}

const initialState: TeamState = {
    singleteam: {
        team: {name: "", ranks: []},        
        players: [],
        upcoming: [],
        lastmatch: {id: -1, season:-1, round:-1, teams: [], location: '', games: [], date: '', matchId:'', homescore: 0, awayscore: 0},
        matches: [],
    },
    status: 'idle'
}

interface GetTeamAction {
    type: String,
    payload: {
        team: TeamWithRanks,
        matches: Match[],
        players: Player[],
        upcoming: Match[],
    }
}

interface GetTeamPayload {
    team: TeamWithRanks;
    matches: Match[];
    players: Player[];
    upcoming: MatchWithRanks[];
    lastmatch: Match;
}

const fetchSingleTeam = (name?:string) => {
    return new Promise<{data: GetTeamPayload}>((resolve) => {
        name = name?.split(" ")[0];
        api.get('/team/'+name?.toLowerCase()).then(function(payload) {
            console.log("NANEL ",name);
          resolve(payload);
        });
      });
}

export const GetSingleTeam = createAsyncThunk(
    'team/GetSingleTeam',
    async (name?:string) => {
        const response = await fetchSingleTeam(name);
        return response;
    }
)

export const SelectTeam = (state: RootState ) => state.team.singleteam;

export const SelectUpcoming = (state: RootState) => state.team.singleteam.upcoming;

export const SelectRanks = (state: RootState) => state.team.singleteam.team.ranks;

export const SelectStatus = (state: RootState) => state.team.status;

export const TeamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {
        GetSingleTeam: (state, action: GetTeamAction) => {
            state.status = 'loading'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetSingleTeam.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(GetSingleTeam.fulfilled, (state, action) => {
                state.singleteam = action.payload.data;
                state.status = "idle"
            })
    }   
});

export default TeamSlice.reducer;