import React from 'react';
import { Team } from '../types/types';
import { TeamLogos, TeamsList } from '../Globals';
import { Card, CardContent } from '@mui/material';
export interface Props {
    team: Team
}

function TeamCard({team}: Props) {

    let name:string = '';
    name = team.name ? team.name : "";

    let colors = TeamsList[name];
    
    if (!colors) {
        colors = ['#262626', '#161616']
    }

    return (<Card sx={{marginLeft: "1rem", maxWidth: 180, overflow: 'visible', display: "flex", background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`, justifyContent: "center"}}>
    <CardContent sx={{justifyContent: "flex-start", flexDirection: "column", display: "flex", paddingBottom: 12, alignItems: "center"}} style={{paddingBottom: 12}}>
        <img src={TeamLogos[name]} style={{width: "60px", height: "60px", margin: "auto"}} alt="opponentname" />
    </CardContent>
</Card>);
}

export default TeamCard;