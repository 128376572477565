import React from 'react';
import ReactGA from 'react-ga';

interface PageViewProps {
    path: string;
}

interface EventProps {
    category:string;
    action:string;
    label:string;
}

interface ModalProps {
    name:string;
}

export function RegisterPageView({path}:PageViewProps) {
    ReactGA.pageview(path);
}

export function RegisterEvent({category, action,label}:EventProps) {
    console.log("ADDing Event: ", category, action,label);
    ReactGA.event({
        category:category,
        action:action,
        label: label,
        
    });
}

export function RegisterModalView({name}:ModalProps) {
    ReactGA.modalview(name);
}