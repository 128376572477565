import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import NavContainer from "../components/Navigation/NavContainer";
import { Container } from "@mui/material";
import SearchAutoComplete from "../components/SearchAutoComplete";
import AdsenseAdd from "../components/AdsenseAdd";
import Header from "../components/Header/Header";
import { getAll, selectAllPlayers } from "../reducers/FindPlayers";
function SearchScreen() {
  const dispatch = useAppDispatch();
  const allplayers = useAppSelector(selectAllPlayers);
  useEffect(() => {
    dispatch(getAll());
  }, []);
  return (
    <div style={{ marginBottom: "2rem" }}>
      <NavContainer />
      <Header />
      <div style={{ paddingTop: "2rem" }}>
        <Container maxWidth="xl" style={{ marginBottom: "2rem" }}>
          <SearchAutoComplete players={allplayers} />
          <AdsenseAdd />
        </Container>
      </div>
    </div>
  );
}

export default SearchScreen;
