import React from "react";
import { TeamLogos, TeamsList, TOOLTIPS } from "../../Globals";

export interface Props {
  teamname: string;
}

function TeamLogo({ teamname }: Props) {
  console.log("TEAMNAME: ", teamname);
  const teamLogo = TeamLogos[teamname] ? TeamLogos[teamname] : "";
  return (
    <img
      src={teamLogo}
      style={{
        width: "60px",
        height: "60px",
        marginLeft: "1rem",
        marginTop: "0.5rem",
        marginRight: "auto",
      }}
    />
  );
}

export default TeamLogo;
