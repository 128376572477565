import React, { useEffect, useState } from 'react';
import NavContainer from '../components/Navigation/NavContainer';
import { Select, MenuItem, InputLabel, Tabs, Tab, Box, Button, Paper, Container } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import VCLoopHole from '../components/Tools/VCloopHole';
import Header from '../components/FrontPage/Header';
import {RegisterPageView} from '../functions/Analytics';
import AdsenseAdd from '../components/AdsenseAdd';

const Tools = () => {
    let toolname = useParams<{tool:string}>();
    
    useEffect(() => {
        RegisterPageView({path: `tools/${toolname}`});    
    },[toolname])
    
    return <Container maxWidth="xl" style={{marginBottom: '2rem',marginLeft: "auto", marginRight: "auto"}}>
        <NavContainer />
        <Header />

        <div style={{paddingTop: '2rem'}}>
            {toolname['tool'] === 'VCLoopHole' ? <VCLoopHole /> : null}
        </div>
        <AdsenseAdd />
    </Container>
}

export default Tools;