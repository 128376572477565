import React, { useEffect, useState } from 'react';
import { GridColDef, GridCellParams, GridRowParams, GridEvents, MuiEvent, GridCallbackDetails, GridRenderCellParams } from '@mui/x-data-grid';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Game, Player } from '../../types/types';
import PlayerDataGrid from '../PlayerDataGrid';
import { useNavigate } from 'react-router-dom';
import { TeamLogos, TeamsList } from '../../Globals';
import { isArray } from 'underscore';
import { RenderTeamCell, RenderPriceCell } from '../../Globals/TableFunctions';
export interface Props {
    games: Game[];
}

const columns:GridColDef[]  = [
    { field: "name", headerName: "Name", width: 130},
    { field: 'season', headerName: 'Season' },
    { field: 'round', headerName: 'Rd' , type: "number" },
    { field: 'position', headerName: 'Position' },
    { field: 'opposition', headerName: 'Opponent', width: 180,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderTeamCell(params.row.opposition, params.row.oppositionlogo, params.row.oppositioncolors))
    },
    { field: 'price', headerName: 'Price' , type: "number", width: 180, 
        renderCell: (params: GridRenderCellParams<Date>) => (RenderPriceCell(params.row.price, params.row.price_change)), 
        sortComparator: (v1, v2) => { return 1 }
    },
    { field: 'minutes_played', headerName: 'Minutes' , type: "number" },
    { field: 'points', headerName: 'Points' , type: "number" },
    { field: 'ppm', headerName: 'PPM' , type: "number" },
    { field: 'base', headerName: 'Base' , type: "number" },
    { field: "power_points", headerName: "Power Pts", type: "number"},
    { field: 'price', headerName: 'Price' , type: "number" },
    { field: 'base_points', headerName: 'Base' , type: "number" },
    { field: 'create_points', headerName: 'Create' , type: "number" },
    { field: 'negative_play_points', headerName: 'Negative Play' , type: "number" },
    { field: 'scoring_points', headerName: 'Scoring' , type: "number" },
    { field: 'evade_points', headerName: 'Evade' , type: "number" },
    { field: 'tackles', headerName: 'Tackles' , type: "number" },
    { field: 'missed_tackles', headerName: 'Missed Tackles' , type: "number" },
    { field: 'tries', headerName: 'Tries' , type: "number" },
    { field: 'hit_ups', headerName: 'Hit Ups' , type: "number" },
    { field: 'errors', headerName: 'Errors' , type: "number" },
    { field: 'navigatebutton', headerName: '' ,renderCell: (params: GridRenderCellParams<Date>) => (
        <KeyboardArrowRightIcon />)
    },
];


const ComparePlayersTable = ({games}:Props) => {
    let navigate = useNavigate();
    const [ rows, setRows ] = useState<object[]>([]);
    const [ selectedSeason, setSelectedSeason ] = useState(2021);
    const [ selectedTerm, setSelectedTerm ] = useState('points');

    useEffect(() => {
        PopulateRows();
    }, [games]);

    function PopulateRows() {
        
        let newrows:object[] = [];
        games.map(function(game){
            let base:number = (game.tackles + game.hitups_over_8m + game.hitups_under_8m + game.missed_tackles);
            let oppositionname = game.opposition?.name ? game.opposition?.name : '';
            let player:Player;
            if (isArray(game.player)) {
                player = game.player[0];
            } else {

                player = game.player ? game.player : {id: '', position: {name: ''}, stats: [{last5Games: [], timestamp: '', avgpts: 0, avgbase: 0, ppm:0,season:""}], firstname: '', lastname: '', pic: '', positionrank: [], ownerships: []};
            }
            let playername:string = player.firstname + ' ' + player.lastname;
            let oppositioncolors = TeamsList[oppositionname];

            newrows.push({
                id: game.id,
                name: playername,
                season: game.season,
                round: game.round,
                opposition: oppositionname,
                oppositionlogo: TeamLogos[oppositionname],
                points: game.points,
                minutes_played: game.minutes_played,
                ppm: game.points / game.minutes_played,
                price: game.price?.toLocaleString('en-US', { style: "currency", currency: "USD"}),
                price_change: game.price_change,
                position: game.position?.name,
                base_points: game.base_points,
                create_points: game.create_points,
                negative_play_points: game.negative_play_points,
                scoring_points: game.scoring_points,
                evade_points: game.evade_points,
                base: base,
                tackles: game.tackles,
                missed_tackles: game.missed_tackles,
                tries: game.tries,
                hit_ups: game.hitups_over_8m + game.hitups_under_8m,
                errors: game.errors,
                oppositioncolors: oppositioncolors,
                power_points: game.tackle_busts + game.effective_offloads
                

            })
        });
        setRows(newrows);
    }

    const onCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        if (params.field === 'navigatebutton') {
            console.log("PARAMS: ", params)
            navigate(`/match/${params.row.season}/${params.row.round}/${params.row.opposition}`);
        }
    }
    return <div>
        <PlayerDataGrid height={600} columns={columns} rows={rows} onCellClick={onCellClick} />

    </div>
}

export default ComparePlayersTable;