import React, { useEffect, useState } from 'react';
import { Match } from '../types/types';
import { TeamLogos, TeamsList } from '../Globals';
import { Card, CardContent, Typography } from '@mui/material';


export interface Props {
    match: Match;
    thisteamname: string;
    shownextgame?: boolean;
}

function UpcomingMatchCard({match, thisteamname, shownextgame}:Props) {
    let nextopponentname:string = '';

    match.teams.map(function(team) {
        if (team.name !== thisteamname) {
            nextopponentname = team.name ? team.name : "";
        }
    });

    let datestring:string = '';
    let matchdate:Date = new Date(match.date);
    datestring = matchdate.toLocaleDateString('en-US', { day: "numeric", month: 'long'});
    let colors = TeamsList[nextopponentname];
    
    if (!colors) {
        colors = ['#262626', '#161616']
    }

    return (<Card sx={{marginLeft: "1rem", maxWidth: 180, overflow: 'visible', display: "flex", background: `linear-gradient(120deg, ${colors[0]} 0%, ${colors[0]} 50%, ${colors[1]} 50%)`}}>
        <CardContent sx={{justifyContent: "flex-start", flexDirection: "column", display: "flex", paddingBottom: 12, alignItems: "center"}} style={{paddingBottom: 12}}>
            <Typography sx={{ fontSize: 12,whiteSpace:"nowrap" }} color="white" gutterBottom>{datestring}</Typography>
            <img src={TeamLogos[nextopponentname]} style={{width: "60px", height: "60px", margin: "auto"}} alt="opponentname" />
            <Typography sx={{ fontSize: 14, marginBottom: 0,whiteSpace:"nowrap" }} color="white" gutterBottom>Round: {match.round}</Typography>
        </CardContent>
    </Card>);
}

export default UpcomingMatchCard;