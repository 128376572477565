import React, {ReactNode, useEffect, useState} from 'react';
import { Match, MatchWithRanks, Rank } from '../../types/types';
import { GridColDef, GridCellParams, GridRowParams, GridEvents, MuiEvent, GridCallbackDetails, GridRenderCellParams } from '@mui/x-data-grid';
import { TeamLogos, TeamsList } from '../../Globals';
import { RenderTeamCell, RenderRankCell } from '../../Globals/TableFunctions'
import PlayerDataGrid from '../PlayerDataGrid';
interface Props {
    matches: MatchWithRanks[];
    thisteam: string;
}

const columns:GridColDef[]  = [
    { field: 'round', headerName: 'Round' },
    { field: 'opponent', headerName: 'Opponent', width: 130,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderTeamCell(params.row.opponent, params.row.opponentlogo, params.row.oppositioncolors)) },
    { field: 'date', headerName: 'Date' },
    { field: 'location', headerName: 'Location', width: 300 },
    { field: 'ranks', headerName: 'PTS Conceeded Rank', width: 700,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderRankCell(params.row.ranks))
    },
]


function UpcomingMatches({matches, thisteam}:Props) {
    const [ rows, setRows ] = useState<object[]>([]);
    useEffect(() =>{
        PopulateRows();
    }, [matches]);
    const PopulateRows = () => {
        let newrows:object[] = [];
        if (matches) {
            matches.map(function(match) {
                var showteam = '';
                var ranks:Rank[] = [];
                match.teams.map(function(team) {
                    if (team.name !== thisteam) {
                        showteam = team.name ? team.name : '';
                        ranks = team.ranks;
                    }
                });
                let oppositioncolors:string[] = TeamsList[showteam] ? TeamsList[showteam] : [0,0];

                newrows.push({
                    id: `${match.round}${showteam}`,
                    round: match.round,
                    opponent: showteam,
                    opponentlogo: TeamLogos[showteam],
                    date: match.date,
                    location: match.location,
                    oppositioncolors: oppositioncolors,
                    ranks: ranks
                })
            });
        }
        setRows(newrows);
    }
    function onRowClick() {
        
        return <div></div>
    }
    function onCellClick() {
        
    }
    return <div>
        <PlayerDataGrid height={'700px'} rows={rows} columns={columns} onCellClick={onCellClick} onRowClick={onRowClick} rowHeight={60}/>
    </div>
}

export default UpcomingMatches;