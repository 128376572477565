import React, { useEffect, useState } from 'react';
import { Placing } from '../../types/types';
import { GridColDef, GridCellParams, GridRowParams, GridEvents, MuiEvent, GridCallbackDetails, GridRenderCellParams } from '@mui/x-data-grid';
import { Navigate, useNavigate } from 'react-router-dom';
import { RenderPriceCell } from '../../Globals/TableFunctions';
import PlayerDataGrid from '../PlayerDataGrid';
import { RenderRankingCell } from '../Tables/TableFunctions';
import { Paper, Typography } from '@mui/material';
export interface Props {
    placings: Placing[];
    height?: number;
    showPrice?: boolean;
}

const columns:GridColDef[]  = [
    { field: 'teamname', headerName: 'Team Name',flex:1 },
    { field: 'position', headerName: 'Ranking',flex:1,
        renderCell:(params: GridRenderCellParams<Date>) => (RenderRankingCell({
            current: params.row.position,
            change: params.row.position_change, 
            isIncrease: params.row.position_change > 0
        })) 
    },
    { field: 'round_position', headerName: 'Round Rank',flex:1, 
        renderCell:(params: GridRenderCellParams<Date>) => (RenderRankingCell({
            current: params.row.round_position,
            change: params.row.round_position_change, 
            isIncrease: params.row.position_change > 0
        })) 
    },

]

const PlacingsTable = ({placings, height,showPrice}:Props) => {
    const [rows, setRows] = useState<object[]>([]);
    const navigate = useNavigate();
    console.log("placings: ", placings, showPrice);
    if (showPrice) {
        columns.push(
        { field: 'price', headerName: 'Price' ,flex:1,
        renderCell: (params: GridRenderCellParams<Date>) => (RenderPriceCell(params.row.price, params.row.price_change))
        })
    }
    useEffect(() => {
        PopulateRows();
    },[placings]);

    const onCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        navigate("/scteams/"+params.row.teamid);
    }

    const PopulateRows = () => {
        var newrows:object[] = [];
        placings.map(function(placing) {
            newrows.push({
                id: `${placing.team.name}-${placing.team.teamid}`,
                teamid: placing.team.teamid,
                teamname: placing.team.name,
                ...placing
            })
        });
        setRows(newrows);
    }
    console.log("Plcaings; ", placings)
    return <Paper>
        <div style={{padding: "1rem"}}>
            <Typography variant="h6"><b>Top teams this round</b></Typography>
        </div>
        <PlayerDataGrid 
            height={height? height: 600} 
            columns={columns} 
            rows={rows} 
            onCellClick={onCellClick}
        />
    </Paper>
}

export default PlacingsTable;