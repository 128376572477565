import React, { useEffect, useState } from 'react';
import { Match } from '../types/types';
import { TeamLogos } from '../Globals';
import { Divider } from '@mui/material';
export interface MatchToolTipProps {
    match: Match;
}

function MatchToolTip({match}:MatchToolTipProps) {
    let hometeamlogo:string = match.hometeam ? TeamLogos[match.hometeam.name] : '';;
    let awayteamlogo:string = match.awayteam ? TeamLogos[match.awayteam.name] : '';

    let homescore = match.homescore;
    let awayscore = match.awayscore;

    return <div style={{display: "flex", flexDirection: "column", paddingLeft: "0.5rem", paddingRight: "0.5rem"}}>
        <div style={{display: "flex", alignItems: "center", width: "100%"}}>
            <img src={hometeamlogo} style={{height: "30px", width: "30px"}} />
            <span style={{marginLeft: "0.5rem"}}>{homescore}</span>
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
            <img src={awayteamlogo} style={{height: "30px", width: "30px"}} />
            <span style={{marginLeft: "0.5rem"}}>{awayscore}</span>
        </div>
    </div>
}

export default MatchToolTip;