import React, { ReactElement, ReactNode, useRef,useEffect  } from 'react';
import { Paper, Tooltip, Typography, useTheme } from '@mui/material';


//import { ContainerIcons } from '../../globals/Globals';
import UpTransition from '../Theming/UpTransition';


export interface Props {
    title: string,
    description: string,
    icon:  string;
    hideTop?: boolean;
    children:  ReactElement | ReactNode;
    tooltipText?: string;
    hideWhite?: boolean;
}

const CarouselContainer = ({title, description, icon, hideTop=false, children,tooltipText='', hideWhite=false}: Props) => {
    const theme = useTheme();
    const nodeRef = useRef(null);
    //const IconElement = <ContainerIcons[icon];>
    const IconElement = <div></div>
    return (<div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        

        <div style={{width: "100%"}}>
            <Paper style={{padding: hideTop? 0 : "1rem", backgroundColor: hideWhite ? 'transparent': ''}} elevation={hideTop? 0 : 5}>
                
                {!hideTop? <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
                    {/* <IconElement sx={{fontSize: "4rem", color: theme.palette.mode === 'dark' ? '#21ace1' : '#21ace1'}}/> */}
                <div>
                    {tooltipText !== '' ? <Tooltip title={tooltipText}>
                        <Typography variant="h6" style={{textAlign: "left"}}>{title} </Typography>
                    </Tooltip>
                        : <Typography variant="h6" style={{textAlign: "left"}}>{title} </Typography>}
                    
                    <Typography gutterBottom variant="body2" style={{textAlign: "left"}}>{description}</Typography>
                </div>
                </div>
                : ""}
                <UpTransition location={title}>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", overflowX: "scroll", paddingBottom: "1rem", paddingTop: "1rem", width: "100%", columnGap: "0.75rem", paddingLeft: "0.5rem", paddingRight: "1rem"}}>
                    
                        {children}
                    
                </div>
                </UpTransition>
            </Paper>
            </div>
        
        </div>)
}

export default CarouselContainer;